import { useState } from 'react';
import { noop } from 'utils/function';
import {
  RequestExecutionCallback,
  FetchState,
  UseLazyRequestOptions,
  UseRequestReturn,
} from './types';
import { createErrorResponse } from './util/createErrorResponse';
import { invokeCallbackHandlingErrors } from './util/invokeCallbackHandlingErrors';

export const useLazyRequest = <R, P>(
  callback: RequestExecutionCallback<R, P>,
  options: UseLazyRequestOptions<R, P> = {}
): UseRequestReturn<R, P> => {
  const { onSuccess = noop, onFailed = noop, onFinally = noop } = options;

  const [fetchState, setFetchState] = useState<FetchState<R>>({
    response: undefined,
    error: undefined,
    isLoading: false,
    isError: false,
    isSuccessful: false,
  });

  const performRequest = async (params: P): Promise<R> => {
    setFetchState((prev) => ({
      ...prev,
      isLoading: true,
      isError: false,
      isSuccessful: false,
      error: undefined,
    }));

    try {
      const result = await callback(params);

      setFetchState({
        response: result,
        isLoading: false,
        error: undefined,
        isError: false,
        isSuccessful: true,
      });

      invokeCallbackHandlingErrors(() => onSuccess(result, params!));

      return result;
    } catch (error) {
      const errorResponse = createErrorResponse(error);

      setFetchState({
        response: undefined,
        error: errorResponse,
        isLoading: false,
        isError: true,
        isSuccessful: false,
      });

      invokeCallbackHandlingErrors(() => onFailed(errorResponse, params!));

      throw errorResponse;
    } finally {
      invokeCallbackHandlingErrors(() => onFinally(params!));
    }
  };

  return {
    ...fetchState,
    // Can safely use a non-nullish assertion here, as exec() can only be
    // called without parameter when performRequest() accepts undefined
    exec: (params?: P) => performRequest(params!),
  };
};
