import { isAfter } from 'date-fns';
import {
  MEMBERSHIP_STATUS,
  MEMBERSHIP_TIER,
  MEMBERSHIP_TIER_MAPPING,
  PROFILE_COMPLETION,
} from 'constants/vendor';
import { generateMediaImageUrl } from 'utils/index';
import { unifyTimestampToDate } from 'helpers/unifyTimestamp';
import { TRANSPORTATION_OVERALL_SERVICE_TYPE_ID } from 'constants/serviceTypes';
import { Vendor } from 'types/vendor';

export const isExpiredMemberships = (membershipEndDate: string | null) => {
  if (!membershipEndDate) return true;

  return isAfter(new Date(), unifyTimestampToDate(membershipEndDate));
};

export const isPremiumMemberships = (membershipTier: number) =>
  membershipTier > MEMBERSHIP_TIER.basic;

export const isLiteMemberships = (membershipTier: number) =>
  membershipTier === MEMBERSHIP_TIER.lite;

export const isGoldMemberships = (membershipTier: number) =>
  membershipTier === MEMBERSHIP_TIER.gold;

export const isValidPremiumMemberships = ({
  membershipTier,
  membershipEndDate,
}: {
  membershipTier: number;
  membershipEndDate: string;
}) => !isExpiredMemberships(membershipEndDate) && isPremiumMemberships(membershipTier);

export const isActiveMembershipStatus = (vendorStatusId: number) =>
  (
    [
      MEMBERSHIP_STATUS.ACTIVE,
      MEMBERSHIP_STATUS.RESTRICTED_ACCESS_PAYMENT_DUE,
      MEMBERSHIP_STATUS.RESTRICTED_DUE_TO_NON_RESPONSE,
      MEMBERSHIP_STATUS.VACATION_MODE_LEADS_PAUSED,
    ] as number[]
  ).includes(vendorStatusId);

export const isActive = (vendorStatusId: number) => vendorStatusId === 0;

export const isPaymentDue = (vendorStatusId: number) => vendorStatusId === -1;

export const isNonResponse = (vendorStatusId: number) => vendorStatusId === -2;

export const isIncompleteRegistration = ({ vendorStatusId }: { vendorStatusId: number }) =>
  vendorStatusId === -4;

export const isExpired = (vendorStatusId: number) => vendorStatusId === -6;

export const isVacationMode = (vendorStatusId: number) => vendorStatusId === -8;

export const getCategoryMaxNumber = (vendor: Vendor) =>
  MEMBERSHIP_TIER_MAPPING[vendor.membershipTier].maxCategoryCount;

export const getThumbnailUrl = (vendor: Vendor) => {
  const { searchResultPhoto } = vendor;

  if (!searchResultPhoto?.mediaApiGuid)
    return generateMediaImageUrl('dfdb0a11-f937-4cd6-b928-4bfb40348599', { resizeWidth: 216 });

  return generateMediaImageUrl(searchResultPhoto.mediaApiGuid, {
    ...searchResultPhoto,
    resizeWidth: 400,
  });
};

export const calProfileCompletion = (vendor: Vendor) => {
  let startingPricePercentage = 0;
  let profileDetailsPercentage = 0;
  let categoryPercentage = 0;
  let profilePhotoPercentage = 0;
  let galleryPhotosPercentage = 0;
  let videosPercentage = 0;
  if (vendor.minPayRange > 0) {
    startingPricePercentage = PROFILE_COMPLETION.percentages.startingPrice;
  }
  if ((vendor.description || '').trim().split(/\s+/).length > 100) {
    profileDetailsPercentage = PROFILE_COMPLETION.percentages.profileDetails;
  }
  if (vendor.serviceTypes.length > 1) {
    categoryPercentage = PROFILE_COMPLETION.percentages.categoryOptions;
  }
  if (vendor.profilePhoto && vendor.searchResultPhoto) {
    profilePhotoPercentage = PROFILE_COMPLETION.percentages.profileAndSearchPhoto;
  }
  if (vendor.numberOfPhoto) {
    galleryPhotosPercentage =
      vendor.numberOfPhoto >= PROFILE_COMPLETION.completeNeededCount.galleryPhotos
        ? PROFILE_COMPLETION.percentages.galleryPhotos
        : (PROFILE_COMPLETION.percentages.galleryPhotos /
            PROFILE_COMPLETION.completeNeededCount.galleryPhotos) *
          vendor.numberOfPhoto;
  }
  if (vendor.numberOfVideo) {
    videosPercentage = PROFILE_COMPLETION.percentages.videos;
  }
  const totalPercentage =
    startingPricePercentage +
    profileDetailsPercentage +
    categoryPercentage +
    profilePhotoPercentage +
    galleryPhotosPercentage +
    videosPercentage;

  return {
    startingPricePercentage,
    profileDetailsPercentage,
    categoryPercentage,
    profilePhotoPercentage,
    galleryPhotosPercentage,
    videosPercentage,
    totalPercentage,
  };
};

export const isAllowCreateVerifiedBooking = (vendor: Vendor) => {
  if (vendor.vendorStatusId === null) return false;
  return (
    (
      [
        MEMBERSHIP_STATUS.ACTIVE,
        MEMBERSHIP_STATUS.RESTRICTED_ACCESS_PAYMENT_DUE,
        MEMBERSHIP_STATUS.RESTRICTED_DUE_TO_NON_RESPONSE,
        MEMBERSHIP_STATUS.VACATION_MODE_LEADS_PAUSED,
      ] as number[]
    ).includes(vendor.vendorStatusId) &&
    vendor.overallServiceTypeId !== TRANSPORTATION_OVERALL_SERVICE_TYPE_ID
  );
};

export const hasVendorPaymentWarning = (vendor: Vendor) => {
  if (vendor.vendorStatusId === null) return false;
  return (
    [MEMBERSHIP_STATUS.RESTRICTED_ACCESS_PAYMENT_DUE, MEMBERSHIP_STATUS.EXPIRED] as number[]
  ).includes(vendor.vendorStatusId);
};

export const hasVendorRestrainingWarning = (vendor: Vendor) => {
  if (vendor.vendorStatusId === null) return false;
  return (
    [
      MEMBERSHIP_STATUS.RESTRICTED_ACCESS_PAYMENT_DUE,
      MEMBERSHIP_STATUS.EXPIRED,
      MEMBERSHIP_STATUS.MEMBERSHIP_REVOKED,
      MEMBERSHIP_STATUS.PROFILE_REMOVED_1,
    ] as number[]
  ).includes(vendor.vendorStatusId);
};

const vendorHelper = {
  isExpiredMemberships,
  isPremiumMemberships,
  isLiteMemberships,
  isGoldMemberships,
  isActive,
  isPaymentDue,
  isNonResponse,
  isExpired,
  isVacationMode,
  getCategoryMaxNumber,
  calProfileCompletion,
  isAllowCreateVerifiedBooking,
};

export default vendorHelper;
