/* eslint-disable no-param-reassign */
import axios from 'axios';
import { env, gmConfig } from 'config/index';
import { BEARER_TOKEN_COOKIE_NAME, JWT_COOKIE_NAME } from 'constants/storage';
import cookieHelper from 'helpers/cookieHelper';
import { requestUnauthorizedHandler } from 'helpers/axiosRequest';

export const vendorAPIRequest = axios.create({
  baseURL: `${gmConfig.domains.vendorApi}`,
  withCredentials: true,
});

vendorAPIRequest.interceptors.request.use(
  (config) => {
    config.headers = {
      ...config.headers,
      gmBearerToken: String(cookieHelper.get(BEARER_TOKEN_COOKIE_NAME)),
      token: cookieHelper.get(JWT_COOKIE_NAME) || '',
    };
    return config;
  },
  (error) => Promise.reject(error)
);

vendorAPIRequest.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (error.response?.status === 401) {
      const handledResult = await requestUnauthorizedHandler(vendorAPIRequest, error);
      return handledResult;
    }
    return Promise.reject(error);
  }
);

export const gigMasterAPIRequest = axios.create({
  // FIXME: This is a temporary fix to use the correct domain for local development
  baseURL: `${env === 'local-dev' ? gmConfig.domains.gmApi : gmConfig.domains.mcp}`,
  withCredentials: true,
});

gigMasterAPIRequest.interceptors.request.use(
  (config) => {
    config.headers = {
      ...config.headers,
      Authorization: `Bearer ${cookieHelper.get(BEARER_TOKEN_COOKIE_NAME)}`,
    };
    return config;
  },
  (error) => Promise.reject(error)
);

gigMasterAPIRequest.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (error.response?.status === 401) {
      const handledResult = await requestUnauthorizedHandler(gigMasterAPIRequest, error);
      return handledResult;
    }
    return Promise.reject(error);
  }
);

export const theBashWebAPIRequest = axios.create({
  baseURL: `${gmConfig.domains.www}`,
});

export const mediaAPIRequest = axios.create({
  baseURL: `${gmConfig.domains.mediaApi}`,
});

export const stripeFileAPIRequest = axios.create({
  baseURL: `https://${gmConfig.domains.stripeFile}`,
});
