export const SEND_QUOTE_EVENTS = {
  quoteFormSendButtonClickSuccessfully: 'Quote Form Send Button Clicked Ok',
  quoteFormSendButtonClickUnsuccessfully: 'Quote Form Send Button Clicked Fail',
  quoteFormPreviewButtonClickSuccessfully: 'Quote Form Preview Button Clicked Ok',
  quoteFormPreviewButtonClickUnsuccessfully: 'Quote Form Preview Button Clicked Fail',
  quoteFormCancelButtonClick: 'Quote Form Cancel Button Clicked',
  quoteFormInsertSavedResponseButtonClick: 'Quote Form Insert SR Button Clicked',
  quoteFormApplySavedResponseButtonClick: 'Quote Form Apply Saved Response Button Clicked',
  quoteFormCloseInsertSavedResponseDialogButtonClick: 'Quote Form Close Insert SR Button Clicked',
  quoteFormBackToEditButtonClick: 'Quote Form Back To Edit Button Clicked',
  quoteFormEditRatePeriodButtonClick: 'Quote Form Edit Rate Period Button Clicked',
  quoteFormSaveRatePeriodButtonClick: 'Quote Form Save Rate Period Button Clicked',
  quoteFormRatePeriodDialogClose: 'Quote Form Close Rate Period Button Clicked',
  quoteFormEditDepositOptionsButtonClick: 'Quote Form Edit Deposit Options Button Clicked',
  quoteFormSaveDepositOptionsButtonClick: 'Quote Form Save Deposit Options Button Clicked',
  quoteFormDepositOptionsDialogClose: 'Quote Form Close Deposit Options Button Clicked',
  quoteFormEditBalanceOptionsButtonClick: 'Quote Form Edit Balance Options Button Clicked',
  quoteFormSaveBalanceOptionsButtonClick: 'Quote Form Save Balance Options Button Clicked',
  quoteFormBalanceOptionsDialogClosed: 'Quote Form Balance Options Dialog Closed',
  quoteFormEditPaymentOptionsButtonClick: 'Quote Form Edit Payment Options Button Clicked',
  quoteFormSavePaymentOptionsButtonClick: 'Quote Form Save Payment Options Button Clicked',
  quoteFormPaymentOptionsDialogClose: 'Quote Form Close Payment Options Button Clicked',
  leadDeclineSendButtonClickSuccessfully: 'Lead Decline Send Button Clicked Ok',
  leadDeclinePreviewButtonClick: 'Lead Decline Preview Button Clicked',
  leadDeclineCancelButtonClick: 'Lead Decline Cancel Button Clicked',
  leadDeclineInsertSavedResponseButtonClick: 'Lead Decline Insert SR Button Clicked',
  leadDeclineApplySavedResponseButtonClick: 'Lead Decline Apply Saved Response Button Clicked',
  leadDeclineCloseInsertSavedResponseDialogButtonClick:
    'Lead Decline Close Insert SR Button Clicked',
  leadDeclineBackToEditButtonClick: 'Lead Decline Back To Edit Button Clicked',
} as const;

export interface SendQuoteBaseEventProperties {
  gigId: number;
  leadId: number;
  leadStatus: string;
  autoAdded: boolean;
}
export interface SendQuoteLeadEventProperties extends SendQuoteBaseEventProperties {
  budget: string;
  guestCount: string;
  eventDetailLength: number;
  fromPreview: boolean;
  serviceType: string;
  eventType: string;
  serviceLengthInMinutes: number;
  location: string;
  personalMessageLength: number;
}

interface ValidationErrorsEventProperties {
  validationErrors: Record<string, string>;
}

interface EventPayErrorEventProperties {
  hasEventPayError: boolean;
}

export type SendQuoteEventPropsMap = {
  [SEND_QUOTE_EVENTS.quoteFormSendButtonClickSuccessfully]: SendQuoteLeadEventProperties;
  [SEND_QUOTE_EVENTS.quoteFormSendButtonClickUnsuccessfully]: SendQuoteLeadEventProperties &
    ValidationErrorsEventProperties &
    EventPayErrorEventProperties;
  [SEND_QUOTE_EVENTS.quoteFormPreviewButtonClickSuccessfully]: SendQuoteBaseEventProperties;
  [SEND_QUOTE_EVENTS.quoteFormPreviewButtonClickUnsuccessfully]: SendQuoteBaseEventProperties &
    ValidationErrorsEventProperties &
    EventPayErrorEventProperties;
  [SEND_QUOTE_EVENTS.quoteFormCancelButtonClick]: SendQuoteLeadEventProperties &
    ValidationErrorsEventProperties;
  [SEND_QUOTE_EVENTS.quoteFormBackToEditButtonClick]: SendQuoteBaseEventProperties;
  [SEND_QUOTE_EVENTS.quoteFormInsertSavedResponseButtonClick]: SendQuoteLeadEventProperties &
    ValidationErrorsEventProperties;
  [SEND_QUOTE_EVENTS.quoteFormApplySavedResponseButtonClick]: SendQuoteLeadEventProperties;
  [SEND_QUOTE_EVENTS.quoteFormCloseInsertSavedResponseDialogButtonClick]: SendQuoteLeadEventProperties;
  [SEND_QUOTE_EVENTS.quoteFormEditRatePeriodButtonClick]: SendQuoteBaseEventProperties &
    ValidationErrorsEventProperties;
  [SEND_QUOTE_EVENTS.quoteFormSaveRatePeriodButtonClick]: SendQuoteBaseEventProperties &
    ValidationErrorsEventProperties;
  [SEND_QUOTE_EVENTS.quoteFormRatePeriodDialogClose]: SendQuoteBaseEventProperties &
    ValidationErrorsEventProperties;
  [SEND_QUOTE_EVENTS.quoteFormEditDepositOptionsButtonClick]: SendQuoteBaseEventProperties &
    ValidationErrorsEventProperties;
  [SEND_QUOTE_EVENTS.quoteFormSaveDepositOptionsButtonClick]: SendQuoteBaseEventProperties &
    ValidationErrorsEventProperties & {
      depositOption: string;
      depositRefundable: boolean;
    };
  [SEND_QUOTE_EVENTS.quoteFormDepositOptionsDialogClose]: SendQuoteBaseEventProperties &
    ValidationErrorsEventProperties & {
      depositOption: string;
      depositRefundable: boolean;
    };
  [SEND_QUOTE_EVENTS.quoteFormEditBalanceOptionsButtonClick]: SendQuoteBaseEventProperties &
    ValidationErrorsEventProperties;
  [SEND_QUOTE_EVENTS.quoteFormSaveBalanceOptionsButtonClick]: SendQuoteBaseEventProperties &
    ValidationErrorsEventProperties & { balanceRefundable: boolean };
  [SEND_QUOTE_EVENTS.quoteFormBalanceOptionsDialogClosed]: SendQuoteBaseEventProperties &
    ValidationErrorsEventProperties & { balanceRefundable: boolean };
  [SEND_QUOTE_EVENTS.quoteFormEditPaymentOptionsButtonClick]: SendQuoteBaseEventProperties &
    ValidationErrorsEventProperties;
  [SEND_QUOTE_EVENTS.quoteFormSavePaymentOptionsButtonClick]: SendQuoteBaseEventProperties &
    ValidationErrorsEventProperties & {
      paymentRefundable: boolean;
    };
  [SEND_QUOTE_EVENTS.quoteFormPaymentOptionsDialogClose]: SendQuoteBaseEventProperties &
    ValidationErrorsEventProperties & {
      paymentRefundable: boolean;
    };
  [SEND_QUOTE_EVENTS.leadDeclineSendButtonClickSuccessfully]: SendQuoteLeadEventProperties;
  [SEND_QUOTE_EVENTS.leadDeclinePreviewButtonClick]: SendQuoteBaseEventProperties &
    ValidationErrorsEventProperties;
  [SEND_QUOTE_EVENTS.leadDeclineCancelButtonClick]: SendQuoteLeadEventProperties &
    ValidationErrorsEventProperties;
  [SEND_QUOTE_EVENTS.leadDeclineInsertSavedResponseButtonClick]: SendQuoteLeadEventProperties;
  [SEND_QUOTE_EVENTS.leadDeclineApplySavedResponseButtonClick]: SendQuoteLeadEventProperties;
  [SEND_QUOTE_EVENTS.leadDeclineCloseInsertSavedResponseDialogButtonClick]: SendQuoteLeadEventProperties;
  [SEND_QUOTE_EVENTS.leadDeclineBackToEditButtonClick]: SendQuoteBaseEventProperties;
};
