import React from 'react';
import { useRouteMatch, useLocation } from 'react-router-dom';
import find from 'lodash/find';

import LeadsFoldersContext from 'context/leadsFolders';
import { trackPageViewEvent } from 'helpers/tracking/pageView';
import { LEADS_DEFAULT_FOLDERS } from 'views/Leads/constant';
import { useFeatureFlags, IsEnabledKey } from 'hooks/useFeatureFlags';

const defaultTitle = 'The Bash';

interface RouterPathMetadata {
  title: string;
  manualTracking?: boolean;
}

export const getRouterPathsMetadata: (
  featureFlags?: Record<IsEnabledKey, boolean>
) => Record<string, RouterPathMetadata> = (featureFlags) => ({
  '/membership-selection': { title: `Membership Selection | ${defaultTitle}` },
  '/dashboard': { title: `Dashboard | ${defaultTitle}` },
  '/membership': { title: `Membership | ${defaultTitle}` },
  '/profile/modify': { title: `Profile details | ${defaultTitle}` },
  '/profile/event-types': { title: `Event types | ${defaultTitle}` },
  '/profile/categories': {
    title: `${
      featureFlags?.isSecondaryCategorySearchPersonalizationEnabled
        ? 'Category and search options'
        : 'Category options'
    } | ${defaultTitle}`,
  },
  '/profile/profile-photo': { title: `Profile and search photo | ${defaultTitle}` },
  '/profile/gallery-photos': { title: `Gallery photos | ${defaultTitle}` },
  '/profile/related-profiles': { title: `Related profiles | ${defaultTitle}` },
  '/profile/song-list': { title: `Song list | ${defaultTitle}` },
  '/profile/group-members': { title: `Group members | ${defaultTitle}` },
  '/profile/reviews': { title: `Reviews | ${defaultTitle}`, manualTracking: true },
  '/profile/videos': { title: `Videos | ${defaultTitle}` },
  '/profile': { title: `Profile | ${defaultTitle}` },
  '/featured-profile/create': { title: `Purchase Featured Profiles | ${defaultTitle}` },
  '/featured-profile': { title: `Manage Featured Profiles | ${defaultTitle}` },
  '/account/vacation-mode': { title: `Vacation mode | ${defaultTitle}` },
  '/account/optional-features': { title: `Optional features | ${defaultTitle}` },
  '/account/contact-info': { title: `Contact information | ${defaultTitle}` },
  '/account/password': { title: `Password | ${defaultTitle}` },
  '/account/referral-bonus': { title: `Referral bonus program | ${defaultTitle}` },
  '/account/billing-information': {
    title: `Billing information | ${defaultTitle}`,
    manualTracking: true,
  },
  '/account/eventpay/create': { title: `Create EventPay account | ${defaultTitle}` },
  '/account/eventpay/:stripeAccountId/upload-document': {
    title: `Photo ID Verification | ${defaultTitle}`,
  },
  '/account/eventpay/:stripeAccountId/ssn': { title: `SSN Verification | ${defaultTitle}` },
  '/account/eventpay/:stripeAccountId': { title: `Edit EventPay account | ${defaultTitle}` },
  '/account/eventpay': { title: `EventPay | ${defaultTitle}` },
  '/account/transactions': { title: `Account transactions | ${defaultTitle}` },
  '/account': { title: `Account | ${defaultTitle}` },
  '/tools/contact-widget': { title: `Portable contact widget | ${defaultTitle}` },
  '/tools/reviews-widget': { title: `Portable review widget | ${defaultTitle}` },
  '/tools/awards-badges': { title: `Awards and badges | ${defaultTitle}` },
  '/tools/profile-exposure': { title: `Profile exposure | ${defaultTitle}` },
  '/tools/search-results': { title: `Search results analyzer | ${defaultTitle}` },
  '/tools/booking-dollars': { title: `Booking dollars | ${defaultTitle}` },
  '/tools': { title: `Tools | ${defaultTitle}` },
  '/leads/folders/:folderId': { title: `Leads folder | ${defaultTitle}` },
  '/leads/manage-folders': { title: `Manage folders | ${defaultTitle}` },
  '/leads/unanswered': { title: `Unanswered leads | ${defaultTitle}` },
  '/leads/saved-responses/create': {
    title: `Create saved response | ${defaultTitle}`,
    manualTracking: true,
  },
  '/leads/saved-responses/:savedResponseId': {
    title: `Update saved response | ${defaultTitle}`,
    manualTracking: true,
  },
  '/leads/saved-responses': { title: `Saved responses | ${defaultTitle}`, manualTracking: true },
  '/leads/:leadId/response': { title: `Lead response | ${defaultTitle}` },
  '/leads/:leadId/messages': { title: `Messages | ${defaultTitle}`, manualTracking: true },
  '/leads/:leadId': { title: `Lead details | ${defaultTitle}`, manualTracking: true },
  '/calendar/create-booking': { title: `Create verified booking | ${defaultTitle}` },
  '/calendar': { title: `Event calendar | ${defaultTitle}` },
  '/reviews': { title: `Reviews | ${defaultTitle}`, manualTracking: true },
});

const PageView = () => {
  const { leadsFolders } = React.useContext(LeadsFoldersContext);

  const location = useLocation();

  const featureFlags = useFeatureFlags();
  const routerPathsMetadata = getRouterPathsMetadata(featureFlags);

  const matched = useRouteMatch<{ folderId: string }>({
    path: Object.keys(routerPathsMetadata),
    strict: true,
    sensitive: true,
  });

  const title = React.useMemo(
    () => {
      if (!matched) {
        return defaultTitle;
      }

      return routerPathsMetadata[matched.path]?.title ?? defaultTitle;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [location.pathname]
  );

  const leadsFolderPageName = React.useMemo(() => {
    const folderName = find(leadsFolders, { id: Number(matched?.params?.folderId) })?.name || '';

    if ([LEADS_DEFAULT_FOLDERS.leadsInbox, LEADS_DEFAULT_FOLDERS.trash, ''].includes(folderName)) {
      return folderName;
    }

    return 'Custom Folder';
  }, [leadsFolders, matched?.params?.folderId]);

  React.useEffect(() => {
    window.document.title = title;
  }, [title]);

  React.useEffect(() => {
    const [pageName] = title.split('|');
    if (
      matched &&
      !matched.params?.folderId && // folders are managed in a useEffect below
      !routerPathsMetadata[matched.path]?.manualTracking
    ) {
      trackPageViewEvent(pageName.trim(), {});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  React.useEffect(() => {
    const folderId = matched?.params?.folderId;
    if (folderId) {
      const folderName = find(leadsFolders, { id: Number(folderId) })?.name || '';

      if (folderName) {
        window.document.title = `${folderName} | ${defaultTitle}`;
      }
    }
  }, [leadsFolders, matched?.params?.folderId]);

  React.useEffect(() => {
    if (leadsFolderPageName) {
      trackPageViewEvent(leadsFolderPageName, {
        title: `${leadsFolderPageName} | ${defaultTitle}`,
      });
    }
  }, [matched?.params?.folderId, leadsFolderPageName]);

  return <></>;
};

export default PageView;
