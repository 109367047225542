import React from 'react';

const useIsOverflow = () => {
  const elementRef = React.useRef<HTMLDivElement>(null);
  const [isOverflow, setIsOverflow] = React.useState(false);

  React.useLayoutEffect(() => {
    if (elementRef.current) {
      const hasOverflowed = elementRef.current.scrollWidth > elementRef.current.offsetWidth;
      setIsOverflow(hasOverflowed);
    }
  }, []);

  return { ref: elementRef, isOverflow };
};

export default useIsOverflow;
