import React from 'react';
import vendorApi from 'api/vendor';
import accountApi from 'api/account';
import VendorContext from 'context/vendor';
import { useLazyRequest } from '../request/useLazyRequest';
import { NotificationScope, NotificationScopeValue } from './constants';

export interface UseCreateNotificationProps {
  notificationType: string;
  notificationScope: NotificationScopeValue;
  onSuccess?: () => void;
  onFailed?: () => void;
  onFinally?: () => void;
}

export const useCreateNotification = ({
  notificationType,
  notificationScope,
  onSuccess,
  onFailed,
  onFinally,
}: UseCreateNotificationProps) => {
  const { vendor } = React.useContext(VendorContext);

  const createNotification = async () => {
    if (notificationScope === NotificationScope.PROFILE) {
      await vendorApi.createNotification({
        vendorId: vendor.vendorId,
        type: notificationType,
      });
    } else {
      await accountApi.createNotification({
        type: notificationType,
      });
    }
  };

  const { exec, isLoading, isError, isSuccessful } = useLazyRequest<void, void>(
    createNotification,
    {
      onSuccess,
      onFailed,
      onFinally,
    }
  );

  return {
    create: exec,
    isLoading,
    isError,
    isSuccessful,
  };
};
