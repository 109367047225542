import React from 'react';
import axios from 'axios';
import { isProduction } from 'config/index';
import isEmpty from 'lodash/isEmpty';
import findKey from 'lodash/findKey';
import { noop } from 'utils/function';

const handleResponseError = (e) => {
  const error = {
    name: e.name,
    message: e.message,
    details: e.details,
    stack: isProduction ? undefined : e.stack,
    response: e.response,
  };

  if (e.response) {
    error.statusCode = e.response.status;
    error.message = e.response.data.message;

    if (!isEmpty(e.response.data.validation)) {
      /* eslint prefer-destructuring: ["error", {AssignmentExpression: {array: false}}] */
      error.validationKey = e.response.data.validation.keys[0];
    }

    if (!isEmpty(e.response.data.errors)) {
      /* eslint prefer-destructuring: ["error", {AssignmentExpression: {array: false}}] */
      error.validationKey = e.response.data.errors?.[0]?.name;
    }
  }

  return error;
};

/**
 * @deprecated use [useLazyRequest]{@link import('hooks/request/useLazyRequest').useLazyRequest} or [useEagerRequest]{@link import('hooks/request/useEagerRequest').useEagerRequest} instead.
 */
const useRequest = (service, options = {}) => {
  const {
    manual = false,
    defer = 0,
    params: reqParams,
    onSuccess = noop,
    onFailed = noop,
    onFinally = noop,
    wrapError = true,
    fetchKey = () => +new Date(),
    includeExecException = false,
  } = options;

  const ref = React.useRef({
    isInitialMount: true,
    time: 0,
    axiosCancelTokenSource: axios.CancelToken.source(),
    params: reqParams,
  });

  const [fetchState, setFetchState] = React.useState({
    response: {},
    error: {},
    isLoading: !manual,
    isError: false,
    fetches: {},
  });

  const request = async (params) => {
    const key = fetchKey(params).toString();
    ref.current.axiosCancelTokenSource = axios.CancelToken.source();

    setFetchState((prevFetchState) => ({
      ...prevFetchState,
      isLoading: true,
      isError: false,
      error: {},
      fetches: { ...prevFetchState.fetches, [key]: { isLoading: true, isError: false } },
    }));

    try {
      const result = await (params
        ? service(params, ref.current.axiosCancelTokenSource.token)
        : service(ref.current.axiosCancelTokenSource.token));

      setFetchState((prevFetchState) => ({
        ...prevFetchState,
        response: result,
        fetches: { ...prevFetchState.fetches, [key]: { isLoading: false, isError: false } },
        isLoading: !!findKey(prevFetchState.fetches, (o, k) => k !== key && o.isLoading),
      }));
      onSuccess(result, params);
      return { response: result, params, isError: false };
    } catch (e) {
      let wrappedError = e;
      if (wrapError) wrappedError = handleResponseError(e);
      setFetchState((prevFetchState) => ({
        response: {},
        error: wrappedError,
        fetches: { ...prevFetchState.fetches, [key]: { isLoading: false, isError: true } },
        isLoading: !!findKey(prevFetchState.fetches, (o, k) => k !== key && o.isLoading),
        isError: true,
      }));
      onFailed(wrappedError, params);
      if (includeExecException) {
        throw wrappedError;
      }
      return { e, params, isError: true };
    } finally {
      onFinally();
    }
  };

  const deferRequest = (params) => {
    clearTimeout(ref.current.time);

    return new Promise((resolve) => {
      ref.current.time = setTimeout(() => {
        resolve(request(params));
      }, defer);
    });
  };

  const exec = defer ? deferRequest : request;

  React.useEffect(() => {
    if (!manual) {
      exec(reqParams);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [manual]);

  return {
    ...fetchState,
    exec,
    axiosCancelTokenSource: ref.current.axiosCancelTokenSource,
  };
};

export default useRequest;
