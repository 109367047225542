import { Theme } from '@mui/material';

const styles = {
  childrenMenuItemRoot: (theme: Theme) => ({
    display: 'flex',
    padding: '0px 20px',
    paddingLeft: '42px',
    textTransform: 'unset',
    '& > a': {
      color: theme.palette.grey[300],
      textDecoration: 'none',
      display: 'inline-flex',
      alignItems: 'center',
    },
    '& > a:hover': {
      textDecoration: 'none',
      color: theme.palette.blue[100],
    },
    '& > a > svg': {
      fill: theme.palette.grey[300],
    },
    '& > a:hover > svg': {
      fill: theme.palette.blue[100],
    },
    '&.active': {
      '& > a': {
        borderLeft: `1px solid ${theme.palette.blue[300]}`,
        ...theme.typography.body1,
        backgroundColor: theme.palette.action.background,
        borderRadius: '0px 4px 4px 0px',
        color: theme.palette.grey[300],
      },
      '& > a > span > svg': {
        fill: theme.palette.grey[300],
      },
      '&:hover': {
        '& > a': {
          color: theme.palette.blue[100],
        },
        '& > a > span > svg': {
          fill: theme.palette.blue[100],
        },
      },
    },
  }),
  linkIcon: {
    display: 'flex',
    margin: '0px 10px 0px 0px',
    '& svg': {
      width: 24,
      height: 24,
    },
  },
  itemLink: (theme: Theme) => ({
    padding: '8px 12px 8px 22px',
    borderLeft: `1px solid ${theme.palette.grey[600]}`,
    alignItems: 'center',
    width: '100%',
  }),

  itemLinkTitle: (theme: Theme) => ({
    marginRight: 'auto',
    wordBreak: 'break-word',
    whiteSpace: 'pre-wrap',
    [theme.breakpoints.up('sm')]: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
  }),
  unansweredBox: (theme: Theme) => ({
    [theme.breakpoints.down('md')]: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      paddingTop: '2px',
    },
  }),
  unanswered: (theme: Theme) => ({
    padding: '0px 8px',
    marginLeft: '10px',
    backgroundColor: theme.palette.blue[400],
    borderRadius: '20px',
    ...theme.typography.caption,
    color: theme.palette.primary.contrastText,
  }),
};

export default styles;
