import React from 'react';
import PropTypes from 'prop-types';
import { noop } from 'utils/function';
import { styled } from '@mui/material';
import MuiSnackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Typography from '@mui/material/Typography';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import CancelIcon from '@mui/icons-material/Cancel';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import LinkButton from 'components/Form/LinkButton';
import { classes, styles } from './styles';

const StyledSnackbar = styled(MuiSnackbar)(styles.styledRoot);

export const SNACKBAR_TYPES = {
  ERROR: 'error',
  WARNING: 'warning',
  SUCCESS: 'success',
};

const Snackbar = ({
  open: propOpen,
  type,
  content,
  onClose,
  autoHideDuration,
  actionText,
  action,
  ...otherProps
}) => {
  const [open, setOpen] = React.useState(propOpen);

  React.useEffect(() => {
    setOpen(propOpen);
  }, [propOpen]);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
    onClose();
  };

  return (
    <StyledSnackbar
      {...otherProps}
      open={open}
      type={type}
      autoHideDuration={autoHideDuration}
      onClose={handleClose}
      data-testid={`${type}-snackbar`}
    >
      <Alert
        className={classes.alert}
        severity={type}
        content={content}
        iconMapping={{
          success: <CheckCircleIcon />,
          error: <CancelIcon />,
          warning: <ErrorIcon />,
        }}
        action={<CloseRoundedIcon onClick={handleClose} />}
      >
        <Typography variant="subtitle2" gutterBottom={false} align="left" sx={styles.messageText}>
          {content}
          {action && (
            <>
              {' '}
              <LinkButton
                variant="subtitle2"
                color="secondary"
                onClick={action}
                underline="always"
                data-testid="snackbar-action-button"
                sx={styles.actionButton}
              >
                {actionText}
              </LinkButton>
            </>
          )}
        </Typography>
      </Alert>
    </StyledSnackbar>
  );
};

Snackbar.propTypes = {
  open: PropTypes.bool,
  type: PropTypes.oneOf(['success', 'error', 'warning']),
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  onClose: PropTypes.func,
  actionText: PropTypes.string,
  action: PropTypes.func,
  autoHideDuration: PropTypes.number,
};

Snackbar.defaultProps = {
  open: false,
  type: 'success',
  content: 'Your changes were saved successfully.',
  autoHideDuration: 6000,
  actionText: '',
  action: null,
  onClose: noop,
};

export default Snackbar;
