import React from 'react';
import { styled } from '@mui/material/styles';

import Loader from 'components/Loader';
import { withGlobalContext } from 'hoc/withGlobalContext';

import styles from './styles';

const StyledLoader = styled(Loader)(styles.root);

export const GlobalLoader = (props) => <StyledLoader {...props} />;

export const mapStateToProps = ({ ui: { isLoading } }) => ({
  isLoading,
});

const ContextGlobalLoader = withGlobalContext(mapStateToProps)(React.memo(GlobalLoader));

ContextGlobalLoader.displayName = 'ContextGlobalLoader';

export default ContextGlobalLoader;
