import { Theme } from '@mui/material';

const PREFIX = 'component-ActionSnackbar';

export const classes = {
  contentRoot: `${PREFIX}-contentRoot`,
  contentMessage: `${PREFIX}-contentMessage`,
};

export const styles = {
  styledRoot: ({ theme }: { theme: Theme }) => ({
    [theme.breakpoints.down('sm')]: {
      left: '20px',
      right: '20px',
      bottom: '16px',
    },

    [theme.breakpoints.up('sm')]: {
      left: '32px',
      bottom: '16px',
    },

    [theme.breakpoints.up('md')]: {
      left: '312px',
      bottom: '16px',
    },

    [`& .${classes.contentRoot}`]: {
      paddingTop: '8px',
      paddingBottom: '8px',
      minWidth: '180px',
      maxWidth: '500px',
      borderRadius: '2px',
      backgroundColor: theme.palette.grey[900],
      boxShadow: `0px 0px 10px ${theme.palette.common.black}33`,
      [theme.breakpoints.down('sm')]: {
        minWidth: 'unset',
        maxWidth: 'unset',
      },
    },
    [`& .${classes.contentMessage}`]: {
      wordBreak: 'break-word',
      textAlign: 'left',
      display: 'inline',
      padding: '0px',
    },
  }),
};
