import React from 'react';

import { SxProps, Theme } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import ButtonBase from '@mui/material/ButtonBase';
import useMediaQuery from '@mui/material/useMediaQuery';

import InstallBannerLeft from 'components/Icon/InstallBannerLeft';
import InstallBannerRight from 'components/Icon/InstallBannerRight';
import { usePwaContextDispatch, usePwaContextState } from 'context/pwa';
import PwaInstallTutorialDialog from 'components/Dialog/PwaInstallTutorialDialog';
import PwaInstallVisualGuidance from 'components/PwaInstallVisualGuidance';
import { verticalScroll } from 'utils/index';

import { useTheme } from '@mui/material/styles';
import styles from './styles';

type PwaInstallBannerProps = {
  sx?: SxProps;
};
export const PwaInstallBanner = (props: PwaInstallBannerProps) => {
  const theme = useTheme();
  const isGtTablet = useMediaQuery((localTheme: Theme) => localTheme.breakpoints.up('md'));

  const { sx } = props;
  const {
    webInstallWebPromptEvent,
    showIosInstallPromptEvent,
    handleWebInstallPrompt,
    openPWAVisualGuidance,
    setOpenPWAVisualGuidance,
    guidanceStep,
  } = {
    ...usePwaContextState(),
    ...usePwaContextDispatch(),
  };

  const [openInstallTutorial, setOpenInstallTutorial] = React.useState(false);

  React.useEffect(() => {
    const selectors = `[data-testid=pwa-install-banner]`;
    const scrollIntoViewOptions = { block: 'end' };

    if (guidanceStep === 'dismiss' || guidanceStep === 'check now') {
      // @ts-expect-error The vertical scroll isn't in TS yet
      verticalScroll(selectors, scrollIntoViewOptions);
    }
  }, [guidanceStep]);

  const handleTooltipClose = () => {
    setOpenPWAVisualGuidance(false);
  };

  return (
    (webInstallWebPromptEvent || showIosInstallPromptEvent) && (
      <Box sx={[styles.root, ...(Array.isArray(sx) ? sx : [sx])]} data-testid="pwa-install-banner">
        <InstallBannerLeft sx={styles.bannerLeftImage} />
        <InstallBannerRight sx={styles.bannerRightImage} />
        {webInstallWebPromptEvent && (
          <PwaInstallVisualGuidance
            open={isGtTablet && openPWAVisualGuidance}
            onClose={handleTooltipClose}
          >
            <ButtonBase
              data-testid="web-install-button"
              sx={styles.button}
              onClick={() => {
                handleTooltipClose();
                handleWebInstallPrompt();
              }}
            />
          </PwaInstallVisualGuidance>
        )}
        {showIosInstallPromptEvent && (
          <PwaInstallVisualGuidance
            open={isGtTablet && openPWAVisualGuidance}
            onClose={handleTooltipClose}
          >
            <ButtonBase
              data-testid="ios-install-button"
              sx={styles.button}
              onClick={() => {
                handleTooltipClose();
                setOpenInstallTutorial(true);
              }}
            />
          </PwaInstallVisualGuidance>
        )}
        <Box sx={styles.content}>
          <Typography
            variant="subtitle1"
            color={theme.palette.common.white}
          >{`Install The Bash's App`}</Typography>
          <Typography variant="caption" color={theme.palette.common.white}>
            {`For quick account access and lead notifications.`}
          </Typography>
        </Box>
        {openInstallTutorial && (
          <PwaInstallTutorialDialog
            // @ts-expect-error component isn't in TS
            fullScreen
            open
            onClose={() => {
              setOpenInstallTutorial(false);
            }}
          />
        )}
      </Box>
    )
  );
};

export default PwaInstallBanner;
