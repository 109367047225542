import React from 'react';
import { styled } from '@mui/material/styles';
import { gmConfig } from 'config/index';

import styles from './styles';

const StyledBrandImage = styled('img', { name: 'BrandImage' })(styles.root);

const BrandImage = (props) => (
  <StyledBrandImage
    alt="BrandImage"
    src={`https://${gmConfig.domains.cdn}/logo/the-bash-header-logo.svg`}
    {...props}
  />
);

export default BrandImage;
