import React from 'react';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { NewFeatureDialog } from 'components/Dialog/NewFeatureDialog';
import { OnDismissSources, OnDismissSourceValue } from 'components/Dialog/NewFeatureDialog/types';
import { useHistory } from 'react-router-dom';
import VendorContext from 'context/vendor';
import { useFeatureFlags } from 'hooks/useFeatureFlags';
import { NotificationScope } from 'hooks/notification/constants';
import { useIsPhotoBoothVendor } from 'hooks/useIsPhotoBoothVendor';
import styles from './styles';

const shouldShowNotification = (route: string) =>
  !route.includes('/leads') || route.includes('/messages');

const SEOFeatureNotificationDialog = () => {
  const history = useHistory();
  const currentRoute = history.location.pathname;
  const { isLoading: isGetVendorLoading } = React.useContext(VendorContext);
  const {
    isSecondaryCategorySearchPersonalizationEnabled: isSCSPEnabled,
    isSecondaryCategorySearchPersonalizationExtraEnabled: isSCSPExtraEnabled,
  } = useFeatureFlags();

  const handleDismiss = (source: OnDismissSourceValue) => {
    if (source === OnDismissSources.DISMISS_BUTTON && currentRoute !== '/profile/categories') {
      history.push('/profile/categories');
    }
  };

  // Temporary/dirty check because Photo Booth vendors have no secondary category and this modal will be removed soon anyway
  const isPhotoBoothVendor = useIsPhotoBoothVendor();

  if (
    !isSCSPEnabled ||
    !isSCSPExtraEnabled ||
    isGetVendorLoading ||
    !shouldShowNotification(currentRoute) ||
    isPhotoBoothVendor
  ) {
    return null;
  }

  return (
    <NewFeatureDialog
      title="🚨 New feature!"
      notificationType="SEONewFeatureNotification"
      dismissButtonText="Get Started"
      notificationScope={NotificationScope.ACCOUNT}
      closable
      onDismiss={handleDismiss}
    >
      <Box sx={{ paddingTop: '16px', paddingBottom: '4px' }}>
        <Typography variant="subtitle2" color="text.primary">
          You can now customize how your profile appears in search results by uploading a unique
          photo and creating a tailored description for each secondary category that you’re listed
          in.
          <br />
          <br />
          By default, your main profile photo and description will be used for all
          categories—customize these now to drive the most relevant leads to your business. Tip: Use
          our AI feature to easily create custom descriptions.
        </Typography>
      </Box>
      <Box sx={styles.learnMoreBox}>
        <Typography variant="subtitle1" color="text.primary">
          Learn more
        </Typography>
        <ul>
          <li>
            <Link
              variant="subtitle2"
              color="primary"
              href="https://itg.thebash.com/selecting-your-categories"
              underline="always"
              target="_blank"
            >
              How to Select Categories & Customize Your Search Results Tile
            </Link>
          </li>
        </ul>
      </Box>
    </NewFeatureDialog>
  );
};

export default SEOFeatureNotificationDialog;
