import {
  type IToggle,
  useUnleashContext,
  useFlagsStatus,
  useFlags,
  FlagProvider,
} from '@tkww/tkww-feature-flags-sdk-react';
import type { PropsWithChildren } from 'react';
import { gmConfig } from 'config/index';
import { FeatureFlagValue, FeatureFlagsModule, FeatureFlagsContext } from './types';
import { FEATURE_FLAGS } from './constants';

const initUnleashFeatureFlagsModule = (): FeatureFlagsModule => {
  const FeatureFlagsContextProvider = ({ children }: PropsWithChildren) => (
    // FIXME improve config type-inference
    // @ts-expect-error config some required fields of the config as inferred as optional
    <FlagProvider config={gmConfig.featureFlags.unleash}>{children}</FlagProvider>
  );

  const knownFeatureFlags: string[] = Object.values(FEATURE_FLAGS);

  const getEnabledFeatureFlags = (flags: IToggle[]) =>
    flags
      .filter((flag) => flag.enabled)
      .map((flag) => flag.name)
      .filter((flagName): flagName is FeatureFlagValue => knownFeatureFlags.includes(flagName));

  const useFeatureFlagsContext = (): FeatureFlagsContext => {
    const updateUnleashContext = useUnleashContext();
    const status = useFlagsStatus();
    const flags = useFlags();

    return {
      enabledFeatureFlags: getEnabledFeatureFlags(flags),
      status: {
        isReady: status.flagsReady,
        isError: status.flagsError,
      },
      setUserInfo: (info) => {
        updateUnleashContext({
          userId: info.vendorLegacyId != null ? String(info.vendorLegacyId) : undefined,
          sessionId: info.accountId != null ? String(info.accountId) : undefined,
        });
      },
    };
  };

  return {
    FeatureFlagsContextProvider,
    useFeatureFlagsContext,
  };
};

export default initUnleashFeatureFlagsModule;
