import { generatePageEventAction } from 'redux/action';
import store from 'redux/store';
import { isPwa } from 'helpers/deviceHelper';
import { MessagesWithRestrictionsProperties } from './messages';
import { ReviewDetailBaseEventProperties, ReviewsBaseEventProperties } from './reviews';
import { LeadStatusValue, ResponseTypeValue } from '../../constants/leads';
import { SavedResponsesListEventProperties } from './savedResponses';
import { transformEventPropertiesCase } from './helpers';
import { ManageMembershipPageProps } from './membership';
import { BillingInformationBaseEventProperties } from './billingInformation';

export const TrackingPageNames = {
  LEAD_DETAIL: 'Lead details',
  MESSAGES: 'Messages',
  REVIEWS: 'Reviews',
  REVIEW_DETAIL: 'Review detail',
  SAVED_RESPONSES: 'Saved responses',
  CREATE_SAVED_RESPONSE: 'Create raved response',
  MEMBERSHIP: 'Membership',
  BILLING_INFORMATION: 'Billing information',
} as const;

export interface CommonPageViewEventProps {
  title?: string;
}

type CustomPageViewEventPropsMap = {
  [TrackingPageNames.LEAD_DETAIL]: {
    gigId: number;
    leadId: number;
    leadStatus?: LeadStatusValue;
    autoAdded: boolean;
    serviceType: string;
  };
  [TrackingPageNames.MESSAGES]: MessagesWithRestrictionsProperties;
  [TrackingPageNames.REVIEWS]: ReviewsBaseEventProperties;
  [TrackingPageNames.REVIEW_DETAIL]: ReviewDetailBaseEventProperties;
  [TrackingPageNames.SAVED_RESPONSES]: SavedResponsesListEventProperties;
  [TrackingPageNames.CREATE_SAVED_RESPONSE]: {
    responseType: ResponseTypeValue;
    isEditing: boolean;
  };
  [TrackingPageNames.MEMBERSHIP]: ManageMembershipPageProps;
  [TrackingPageNames.BILLING_INFORMATION]: BillingInformationBaseEventProperties;
};

type InferPageViewEventProps<P extends keyof CustomPageViewEventPropsMap | string> =
  P extends keyof CustomPageViewEventPropsMap
    ? CommonPageViewEventProps & CustomPageViewEventPropsMap[P]
    : CommonPageViewEventProps;

const newTabEventAlreadyTracked = 'tracking:newTabEventAlreadyTracked';

const isMembershipSelectionPage = () => window.location.pathname === '/membership-selection';

export function trackPageViewEvent<P extends keyof CustomPageViewEventPropsMap | string>(
  pageName: P,
  props: InferPageViewEventProps<P>
): void {
  const isNewTab = sessionStorage.getItem(newTabEventAlreadyTracked) == null;

  if (!isMembershipSelectionPage()) {
    sessionStorage.setItem(newTabEventAlreadyTracked, 'true');
  }

  store.dispatch(
    generatePageEventAction(
      pageName,
      transformEventPropertiesCase({
        ...props,
        title: props.title ?? document.title,
        newTab: isNewTab,
        isPwa: isPwa(),
      })
    )
  );
}
