import React, { PropsWithChildren } from 'react';
import classNames from 'classnames';
import Box, { BoxProps } from '@mui/material/Box';

import ErrorMessage from './ErrorMessage';
import Spinner from './Spinner';

import styles from './styles';

export interface LoaderProps extends BoxProps {
  isError?: boolean;
  isLoading?: boolean;
  withMask?: boolean;
  spinnerComponent?: React.ReactNode;
  errorMessage?: React.ReactNode;
  className?: string;
}

const Loader = ({
  isError = false,
  isLoading = true,
  withMask,
  children = null,
  spinnerComponent,
  errorMessage,
  className,
  sx = {},
  ...other
}: PropsWithChildren<LoaderProps>) => {
  const spinner = spinnerComponent || <Spinner />;

  if (isLoading || isError) {
    return (
      <Box
        component="span"
        data-testid="Loader"
        sx={[styles.loaderContainer, ...(Array.isArray(sx) ? sx : [sx])]}
        className={classNames('Loader-root', className)}
        {...other}
      >
        {isLoading && (withMask ? <Box sx={styles.maskBox}>{children}</Box> : spinner)}
        {isError && (
          <ErrorMessage
            errorMessage={errorMessage}
            sx={{
              ...(isLoading && styles.isLoading),
            }}
          />
        )}
      </Box>
    );
  }

  return <>{children}</>;
};

export default Loader;
