import { HEADER_HEIGHT } from './components/Header/styles';

export const classes = {
  root: 'app-content-box',
};

const styles = {
  coloredBackground: {
    display: 'flex',
    backgroundColor: 'grey.100',
  },
  appContentBox: {
    marginTop: `${HEADER_HEIGHT}px`,
    width: '100%',

    '@media print': {
      marginTop: 0,
    },
  },
  loaderPage: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 2,
    minHeight: '500px',
    backgroundColor: 'grey.100',
  },
  loaderError: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 2,
    minHeight: '500px',
    backgroundColor: 'grey.100',
  },
  footer: {
    overflowAnchor: 'none',

    '@media print': {
      display: 'none',
    },
  },
};

export default styles;
