import palette from 'theme/palette';

// FIXME: Some mappings are duplicated on the backend, extract to a package when we're in the monorepo
export const MEMBERSHIP_TIER = {
  lite: -1,
  basic: 0,
  professional: 1,
  gold: 2,
} as const;

export type MembershipTierKey = keyof typeof MEMBERSHIP_TIER;
export type MembershipTierValue = (typeof MEMBERSHIP_TIER)[MembershipTierKey];

export const MEMBERSHIP_TIER_MAPPING = {
  [MEMBERSHIP_TIER.lite]: { name: 'Lite', maxCategoryCount: 2 },
  [MEMBERSHIP_TIER.basic]: { name: 'Basic', maxCategoryCount: 6 },
  [MEMBERSHIP_TIER.professional]: { name: 'Professional', maxCategoryCount: 12 },
  [MEMBERSHIP_TIER.gold]: { name: 'Gold', maxCategoryCount: 18 },
};

export const MEMBERSHIP_STATUS = {
  ACTIVE: 0,
  RESTRICTED_ACCESS_PAYMENT_DUE: -1,
  RESTRICTED_DUE_TO_NON_RESPONSE: -2,
  REGISTRATION_INCOMPLETE: -4,
  EXPIRED: -6,
  MEMBERSHIP_REVOKED: -7,
  VACATION_MODE_LEADS_PAUSED: -8,
  PROFILE_REMOVED_1: -9,
  PROFILE_REMOVED_2: -13,
} as const;

export type MembershipStatusKey = keyof typeof MEMBERSHIP_STATUS;
export type MembershipStatusValue = (typeof MEMBERSHIP_STATUS)[MembershipStatusKey];

export interface MembershipStatusIdMappingValue {
  statusDescription: string;
  statusColor: string;
}

export const MEMBERSHIP_STATUS_ID_MAPPING: Record<
  MembershipStatusValue,
  MembershipStatusIdMappingValue
> = {
  [MEMBERSHIP_STATUS.ACTIVE]: {
    statusDescription: 'Active',
    statusColor: palette.success.main,
  },
  [MEMBERSHIP_STATUS.RESTRICTED_ACCESS_PAYMENT_DUE]: {
    statusDescription: 'Restricted access: Payment due',
    statusColor: palette.warning.main,
  },
  [MEMBERSHIP_STATUS.RESTRICTED_DUE_TO_NON_RESPONSE]: {
    statusDescription: 'Restricted due to non-response',
    statusColor: palette.error.main,
  },
  [MEMBERSHIP_STATUS.REGISTRATION_INCOMPLETE]: {
    statusDescription: 'Registration incomplete',
    statusColor: palette.error.main,
  },
  [MEMBERSHIP_STATUS.EXPIRED]: {
    statusDescription: 'Expired',
    statusColor: palette.text.secondary,
  },
  [MEMBERSHIP_STATUS.MEMBERSHIP_REVOKED]: {
    statusDescription: 'Membership revoked',
    statusColor: palette.text.secondary,
  },
  [MEMBERSHIP_STATUS.VACATION_MODE_LEADS_PAUSED]: {
    statusDescription: 'Vacation mode - Leads paused',
    statusColor: palette.text.secondary,
  },
  [MEMBERSHIP_STATUS.PROFILE_REMOVED_1]: {
    statusDescription: 'Profile removed',
    statusColor: palette.text.secondary,
  },
  [MEMBERSHIP_STATUS.PROFILE_REMOVED_2]: {
    statusDescription: 'Profile removed',
    statusColor: palette.text.secondary,
  },
};

export interface MembershipStatusLabelData {
  statusDescription: string;
  labelColor: string;
  labelTextColor: string;
  extraInfo?: string;
}

export const MEMBERSHIP_STATUS_LABEL_ID_MAPPING: Record<
  MembershipStatusValue,
  MembershipStatusLabelData
> = {
  [MEMBERSHIP_STATUS.ACTIVE]: {
    statusDescription: 'Active',
    labelColor: palette.mint[50],
    labelTextColor: palette.mint[700],
  },
  [MEMBERSHIP_STATUS.RESTRICTED_ACCESS_PAYMENT_DUE]: {
    statusDescription: 'Payment due',
    labelColor: palette.warning.light,
    labelTextColor: palette.warning.dark,
  },
  [MEMBERSHIP_STATUS.RESTRICTED_DUE_TO_NON_RESPONSE]: {
    statusDescription: 'Restricted due to non-response',
    labelColor: palette.error.light,
    labelTextColor: palette.error.main,
  },
  [MEMBERSHIP_STATUS.REGISTRATION_INCOMPLETE]: {
    statusDescription: 'Registration incomplete',
    labelColor: palette.error.light,
    labelTextColor: palette.error.main,
  },
  [MEMBERSHIP_STATUS.MEMBERSHIP_REVOKED]: {
    statusDescription: 'Membership revoked',
    labelColor: palette.error.light,
    labelTextColor: palette.error.main,
  },
  [MEMBERSHIP_STATUS.EXPIRED]: {
    statusDescription: 'Expired',
    labelColor: palette.grey[100],
    labelTextColor: palette.grey[600],
  },
  [MEMBERSHIP_STATUS.VACATION_MODE_LEADS_PAUSED]: {
    statusDescription: 'Vacation mode',
    labelColor: palette.grey[100],
    labelTextColor: palette.grey[600],
    extraInfo: 'Leads paused',
  },
  [MEMBERSHIP_STATUS.PROFILE_REMOVED_1]: {
    statusDescription: 'Profile removed',
    labelColor: palette.error.light,
    labelTextColor: palette.error.main,
  },
  [MEMBERSHIP_STATUS.PROFILE_REMOVED_2]: {
    statusDescription: 'Profile removed',
    labelColor: palette.error.light,
    labelTextColor: palette.error.main,
  },
};

export const DISABLED_SONG_LIST_SERVICE_IDS = [4000, 6000, 6100, 6200] as const;

export const PROFILE_COMPLETION = {
  percentages: {
    startingPrice: 5,
    profileDetails: 30,
    categoryOptions: 25,
    profileAndSearchPhoto: 10,
    galleryPhotos: 10,
    videos: 20,
    audioSamples: 0,
  },
  completeNeededCount: {
    galleryPhotos: 10,
  },
} as const;

export const OPTIONAL_FEATURES_ID_MAPPING = {
  searchResultsAnalyzer: 1,
  finalOutcomeEmails: 2,
  inDemand: 5,
  leadAlerts: 6,
  clientMessageAlerts: 7,
} as const;

export const ALERT_ID_MAPPING = {
  membershipExpirationBefore: 1,
  membershipExpirationAfter: 2,
  pastDue: 3,
  disabledAutoAddLeads: 4,
  newGigAlerts: 5,
  clientMessage: 6,
  redeemPayments: 7,
  autoRenewExpiration: 8,
  bookingFeeUpdates: 47,
  outstandingStripeInfoSSN: 51,
  outstandingStripeInfoPhotoID: 52,
  gigPayIsLive: 53,
} as const;

export const COUNTRY_ABBREVIATIONS = {
  usa: 'USA',
  canada: 'CAN',
} as const;
