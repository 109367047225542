import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import CssBaseline from '@mui/material/CssBaseline';
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import { LinkProvider } from '@tkww/the-bash-frontend';
import { VendorContextProvider } from 'context/vendor';
import { LeadsFolderContextProvider } from 'context/leadsFolders';
import { GlobalContextProvider } from 'context/global';
import iosPWASplash from 'utils/iosPWASplash';
import { AccountContextProvider } from 'context/account';
import { PwaContextProvider } from 'context/pwa';
import { LeftNavContextProvider } from 'context/leftNav';
import { FeatureFlagsContextProvider } from 'featureFlags/index';
import { InstantFeedbackContextProvider } from 'context/instantFeedback';
import { SnackbarContextProvider } from 'context/snackbar';

import { gmConfig } from 'config/index';
import store from 'redux/store';
import theme from 'theme/index';
import DevelopmentLoginAppWrapper from 'views/Development/DevelopmentLogin/DevelopmentLoginAppWrapper';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

const container = document.getElementById('root');
const root = createRoot(container);
const queryClient = new QueryClient();

root.render(
  <BrowserRouter>
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <QueryClientProvider client={queryClient}>
          <DevelopmentLoginAppWrapper>
            <LeftNavContextProvider>
              <AccountContextProvider>
                <PwaContextProvider>
                  <FeatureFlagsContextProvider>
                    <VendorContextProvider>
                      <LeadsFolderContextProvider>
                        <Provider store={store}>
                          <InstantFeedbackContextProvider>
                            <SnackbarContextProvider>
                              <GlobalContextProvider>
                                <LinkProvider
                                  wwwDomain={gmConfig.domains.www}
                                  stack="node"
                                  subDomain="mcp"
                                  navMethod={() => {}}
                                >
                                  <App />
                                </LinkProvider>
                              </GlobalContextProvider>
                            </SnackbarContextProvider>
                          </InstantFeedbackContextProvider>
                        </Provider>
                      </LeadsFolderContextProvider>
                    </VendorContextProvider>
                  </FeatureFlagsContextProvider>
                </PwaContextProvider>
              </AccountContextProvider>
            </LeftNavContextProvider>
          </DevelopmentLoginAppWrapper>
        </QueryClientProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  </BrowserRouter>
);

iosPWASplash.generator(`https://${gmConfig.domains.cdn}/img/pwa-icon-512x512.png`, {
  crossOrigin: true,
  color: theme.palette.blue[900],
});

serviceWorkerRegistration.register({
  onUpdate: (registration) => {
    navigator.serviceWorker.addEventListener('message', (event) => {
      if (event.data?.type === 'RELOAD') {
        window.location.reload();
      }
    });

    registration.waiting.postMessage({ type: 'SKIP_WAITING' });
  },
});
