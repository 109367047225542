import React from 'react';
import { styled } from '@mui/material/styles';
import { BoxProps } from '@mui/material/Box';

import { Color } from '@mui/material';
import styles from './styles';

const Dot = styled('span')(styles.dot);

const DotWrapper = styled('span')(styles.dotWrapper);

type SpinnerProps = Omit<BoxProps<'span'>, 'ref' | 'color'> & { customColor?: Color };

const Spinner: React.FC<SpinnerProps> = (props) => (
  <DotWrapper {...props}>
    {[...Array(3)].map((_, index) => (
      <Dot key={index} role="presentation" />
    ))}
  </DotWrapper>
);

export default Spinner;
