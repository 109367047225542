import { Theme } from '@mui/material';

const styles = {
  menuItemRoot: (theme: Theme) => ({
    height: '44px',
    display: 'flex',
    padding: '0px 20px',
    textTransform: 'uppercase',
    '& > a': {
      width: '100%',
      color: theme.palette.grey[300],
      textDecoration: 'none',
      display: 'inline-flex',
      alignItems: 'center',
    },
    '& > a:hover': {
      textDecoration: 'none',
      color: theme.palette.blue[100],
    },
    '& > a > svg': {
      fill: theme.palette.grey[300],
    },
    '& > a:hover > svg': {
      fill: theme.palette.blue[100],
    },
    '& > div': {
      width: '100%',
      color: theme.palette.grey[300],
      textDecoration: 'none',
      display: 'inline-flex',
      alignItems: 'center',
    },
    '&.active': {
      '& > a': {
        ...theme.typography.body1,
        color: theme.palette.blue[300],
        backgroundColor: theme.palette.menu.background[100],
        borderRadius: '4px',
      },
      '& > a > svg': {
        fill: theme.palette.blue[300],
      },
      '&:hover': {
        '& > a': {
          color: theme.palette.blue[100],
        },
        '& > a > svg': {
          fill: theme.palette.blue[100],
        },
      },
      '& > div': {
        color: theme.palette.blue[300],
      },
    },
    '&.hasChildren': {
      '& > a': {
        background: 'none',
      },
    },
  }),
  itemLink: () => ({
    display: 'flex',
    paddingRight: '12px',
  }),
  itemLinkIcon: {
    display: 'flex',
    margin: '0px 12px',
    '& svg': {
      width: 20,
      height: 20,
    },
  },
  itemLinkTitle: {
    marginRight: 'auto',
  },
};

export default styles;
