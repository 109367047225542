import { Theme } from '@mui/material';

const styles = ({ theme }: { theme: Theme }) => ({
  '& .MuiDialog-paper': {
    width: '848px',
    maxWidth: '848px',

    [theme.breakpoints.down('sm')]: {
      width: '100%',
      height: '100%',
      margin: '0',
      maxWidth: '100%',
      maxHeight: 'none',
      borderRadius: '0px',
    },
  },

  '& .MuiDialogTitle-root': {
    boxShadow: `inset 0px -1px 0px ${theme.palette.grey[300]}`,
    padding: '20px 16px',
    paddingRight: '68px',

    '& .Dialog-closeButton': {
      top: '12px',
    },
  },
  '& .MuiDialogActions-root': {
    padding: '16px 24px',
    boxShadow: `inset 0px 1px 0px ${theme.palette.grey[300]}`,
  },
  '& .MuiDialogActions-spacing > :not(:first-of-type)': {
    marginLeft: '16px',
  },

  '& .MuiDialog-paperScrollPaper': {
    maxHeight: 'calc(100% - 152px)',
    [theme.breakpoints.down('sm')]: {
      maxHeight: 'none',
    },
  },

  '& .MuiDialog-paper.MuiDialog-paperFullScreen': {
    width: '100%',
    maxWidth: '100%',
    height: '100%',
    maxHeight: '100%',
  },
});

export default styles;
