export const NotificationScope = {
  PROFILE: 'profile',
  ACCOUNT: 'account',
} as const;

export type NotificationScopeKey = keyof typeof NotificationScope;
export type NotificationScopeValue = (typeof NotificationScope)[NotificationScopeKey];

export const NotificationStatus = {
  LOADING: 'loading',
  EXISTS: 'exists',
  DOES_NOT_EXIST: 'does_not_exist',
  CREATING: 'creating',
} as const;

export type NotificationStatusKey = keyof typeof NotificationStatus;
export type NotificationStatusValue = (typeof NotificationStatus)[NotificationStatusKey];
