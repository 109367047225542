import React from 'react';
import PropTypes from 'prop-types';
import slice from 'lodash/slice';
import isEmpty from 'lodash/isEmpty';
import sumBy from 'lodash/sumBy';

import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

import LinkButton from 'components/Form/LinkButton';

import { EVENTS, trackEvent } from 'helpers/tracking';
import ChildrenMenuItem from '../ChildrenMenuItem';
import styles from './styles';

const LeadsFolderMenu = React.memo((props) => {
  const { leadsFolders, onClickMenuItem } = props;
  const [isExpanded, setIsExpanded] = React.useState(false);

  const { fixedLeadsList, expandableLeadsList } = React.useMemo(() => {
    const leadsFoldersMenu = leadsFolders.map((folder) => ({
      title: folder.name,
      url: `/leads/folders/${folder.id}`,
      totalLeads: folder.totalLeads,
      unansweredLeads: folder.totalUnansweredLeads,
      dataTestId: `link-left-nav-leads-${folder.id}`,
    }));
    if (leadsFolders.length > 5) {
      return {
        fixedLeadsList: slice(leadsFoldersMenu, 0, 4),
        expandableLeadsList: slice(leadsFoldersMenu, 4, leadsFoldersMenu.length),
      };
    }
    return {
      fixedLeadsList: leadsFoldersMenu,
      expandableLeadsList: [],
    };
  }, [leadsFolders]);

  const renderMenuItems = (folders) =>
    folders.map((menu) => (
      <ChildrenMenuItem
        key={menu.title}
        title={menu.title}
        data-testid={menu.dataTestId}
        url={menu.url}
        onClickMenuItem={() => onClickMenuItem(menu.title, menu.url)}
        unanswered={menu.unansweredLeads}
      />
    ));

  const unanswered = React.useMemo(() => {
    const unansweredCount = sumBy(expandableLeadsList, (lead) => lead.unansweredLeads);

    return unansweredCount;
  }, [expandableLeadsList]);
  const renderActionButton = () => (
    <Box component="div" variant="body2" sx={styles.linkBox}>
      <LinkButton
        variant="body2"
        sx={styles.routeLink}
        onClick={() => {
          trackEvent(EVENTS.leadsFoldersToggleClicked, {
            expanded: !isExpanded,
          });
          setIsExpanded(!isExpanded);
        }}
        underline="always"
      >
        {isExpanded ? (
          <>
            <Box component="span">Show less</Box>
            <ExpandLessIcon sx={styles.expandIcon} />
          </>
        ) : (
          <>
            <Box component="span">Show more</Box>
            <ExpandMoreIcon sx={styles.expandIcon} />
          </>
        )}
      </LinkButton>
      {!isExpanded && unanswered > 0 && (
        <Box component="span" sx={styles.unansweredBox} data-testid="show-more-less-unanswered">
          <Box sx={styles.unanswered} component="span">
            {unanswered > 99 ? '99+' : unanswered}
          </Box>
        </Box>
      )}
    </Box>
  );

  return (
    <>
      {renderMenuItems(fixedLeadsList)}
      {!isEmpty(expandableLeadsList) && (
        <>
          <Collapse in={isExpanded} timeout="auto" unmountOnExit>
            {renderMenuItems(expandableLeadsList)}
          </Collapse>
          {renderActionButton()}
        </>
      )}
    </>
  );
});

LeadsFolderMenu.propTypes = {
  leadsFolders: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      totalLeads: PropTypes.number,
      totalUnansweredLeads: PropTypes.number,
    })
  ).isRequired,
  onClickMenuItem: PropTypes.func.isRequired,
};

export default LeadsFolderMenu;
