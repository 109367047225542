import React from 'react';

import { getMonth } from 'date-fns';
import BrandImage from 'components/Image/BrandImage';
import PrideBrandImage from 'components/Image/PrideBrandImage';

const BrandLogo = () => {
  const currentMonth = getMonth(new Date()) + 1;
  const showPrideBrandLogoMonth = 6;

  return currentMonth === showPrideBrandLogoMonth ? <PrideBrandImage /> : <BrandImage />;
};

export default BrandLogo;
