import { Theme } from '@mui/material';
import { keyframes } from '@mui/material/styles';

const loadGrow = keyframes({
  '0%': {
    transform: 'scale(1)',
  },
  ' 20%': {
    transform: 'scale(1.5)',
  },
  ' 40%': {
    transform: 'scale(1)',
  },
});

const styles = {
  dotWrapper: () =>
    ({
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: '18px',
      position: 'absolute',
    }) as const,
  dot: ({ theme }: { theme: Theme }) => ({
    display: 'block',
    backgroundColor: theme.palette.primary.main,
    height: '10px',
    width: '10px',
    borderRadius: '50%',
    animation: `${loadGrow} ease-in 1s infinite`,
    marginRight: '15px',
    '&:nth-of-type(2)': {
      animationDelay: '.33s',
    },
    '&:nth-of-type(3)': {
      animationDelay: '.66s',
      marginRight: '0',
    },
  }),
};

export default styles;
