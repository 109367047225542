import { Theme } from '@mui/material';

const styles = {
  errorContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  errorContext: (theme: Theme) => ({
    textAlign: 'center',
    margin: '0 auto',
    color: theme.palette.text.secondary,
    whiteSpace: 'break-spaces',
  }),
};

export default styles;
