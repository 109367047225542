import { Theme } from '@mui/material';

const PREFIX = 'component-Snackbar';

export const classes = {
  alert: `${PREFIX}-alert`,
};

export const styles = {
  styledRoot: ({ theme, type }: { theme: Theme; type: 'success' | 'error' }) => ({
    width: 'calc(100% - 40px)',
    top: '0px',
    right: '0px',
    bottom: 'auto',
    left: '0px',
    position: 'fixed',
    transform: 'translateX(0%)',
    [theme.breakpoints.up('sm')]: {
      width: '702px',
    },
    '& .MuiCollapse-root': {
      width: '100%',
    },
    [`& .${classes.alert}`]: {
      padding: '8px 16px',
      alignItems: 'flex-start',
      backgroundColor: theme.palette[type].light,
      border: `0.3px solid ${theme.palette[type].main}`,
      borderRadius: '2px',
      '& .MuiAlert-icon': {
        padding: '0px',
        marginTop: '2px',
        marginRight: '8px',
        '& .MuiSvgIcon-root': {
          color: theme.palette[type].main,
        },
      },
      '& .MuiAlert-message': {
        padding: '0px',
        wordBreak: 'break-word',
      },
      '& .MuiAlert-action': {
        padding: '2px 0px 0px 8px',
        marginRight: '0px',
        '& .MuiSvgIcon-root': {
          color: theme.palette.grey[900],
          '&:hover': {
            cursor: 'pointer',
          },
        },
      },
    },
    '@media print': {
      display: 'none',
    },
  }),
  messageText: {
    wordBreak: 'break-word',
  },
  actionButton: {
    display: 'inline',
    marginBottom: '3px',
  },
};
