import React from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import useMediaQuery from '@mui/material/useMediaQuery';

import { useLeftNavContextDispatch } from 'context/leftNav';
import { usePwaContextDispatch } from 'context/pwa';
import { useTheme } from '@mui/material/styles';
import styles from './styles';

const PwaInstallVisualGuidance = ({ open, onClose, children, ...otherProps }) => {
  const theme = useTheme();
  const isGtTablet = useMediaQuery((localTheme) => localTheme.breakpoints.up('md'));

  const { setOpenLeftNav } = useLeftNavContextDispatch();
  const { setGuidanceStep } = usePwaContextDispatch();

  return (
    <Tooltip
      data-testid="pwa-install-visual-guidance-tooltip"
      PopperProps={{
        disablePortal: false,
      }}
      open={open}
      placement="right-end"
      disableFocusListener
      disableHoverListener
      disableTouchListener
      {...otherProps}
      componentsProps={{
        ...otherProps.componentsProps,
        tooltip: {
          sx: styles.tooltip,
        },
      }}
      title={
        <Box sx={styles.title}>
          <Typography variant="subtitle1">
            🌟 The Bash Web App
            <IconButton color="inherit" size="veryLarge" sx={styles.closeButton} onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Typography>
          <Typography variant="body2" sx={styles.content}>
            Install The Bash’s web app now!
          </Typography>
          {!isGtTablet && (
            <Box>
              <Button
                onClick={() => {
                  setOpenLeftNav(true);
                  onClose();
                  setGuidanceStep('check now');
                }}
                size="small"
                sx={styles.checkNowButton}
                data-testid="button-check-now"
              >
                Check Now
              </Button>
            </Box>
          )}
          <svg
            width="84"
            height="46"
            viewBox="0 0 84 46"
            fill="none"
            xmlns="https://www.w3.org/2000/svg"
          >
            <g clipPath="url(#clip0_2411_23723)">
              <circle
                cx="84"
                cy="84"
                r="84"
                transform="matrix(1 0 0 -1 0 168)"
                fill={theme.palette.blue[100]}
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M45 4L52 11L45 18L38 11L45 4Z"
                fill={theme.palette.blue[300]}
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M5 26L8 29L5 32L2 29L5 26Z"
                fill={theme.palette.blue[300]}
              />
            </g>
            <defs>
              <clipPath id="clip0_2411_23723">
                <rect width="84" height="46" fill={theme.palette.common.white} />
              </clipPath>
            </defs>
          </svg>
        </Box>
      }
    >
      {children}
    </Tooltip>
  );
};

export default PwaInstallVisualGuidance;

PwaInstallVisualGuidance.defaultProps = {
  open: false,
  children: undefined,
  onClose: undefined,
};

PwaInstallVisualGuidance.propTypes = {
  open: PropTypes.bool,
  children: PropTypes.node,
  onClose: PropTypes.func,
};
