import React from 'react';
import { styled } from '@mui/material/styles';
import Snackbar, { SnackbarProps } from '@mui/material/Snackbar';
import { classes, styles } from './styles';

const StyledSnackbar = styled(Snackbar)(styles.styledRoot);

const ActionSnackbar = (props: SnackbarProps) => (
  <StyledSnackbar
    autoHideDuration={6000}
    {...props}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'left',
    }}
    ContentProps={{ classes: { root: classes.contentRoot, message: classes.contentMessage } }}
  />
);

export default ActionSnackbar;
