import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import React from 'react';
import { noop } from 'utils/function';
import styles from '../styles';

interface ButtonsSectionProps {
  remindLaterButtonText?: string;
  dismissButtonText?: string;
  onRemindLater?: () => void;
  onDismiss?: () => void;
  'data-testid'?: string;
}

export const ButtonsSection = ({
  remindLaterButtonText = 'Remind me later',
  dismissButtonText = 'Dismiss',
  onRemindLater = noop,
  onDismiss = noop,
  'data-testid': dataTestid,
}: React.PropsWithChildren<ButtonsSectionProps>) => (
  <Box sx={styles.dialogActions}>
    <Grid container spacing="16px" justifyContent="end">
      <Grid item xs={12} sm="auto" order={{ xs: 2, sm: 1 }}>
        <Button
          variant="text"
          onClick={onRemindLater}
          color="primary"
          size="medium"
          sx={[styles.actionsButton, { textTransform: 'none' }]}
          data-testid={`${dataTestid}-remind-later-button`}
        >
          {remindLaterButtonText}
        </Button>
      </Grid>
      <Grid item xs={12} sm="auto" order={{ xs: 1, sm: 2 }}>
        <Button
          onClick={onDismiss}
          color="primary"
          size="medium"
          sx={styles.actionsButton}
          data-testid={`${dataTestid}-dismiss-button`}
        >
          {dismissButtonText}
        </Button>
      </Grid>
    </Grid>
  </Box>
);
