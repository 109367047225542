import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';

import VendorContext from 'context/vendor';
import LeadsFolderContext from 'context/leadsFolders';
import PageLoader from 'components/Layout/PageLoader';
import { useFeatureFlagsContext } from 'featureFlags/index';

const VendorRoute = ({ path, component: RouteComponent, ...rest }) => {
  const {
    vendor,
    isLoading: isGetVendorLoading,
    isError: isGetVendorError,
    hasSelectedVendor,
  } = React.useContext(VendorContext);

  const {
    isLoading: isGetLeadsFoldersLoading,
    isError: isGetLeadsFoldersError,
    inboxFolderId,
  } = React.useContext(LeadsFolderContext);

  const { status: featureFlagsStatus } = useFeatureFlagsContext();

  return (
    <Route
      path={path}
      {...rest}
      render={() =>
        hasSelectedVendor ? (
          <PageLoader
            isLoading={
              isGetVendorLoading || isGetLeadsFoldersLoading || !featureFlagsStatus.isReady
            }
            isError={isGetVendorError || isGetLeadsFoldersError || featureFlagsStatus.isError}
          >
            <RouteComponent vendor={vendor} inboxFolderId={inboxFolderId} />
          </PageLoader>
        ) : (
          <Redirect
            to={`/membership-selection?ReturnUrl=${encodeURIComponent(
              `${window.location.pathname}${window.location.search}`
            )}`}
            push
          />
        )
      }
    />
  );
};

VendorRoute.propTypes = {
  path: PropTypes.string.isRequired,
  component: PropTypes.elementType.isRequired,
};

export default VendorRoute;
