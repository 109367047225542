import { ENTRY_POINT } from '../../views/PurchaseFeaturedProfiles/contexts/CouponCodeContext';
import { FeaturedProfileValidationErrorTypeValue } from '../../views/PurchaseFeaturedProfiles/Step1/ConfigureFeaturedProfile/FeedbackBannerContent/constants';

export const FEATURED_PROFILE_EVENTS = {
  FPPurchaseS1DurationDropdownClicked: 'FP Purchase S1 Duration Dropdown Clicked',
  FPPurchaseS1LocationDropdownClicked: 'FP Purchase S1 Location Dropdown Clicked',
  FPPurchaseS1CategoryDropdownClicked: 'FP Purchase S1 Category Dropdown Clicked',
  FPPurchaseS1MatchedOk: 'FP Purchase S1 Matched Ok',
  FPPurchaseS1PublicPageLinkClicked: 'FP Purchase S1 Public Page Link Clicked',
  FPPurchaseS1MatchedFail: 'FP Purchase S1 Matched Fail',
  FPPurchaseS1GetNotifiedLinkClicked: 'FP Purchase S1 Get Notified Link Clicked',
  FPPurchaseS1AlternateLocationLinkClicked: 'FP Purchase S1 Alternate Location Link Clicked',
  FPPurchaseS1AddFPButtonClicked: 'FP Purchase S1 Add FP Button Clicked',
  FPPurchaseS1RemoveFPButtonClicked: 'FP Purchase S1 Remove FP Button Clicked',
  FPPurchaseS1LearnMoreLinkClicked: 'FP Purchase S1 Learn More Link Clicked',
  FPPurchaseS1CouponAdded: 'FP Purchase S1 Coupon Added',
  FPPurchaseS1CouponRemoved: 'FP Purchase S1 Coupon Removed',
  FPPurchaseS1ContinueClickedOk: 'FP Purchase S1 Continue Clicked Ok',
  FPPurchaseS1ContinueClickedFail: 'FP Purchase S1 Continue Clicked Fail',
  FPPurchaseS2RemoveFPClicked: 'FP Purchase S2 Remove FP Clicked',
  FPPurchaseS2ContinueClicked: 'FP Purchase S2 Continue Clicked',
  FPPurchaseS2EditCardClicked: 'FP Purchase S2 Edit Card Clicked',
  FPPurchaseS2CancelClicked: 'FP Purchase S2 Cancel Clicked',
  FPPurchaseS2TermsAndConditionsLinkClicked: 'FP Purchase S2 Terms And Conditions Link Clicked',
  FPPurchaseS3PayNowClickedOk: 'FP Purchase S3 Pay Now Clicked Ok',
  FPPurchaseS3PayNowClickedFail: 'FP Purchase S3 Pay Now Clicked Fail',
  FPPurchaseS3CancelClicked: 'FP Purchase S3 Cancel Clicked',
} as const;

export type ErrorType = FeaturedProfileValidationErrorTypeValue | 'UNKNOWN';

interface EntryPointProps {
  entryPoint: (typeof ENTRY_POINT)[keyof typeof ENTRY_POINT];
  flowStep: number;
}

interface FeaturedProfilesCountProps {
  featuredProfilesCount: number;
  couponsAdded: boolean;
}

interface PaymentProps extends FeaturedProfilesCountProps {
  paymentOnFile: boolean;
}

export type FeaturedProfileEventPropsMap = {
  [FEATURED_PROFILE_EVENTS.FPPurchaseS1DurationDropdownClicked]: Record<string, never>;
  [FEATURED_PROFILE_EVENTS.FPPurchaseS1LocationDropdownClicked]: Record<string, never>;
  [FEATURED_PROFILE_EVENTS.FPPurchaseS1CategoryDropdownClicked]: Record<string, never>;
  [FEATURED_PROFILE_EVENTS.FPPurchaseS1MatchedOk]: Record<string, never>;
  [FEATURED_PROFILE_EVENTS.FPPurchaseS1PublicPageLinkClicked]: Record<string, never>;
  [FEATURED_PROFILE_EVENTS.FPPurchaseS1MatchedFail]: { errorType: ErrorType };
  [FEATURED_PROFILE_EVENTS.FPPurchaseS1GetNotifiedLinkClicked]: Record<string, never>;
  [FEATURED_PROFILE_EVENTS.FPPurchaseS1AlternateLocationLinkClicked]: Record<string, never>;
  [FEATURED_PROFILE_EVENTS.FPPurchaseS1AddFPButtonClicked]: { featuredProfilesCount: number };
  [FEATURED_PROFILE_EVENTS.FPPurchaseS1RemoveFPButtonClicked]: { featuredProfilesCount: number };
  [FEATURED_PROFILE_EVENTS.FPPurchaseS1LearnMoreLinkClicked]: Record<string, never>;
  [FEATURED_PROFILE_EVENTS.FPPurchaseS1CouponAdded]: EntryPointProps & { isValid: boolean };
  [FEATURED_PROFILE_EVENTS.FPPurchaseS1CouponRemoved]: EntryPointProps;
  [FEATURED_PROFILE_EVENTS.FPPurchaseS1ContinueClickedOk]: FeaturedProfilesCountProps;
  [FEATURED_PROFILE_EVENTS.FPPurchaseS1ContinueClickedFail]: FeaturedProfilesCountProps;
  [FEATURED_PROFILE_EVENTS.FPPurchaseS2RemoveFPClicked]: FeaturedProfilesCountProps;
  [FEATURED_PROFILE_EVENTS.FPPurchaseS2ContinueClicked]: FeaturedProfilesCountProps & {
    termsAndConditionsChecked: boolean;
  };
  [FEATURED_PROFILE_EVENTS.FPPurchaseS2EditCardClicked]: FeaturedProfilesCountProps;
  [FEATURED_PROFILE_EVENTS.FPPurchaseS2CancelClicked]: FeaturedProfilesCountProps;
  [FEATURED_PROFILE_EVENTS.FPPurchaseS2TermsAndConditionsLinkClicked]: FeaturedProfilesCountProps & {
    termsAndConditionsChecked: boolean;
  };
  [FEATURED_PROFILE_EVENTS.FPPurchaseS3PayNowClickedOk]: PaymentProps;
  [FEATURED_PROFILE_EVENTS.FPPurchaseS3PayNowClickedFail]: PaymentProps;
  [FEATURED_PROFILE_EVENTS.FPPurchaseS3CancelClicked]: PaymentProps;
};
