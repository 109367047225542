import React from 'react';
import VendorContext from 'context/vendor';

const PHOTO_BOOTH_SERVICE_TYPE_ID = 2418;

/**
 * Temporary/dirty hook to use in SEO's "New Feature" dialog and "new" chip.
 * Both will be removed soon, so it's not worth it to do a more complex check.
 */
export const useIsPhotoBoothVendor = () => {
  const { vendor } = React.useContext(VendorContext);

  return vendor.serviceTypes.some((st) => st.serviceTypeId === PHOTO_BOOTH_SERVICE_TYPE_ID);
};
