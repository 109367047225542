import { Theme } from '@mui/material';

const styles = {
  tooltip: {
    backgroundColor: 'transparent',
    maxWidth: '372px',
    width: '372px',
    padding: '4px 12px',
  },

  title: (theme: Theme) => ({
    position: 'relative',
    background: theme.palette.blue[50],
    border: `solid 1px ${theme.palette.blue[300]}`,
    borderRadius: '4px',
    padding: '12px 12px 16px 12px',
    boxShadow: `0px 0px 10px 0px ${theme.palette.paper.shadow}`,

    '& svg': {
      position: 'absolute',
      right: 0,
      bottom: '0px',
    },
  }),

  content: { marginTop: '4px', paddingLeft: '24px' },

  checkNowButton: { margin: '8px 0px 0px 20px', textTransform: 'unset' },

  closeButton: { position: 'absolute', top: '14px', right: '12px' },
};

export default styles;
