import React, { PropsWithChildren } from 'react';

// @ts-expect-error we should type this with the expected type
export const ParentContainerContext = React.createContext();

type ParentContainerContextProviderProps = PropsWithChildren<{
  value: unknown;
}>;

export const ParentContainerContextProvider = ({
  value = {},
  children,
}: ParentContainerContextProviderProps) => (
  <ParentContainerContext.Provider value={value}>{children}</ParentContainerContext.Provider>
);

export default ParentContainerContext;
