import React from 'react';
import MuiTooltip, { TooltipProps } from '@mui/material/Tooltip';
import { SxProps } from '@mui/material';
import styles from './styles';

export interface ArrowTooltipProps extends TooltipProps {
  componentsProps?: {
    tooltip?: {
      sx?: SxProps;
    };
    arrow?: {
      sx?: SxProps;
    };
  };
}

const ArrowTooltip: React.FC<ArrowTooltipProps> = ({ componentsProps = {}, ...props }) => {
  const [open, setOpen] = React.useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  return (
    <MuiTooltip
      leaveTouchDelay={10000}
      open={open}
      {...props}
      componentsProps={{
        ...componentsProps,
        arrow: {
          ...componentsProps.arrow,
          sx: [
            styles.arrow,
            ...(Array.isArray(componentsProps.arrow?.sx)
              ? (componentsProps.arrow?.sx ?? [])
              : [componentsProps.arrow?.sx ?? []]),
          ],
        },
        tooltip: {
          ...componentsProps.tooltip,
          sx: [
            styles.tooltip,
            ...(Array.isArray(componentsProps.tooltip?.sx)
              ? (componentsProps.tooltip?.sx ?? [])
              : [componentsProps.tooltip?.sx ?? []]),
          ],
        },
      }}
      onClose={handleTooltipClose}
      onOpen={handleTooltipOpen}
      onClick={handleTooltipOpen}
    />
  );
};

export default ArrowTooltip;
