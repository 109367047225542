import { createTheme } from '@mui/material/styles';

import palette from 'theme/palette';
import breakpoints from 'theme/breakpoints';
import typography from 'theme/typography';
import components from 'theme/components';
import zIndex from './zIndex';

const theme = createTheme({
  palette,
  typography,
  spacing: 20,
  breakpoints,
  zIndex,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  components: components as any,
});

export default theme;
