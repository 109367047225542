import { vendorAPIRequest } from 'api/index';

const subscribe = (subscription: PushSubscription) => {
  const key = subscription.getKey('p256dh');
  const token = subscription.getKey('auth');

  const data = {
    endpoint: subscription.endpoint,
    keys: {
      p256dh: key ? btoa(String.fromCharCode.apply(null, Array.from(new Uint8Array(key)))) : null,
      auth: token ? btoa(String.fromCharCode.apply(null, Array.from(new Uint8Array(token)))) : null,
    },
  };

  vendorAPIRequest.put<void>(`/subscriptions`, data);
};
export default {
  subscribe,
};
