import { createContext, useState, PropsWithChildren } from 'react';
import { noop } from 'utils/function';

export type InstantFeedbackOptions<T = unknown> = {
  'data-testid'?: string;
  data?: T;
  open: boolean;
  message: string;
  actionText?: string;
  actionFunc?: (data: T) => void;
  notWithStickyBottomBar?: boolean;
  hideOnLeavePage?: boolean;
  key?: number;
};

type InstantFeedbackContextState = {
  instantFeedback: InstantFeedbackOptions;
  setInstantFeedback: (value: InstantFeedbackOptions) => void;
};

const initialInstantFeedback = {
  instantFeedback: {
    open: false,
    message: '',
  },
  setInstantFeedback: noop,
};

export const InstantFeedbackContext =
  createContext<InstantFeedbackContextState>(initialInstantFeedback);

export const InstantFeedbackContextProvider = ({ children }: PropsWithChildren<unknown>) => {
  const [instantFeedback, setFeedback] = useState<InstantFeedbackOptions>(
    initialInstantFeedback.instantFeedback
  );

  const setInstantFeedback = (value: InstantFeedbackOptions) => {
    setFeedback(() => ({
      ...value,
      // TODO: what's the point of this key?
      key: value.key || new Date().getTime() + Math.random(),
    }));
  };

  return (
    <InstantFeedbackContext.Provider
      value={{
        instantFeedback,
        setInstantFeedback,
      }}
    >
      {children}
    </InstantFeedbackContext.Provider>
  );
};
