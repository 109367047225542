import upperFirst from 'lodash/upperFirst';
import { useFeatureFlagsContext } from 'featureFlags/index';
import { FEATURE_FLAGS } from 'featureFlags/constants';
import { FeatureFlagKey } from 'featureFlags/types';

export type IsEnabledKey = `is${Capitalize<FeatureFlagKey>}Enabled`;

const convertFeatureFlagKeyToIsEnabledKey = <K extends FeatureFlagKey>(key: K): IsEnabledKey => {
  const capitalizedKey = upperFirst(key) as Capitalize<K>;
  return `is${capitalizedKey}Enabled`;
};

const isEnabledFeatureFlagEntries = Object.entries(FEATURE_FLAGS).map(
  ([key, value]) => [convertFeatureFlagKeyToIsEnabledKey(key as FeatureFlagKey), value] as const
);

/**
 * This hook automatically maps the known feature flags to an object indicating whether each of them is enabled or not.
 *
 * @example
 * // Given the following feature flags:
 * const FEATURE_FLAGS = {
 *   newLeads: 'mcp-new-leads',
 *   quoteV3: 'mcp-quote-v3',
 * } as const;
 *
 * // Returns an object with this shape:
 * {
 *   isNewLeadsEnabled: boolean;
 *   isQuoteV3Enabled: boolean;
 * }
 */
export const useFeatureFlags = () => {
  const { enabledFeatureFlags } = useFeatureFlagsContext();

  const mappedEntries = isEnabledFeatureFlagEntries.map(
    ([key, value]) => [key, enabledFeatureFlags.includes(value)] as const
  );

  return Object.fromEntries(mappedEntries) as Record<IsEnabledKey, boolean>;
};
