import { HEADER_HEIGHT } from 'components/Header/styles';
import palette from 'theme/palette';
import typography from 'theme/typography';

export default {
  MuiButton: {
    defaultProps: {
      variant: 'contained',
      disableElevation: true,
      color: 'primary',
    },
    styleOverrides: {
      root: {
        padding: '10px 18px',
        ...typography.subtitle1,
        height: '48px',
        borderRadius: '3px',
        '&.Mui-disabled': {
          color: palette.secondary.light,
        },
        minWidth: '120px',
      },
      contained: {
        '&.Mui-disabled': {
          color: palette.common.white,
          backgroundColor: palette.primary.light,
        },
        '&:hover.Mui-disabled': {
          backgroundColor: `${palette.primary.light} !important`,
        },
      },
      containedSizeLarge: {
        padding: '12px 32px',
        height: '52px',
        minWidth: '120px',
        ...typography.subtitle1,
      },
      containedSizeSmall: {
        padding: '4px 8px',
        height: '32px',
        ...typography.button,
        minWidth: '100px',
      },
      outlinedSecondary: {
        border: `1px solid ${palette.primary.main}`,
        '&.Mui-disabled': {
          border: `1.5px solid ${palette.secondary.light}`,
        },
      },
      outlinedPrimary: {
        border: `1px solid ${palette.primary.main}`,
      },
      outlinedSizeLarge: {
        padding: '12px 32px',
        minWidth: '120px',
        height: '52px',
        ...typography.subtitle1,
      },
      outlinedSizeSmall: {
        padding: '4px 8px',
        height: '32px',
        minWidth: '100px',
        ...typography.button,
      },
      textSizeSmall: {
        padding: '4px 8px',
        height: '32px',
        minWidth: '100px',
        ...typography.button,
      },
      textSizeLarge: {
        padding: '12px 32px',
        height: '32px',
        ...typography.button,
      },
    },
  },
  MuiIconButton: {
    styleOverrides: {
      root: {
        colorPrimary: {
          '&:hover': {
            backgroundColor: 'transparent',
          },
        },
      },
      sizeSmall: {
        padding: '8px',
      },
      sizeMedium: {
        padding: '10px',
      },
      sizeLarge: {
        padding: '11px',
      },
      sizeVeryLarge: {
        padding: '12px',
      },
    },
  },
  MuiTextField: {
    styleOverrides: {
      root: {
        width: ' 100%',
      },
    },
  },
  MuiRating: {
    defaultProps: {
      precision: 0.5,
      size: 'small',
      readOnly: true,
    },
    styleOverrides: {
      root: {
        color: palette.decorative.star,
      },
    },
  },
  MuiTab: {
    defaultProps: {
      disableRipple: true,
      disableFocusRipple: true,
    },
  },
  MuiTypography: {
    defaultProps: {
      color: 'textPrimary',
    },
    styleOverrides: {
      root: {
        whiteSpace: 'pre-line',
      },
    },
  },
  MuiFormHelperText: {
    defaultProps: {
      component: 'div',
    },
    styleOverrides: {
      root: {
        ...typography.caption,
        marginTop: '8px',
        display: 'flex',
        alignItems: 'flex-start',
        color: palette.text.secondary,
        '&.Mui-error': {
          color: palette.error.main,
        },
        '& svg': {
          fontSize: '17px',
          marginRight: '4px',
          color: palette.text.hint,
        },
      },
      marginDense: {
        marginTop: '8px',
        marginBottom: '0px',
      },
      contained: {
        marginLeft: '0px',
        marginRight: '0px',
      },
    },
  },
  MuiLink: {
    defaultProps: {
      underline: 'hover',
    },
    styleOverrides: {
      root: ({ color }) => ({
        textUnderlinePosition: 'under',
        textDecorationColor: 'initial',
        '&:hover': {
          color: color === 'inherit' ? 'inherit' : palette.primary.dark,
          cursor: 'pointer',
        },
      }),
      button: {
        display: 'flex',
        '&:disabled': {
          color: palette.primary.light,
          textDecoration: 'none',
          cursor: 'unset',
        },
      },
    },
  },
  MuiCssBaseline: {
    styleOverrides: {
      '.no-scroll': {
        overflowY: 'hidden',
      },
      '::-ms-clear': {
        display: 'none',
      },
      '.form-element': {
        scrollMarginTop: `${HEADER_HEIGHT}px`,
      },
      // For dialogs we don't have the header to pad for
      '.MuiDialogContent-root .form-element': {
        scrollMarginTop: 0,
      },
    },
  },
  MuiInputBase: {
    styleOverrides: {
      root: {
        '&.Mui-disabled': {
          color: palette.text.hint,
        },
        '& .Mui-disabled': {
          backgroundColor: palette.background.default,
          color: palette.text.hint,
        },
        height: 'auto',
      },
      input: {
        height: 'auto',
      },
    },
  },
  MuiOutlinedInput: {
    styleOverrides: {
      input: ({ ownerState }) => ({
        padding: '10px 12px',
        ...(ownerState?.endAdornment && {
          paddingRight: '0px',
        }),
        border: 0,
      }),
      sizeSmall: {
        ...typography.body2,
      },
      inputSizeSmall: {
        padding: '4px 12px',
      },
      notchedOutline: {
        borderColor: palette.grey[500],
      },
      root: {
        color: palette.text.primary,
        ...typography.subtitle2,
        borderRadius: '2px',
        '&:hover .MuiOutlinedInput-notchedOutline': {
          borderWidth: '1px',
          borderColor: palette.grey[600],
        },
        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
          borderWidth: '1px',
          borderColor: palette.primary.main,
        },
        '&.Mui-error .MuiOutlinedInput-notchedOutline': {
          borderColor: palette.error.main,
          borderWidth: '1px',
        },
      },
      multiline: {
        padding: '0px',

        '&.MuiInputBase-adornedEnd': {
          padding: '0px 12px 0px 0px',
        },
      },
      adornedEnd: {
        paddingRight: '12px',
      },
      adornedStart: {
        paddingLeft: '12px',
      },
      inputAdornedStart: {
        paddingLeft: '0px',
      },
    },
  },
  MuiInputAdornment: {
    styleOverrides: {
      positionStart: {
        color: palette.text.hint,
      },
    },
  },
  MuiFormControlLabel: {
    styleOverrides: {
      label: {
        '&.Mui-disabled': {
          color: palette.text.hint,
        },
      },
    },
  },
  MuiCheckbox: {
    styleOverrides: {
      root: {
        padding: '10px',
      },
    },
  },
  MuiTooltip: {
    styleOverrides: {
      tooltip: {
        ...typography.caption,
        backgroundColor: palette.action.background,
      },
    },
  },
  MuiSwitch: {
    styleOverrides: {
      root: {
        padding: '12px 13px',
      },
      switchBase: {
        color: palette.common.white,
      },
      track: {
        backgroundColor: palette.grey[500],
        opacity: 'unset',
      },
      colorPrimary: {
        '&.Mui-checked + .MuiSwitch-track': {
          backgroundColor: palette.primary.light,
          opacity: 'unset',
        },
        '&.Mui-disabled': {
          color: palette.primary.light,
        },
        '&.Mui-disabled + .MuiSwitch-track': {
          backgroundColor: palette.primary.light,
          opacity: 0.38,
        },
        '&.Mui-disabled:not(.Mui-checked)': {
          color: palette.grey[100],
        },
        '&.Mui-disabled:not(.Mui-checked) + .MuiSwitch-track': {
          backgroundColor: palette.text.disabled,
          opacity: 'unset',
        },
      },
    },
  },
  MuiPagination: {
    defaultProps: {
      size: 'large',
    },
    styleOverrides: {
      root: {
        padding: '12px 0px',
      },
    },
  },
  MuiPaginationItem: {
    styleOverrides: {
      root: {
        margin: '0 4px',
        borderRadius: '2px',
      },
      sizeLarge: {
        height: '48px',
        minWidth: '48px',
      },
      sizeMedium: {
        height: '44px',
        minWidth: '44px',
      },
      ellipsis: {
        height: 'auto',
      },
      previousNext: {
        color: palette.grey[600],
        '&.Mui-disabled': {
          opacity: 1,
          color: palette.grey[300],
        },
      },
    },
  },
  MuiDialogTitle: {
    defaultProps: {
      component: 'div',
      variant: 'h4',
    },
  },
  MuiMenu: {
    styleOverrides: {
      root: {
        '& .MuiMenu-paper': {
          borderRadius: '0px',
        },
        '& .MuiMenu-list': {
          paddingTop: '0px',
          paddingBottom: '0px',
          border: `1px solid ${palette.divider}`,
          '& .MuiMenuItem-root+.MuiMenuItem-root': {
            borderTop: `1px solid ${palette.grey[300]}`,
          },
        },
      },
    },
  },
  MuiPaper: {
    styleOverrides: {
      elevation8: {
        boxShadow: `0px 0px 10px ${palette.paper.shadow}`,
      },
    },
  },
  MuiMenuItem: {
    styleOverrides: {
      root: {
        '&.Mui-disabled': {
          opacity: 0.5,
        },
        '&.Mui-selected': {
          backgroundColor: palette.grey[300],
        },
        '&.Mui-selected:hover': {
          backgroundColor: palette.grey[300],
        },
        '&:hover': {
          backgroundColor: palette.grey[100],
        },
        '&.Mui-focusVisible': {
          backgroundColor: palette.grey[100],
        },
        '&.Mui-selected.Mui-focusVisible': {
          backgroundColor: palette.grey[300],
        },
      },
    },
  },

  MuiDatePickerToolbar: {
    styleOverrides: {
      root: () => ({
        padding: '16px 24px',

        '&> span': {
          lineHeight: '32px',
        },
      }),
      title: {
        ...typography.body2,
        fontSize: '34px',
        lineHeight: '40px',
      },
    },
  },
  MuiPickersCalendarHeader: {
    styleOverrides: {
      root: {
        display: 'flex',
        alignItems: 'center',
        marginTop: '16px',
        marginBottom: '8px',
        paddingLeft: '24px',
        paddingRight: '12px',
        maxHeight: '30px',
        minHeight: '30px',
      },
      label: {
        ...typography.subtitle1,
      },
    },
  },

  MuiPickersArrowSwitcher: {
    styleOverrides: {
      button: {
        padding: '8px',
      },
      spacer: {
        width: '24px',
      },
    },
  },

  MuiPickersYear: {
    styleOverrides: {
      root: {},
      yearButton: {
        ...typography.body2,
      },
    },
  },

  MuiPickersMonth: {
    styleOverrides: {
      monthButton: {
        ...typography.body2,
      },
    },
  },
  MuiPickersDay: {
    styleOverrides: {
      today: {
        '&:not(.Mui-selected)': {
          border: `1px solid ${palette.grey[500]}`,
        },
      },
    },
  },
  MuiSvgIcon: {
    styleOverrides: {
      fontSizeSmall: {
        fontSize: '18px',
      },
      fontSizeMedium: {
        fontSize: '24px',
      },
      colorTextSecondary: {
        color: palette.text.secondary,
      },
    },
  },
  MuiCollapse: {
    styleOverrides: {
      wrapperInner: ({ ownerState: { state, 'line-clamp': lineClamp } }) => {
        if (state !== 'exited' || !lineClamp) {
          return {
            wordBreak: 'break-word',
          };
        }

        return {
          display: '-webkit-box',
          WebkitBoxOrient: 'vertical',
          textOverflow: 'ellipsis',
          wordBreak: 'break-word',
          WebkitLineClamp: lineClamp,
        };
      },
    },
  },
  MuiAppBar: {
    '& .MuiPaper-elevation4': {
      boxShadow: `0px 0px 10px 0px ${palette.paper.shadow}`,
    },
  },
};
