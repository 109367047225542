import { gmConfig } from 'config/index';
import capitalize from 'lodash/capitalize';
import replace from 'lodash/replace';
import { Honeybadger } from '@honeybadger-io/react';
import { Types } from '@honeybadger-io/core';
import { ErrorResponse } from 'hooks/request/types';
import { AxiosError } from 'axios';

const shouldIgnoreError = (notice: Types.Notice) =>
  notice.message.includes('Failed to load Survicate') ||
  notice.message.includes('timeout exceeded') ||
  notice.message.includes('Network Error') ||
  notice.message === 'Request aborted';

Honeybadger.configure(gmConfig.honeyBadger);

Honeybadger.beforeNotify((notice) => notice && !shouldIgnoreError(notice));

const stripeErrorTypes = ['connection_error', 'api_error'];
export const handleResponseError = (e: AxiosError | StripeError) => {
  const error: ErrorResponse = {
    name: e.name,
    message: e.message,
  };

  if ((e as AxiosError).response) {
    const axiosError = e as AxiosError;
    error.statusCode = axiosError.response?.status;
    error.message = axiosError.response?.data.message;
    error.data = axiosError.response?.data;
  }

  return error;
};

export const honeyBadgerNotify = (
  error: Error,
  additionalData?: Record<string, unknown> | string
) => Honeybadger.notify(error, additionalData);

export const handleHoneyBadgerNotify = (
  error: AxiosError,
  additionalData?: Record<string, unknown> | string
) => {
  if (error.response?.status && error.response.status >= 500) {
    honeyBadgerNotify(handleResponseError(error), additionalData);
  }
  throw error;
};

export interface StripeError {
  message: string;
  type: string;
  param?: string;
  code?: string;
  name: string;
  request_log_url?: string;
}

export const handleHoneyBadgerNotifyForStripe = (error: StripeError) => {
  if (stripeErrorTypes.includes(error.type)) {
    honeyBadgerNotify(
      handleResponseError(error),
      `${capitalize(replace(error.type, /_/g, ' '))} when creating Stripe token`
    );
  }
};
