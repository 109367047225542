import React from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CloseButton from 'components/Dialog/CloseButton';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';

import BaseStyleBigDialog from 'components/Dialog/BaseStyleBigDialog';
import { gmConfig } from 'config/index';

const StyledVideo = styled('video')({});

export const PwaInstallTutorialDialog = (props) => {
  const { onClose, ...otherProps } = props;
  return (
    <BaseStyleBigDialog {...otherProps}>
      <DialogTitle>
        How to install The Bash’s app on your mobile device
        <CloseButton onClick={onClose} />
      </DialogTitle>
      <DialogContent>
        <Box>
          <StyledVideo
            sx={{ marginTop: '32px', width: '100%', height: '392px' }}
            autoPlay
            loop
            muted
            playsInline
          >
            <source
              src={`https://${gmConfig.domains.cdn}/video/pwa-tutorial.mp4`}
              type="video/mp4"
            />
          </StyledVideo>
          <Box sx={{ marginTop: '24px', '& ol': { paddingInlineStart: '28px' } }}>
            <ol>
              <Typography variant="subtitle2" component="li">
                {`In the browser, tap the `}
                <Typography variant="subtitle1" sx={{ display: 'inline-block' }}>
                  Share
                </Typography>
                {` icon, available at the bottom or top of the browser.`}
              </Typography>
              <Typography variant="subtitle2" component="li">
                {`Tap `}
                <Typography variant="subtitle1" sx={{ display: 'inline-block' }}>
                  Add to Home Screen.
                </Typography>
              </Typography>
              <Typography variant="subtitle2" component="li">
                {`Tap `}
                <Typography variant="subtitle1" sx={{ display: 'inline-block' }}>
                  Add
                </Typography>
                {`. After installed, you can find the App on the home screen of your phone.`}
              </Typography>
            </ol>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button sx={{ width: '100%' }} onClick={onClose}>
          Got It
        </Button>
      </DialogActions>
    </BaseStyleBigDialog>
  );
};

export default PwaInstallTutorialDialog;

PwaInstallTutorialDialog.defaultProps = {
  onClose: undefined,
};

PwaInstallTutorialDialog.propTypes = {
  onClose: PropTypes.func,
};
