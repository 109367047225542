import React from 'react';
import { noop } from 'utils/function';
import { Link as RouteLink, useRouteMatch } from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Box, Link, Theme } from '@mui/material';

import ArrowTooltip from 'components/ArrowTooltip';
import useIsOverflow from 'hooks/useIsOverflow';

import styles from './styles';

type ChildrenMenuItemProps = {
  title?: string;
  icon?: React.ReactNode;
  unanswered?: number;
  onClickMenuItem?: () => void;
  className?: string;
  url: string;
  'data-testid'?: string;
};

const ChildrenMenuItem = React.memo((props: ChildrenMenuItemProps) => {
  const {
    title,
    url = '',
    icon,
    unanswered,
    onClickMenuItem = noop,
    className,
    'data-testid': dataTestId,
    ...otherProps
  } = props;
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const active = useRouteMatch(url);
  const { ref, isOverflow } = useIsOverflow();

  return (
    <Box
      component="div"
      variant="body2"
      sx={styles.childrenMenuItemRoot}
      // @ts-expect-error Wants a string not an array
      className={[active && 'active', className]}
      {...otherProps}
    >
      <Link
        sx={styles.itemLink}
        onClick={onClickMenuItem}
        to={url}
        data-testid={dataTestId}
        component={RouteLink}
      >
        {icon && (
          <Box component="span" sx={styles.linkIcon}>
            {icon}
          </Box>
        )}
        {
          <ArrowTooltip
            disableInteractive
            title={title}
            arrow
            placement="top"
            {...(!isMobile && isOverflow
              ? {}
              : {
                  open: false,
                })}
          >
            <Box component="span" sx={styles.itemLinkTitle} ref={ref}>
              {title}
            </Box>
          </ArrowTooltip>
        }
        {unanswered !== undefined && unanswered > 0 && (
          <Box component="span" sx={styles.unansweredBox}>
            <Box sx={styles.unanswered} component="span">
              {unanswered > 99 ? '99+' : unanswered}
            </Box>
          </Box>
        )}
      </Link>
    </Box>
  );
});

export default ChildrenMenuItem;

ChildrenMenuItem.displayName = 'ChildrenMenuItem';
