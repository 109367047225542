import { ResponseTypeValue } from 'constants/leads';

export const SAVED_RESPONSES_EVENTS = {
  savedResponsesListCreateButtonClicked: 'Saved Resp List Create Button Clicked',
  savedResponsesListRowEdit: 'Saved Resp List Row Edited',
  savedResponsesListRowRemoveSuccessfully: 'Saved Resp List Row Removed Ok',
  savedResponsesListRowDrag: 'Saved Resp List Row Dragged',
  savedResponsesFormQuoteSaveButtonClickSuccessfully:
    'Saved Resp Form Quote Save Button Clicked Ok',
  savedResponsesFormQuoteSaveButtonClickUnsuccessfully:
    'Saved Resp Form Quote Save Button Clicked Fail',
  savedResponsesFormQuoteCancelButtonClick: 'Saved Resp Form Quote Cancel Button Clicked',
  savedResponsesFormQuoteEditRateButtonClick: 'Saved Resp Form Quote Edit Rate Button Clicked',
  savedResponsesFormQuoteSaveRateButtonClick: 'Saved Resp Form Quote Save Rate Button Clicked',
  savedResponsesFormQuoteCloseRateDialogClick: 'Saved Resp Form Quote Close Rate Dialog Clicked',
  savedResponsesFormDeclineSaveButtonClickSuccessfully:
    'Saved Resp Form Decline Save Button Clicked Ok',
  savedResponsesFormDeclineCancelButtonClick: 'Saved Resp Form Decline Cancel Button Clicked',
} as const;

export interface SavedResponsesListEventProperties {
  responseType: ResponseTypeValue;
  quoteListLength: number;
  declineListLength: number;
}

export interface SavedResponsesFormEventProperties {
  isEditing: boolean;
}

export type SavedResponsesEventPropsMap = {
  [SAVED_RESPONSES_EVENTS.savedResponsesListCreateButtonClicked]: SavedResponsesListEventProperties & {
    tabValue: ResponseTypeValue;
  };
  [SAVED_RESPONSES_EVENTS.savedResponsesListRowEdit]: SavedResponsesListEventProperties;
  [SAVED_RESPONSES_EVENTS.savedResponsesListRowRemoveSuccessfully]: SavedResponsesListEventProperties;
  [SAVED_RESPONSES_EVENTS.savedResponsesListRowDrag]: SavedResponsesListEventProperties & {
    fromIndex: number;
    toIndex: number;
  };
  [SAVED_RESPONSES_EVENTS.savedResponsesFormQuoteSaveButtonClickSuccessfully]: SavedResponsesFormEventProperties;
  [SAVED_RESPONSES_EVENTS.savedResponsesFormQuoteSaveButtonClickUnsuccessfully]: SavedResponsesFormEventProperties & {
    validationErrors: Record<string, string>;
  };
  [SAVED_RESPONSES_EVENTS.savedResponsesFormQuoteCancelButtonClick]: SavedResponsesFormEventProperties;
  [SAVED_RESPONSES_EVENTS.savedResponsesFormQuoteEditRateButtonClick]: SavedResponsesFormEventProperties;
  [SAVED_RESPONSES_EVENTS.savedResponsesFormQuoteSaveRateButtonClick]: SavedResponsesFormEventProperties;
  [SAVED_RESPONSES_EVENTS.savedResponsesFormQuoteCloseRateDialogClick]: SavedResponsesFormEventProperties;
  [SAVED_RESPONSES_EVENTS.savedResponsesFormDeclineSaveButtonClickSuccessfully]: SavedResponsesFormEventProperties;
  [SAVED_RESPONSES_EVENTS.savedResponsesFormDeclineCancelButtonClick]: SavedResponsesFormEventProperties;
};
