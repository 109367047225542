import React from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';

import { useMediaQuery, Theme } from '@mui/material';

export interface LeftNavContextState {
  shouldShowLeftNav: boolean;
  openLeftNav: boolean;
}

export const StateContext = React.createContext<LeftNavContextState | Record<string, never>>({});
export const DispatchContext = React.createContext<{
  setOpenLeftNav: React.Dispatch<React.SetStateAction<boolean>>;
}>({ setOpenLeftNav: () => {} });
export const useLeftNavContextState = () => React.useContext(StateContext);
export const useLeftNavContextDispatch = () => React.useContext(DispatchContext);

export const LeftNavContextProvider = ({ children }: React.PropsWithChildren) => {
  const isGtTablet = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));
  const { pathname } = useLocation();
  const [openLeftNav, setOpenLeftNav] = React.useState(isGtTablet);

  const shouldShowLeftNav = React.useMemo(
    () => !pathname.startsWith('/membership-selection') || !isGtTablet,
    [pathname, isGtTablet]
  );

  React.useEffect(() => {
    if (!isGtTablet && openLeftNav && shouldShowLeftNav) {
      document.body.classList.add('no-scroll');
    } else {
      document.body.classList.remove('no-scroll');
    }
  }, [isGtTablet, openLeftNav, shouldShowLeftNav]);

  React.useEffect(() => {
    setOpenLeftNav(isGtTablet);
  }, [isGtTablet]);

  return (
    <StateContext.Provider
      value={{
        shouldShowLeftNav,
        openLeftNav: shouldShowLeftNav && (isGtTablet || openLeftNav),
      }}
    >
      <DispatchContext.Provider
        value={{
          setOpenLeftNav,
        }}
      >
        {children}
      </DispatchContext.Provider>
    </StateContext.Provider>
  );
};

LeftNavContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default LeftNavContextProvider;
