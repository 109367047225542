export default {
  h1: {
    fontFamily: 'ProximaNova-Bold',
    fontSize: '32px',
    lineHeight: '40px',
    fontWeight: 'bold',
    letterSpacing: 'normal',
  },
  h2: {
    fontFamily: 'ProximaNova-Bold',
    fontSize: '28px',
    lineHeight: '36px',
    fontWeight: 'bold',
    letterSpacing: 'normal',
  },
  h3: {
    fontFamily: 'ProximaNova-Bold',
    fontSize: '24px',
    lineHeight: '32px',
    fontWeight: 'bold',
    letterSpacing: 'normal',
  },
  h4: {
    fontFamily: 'ProximaNova-Bold',
    fontSize: '20px',
    lineHeight: '28px',
    fontWeight: 'bold',
    letterSpacing: 'normal',
  },
  h5: {
    fontFamily: 'ProximaNova-Bold',
    fontSize: '18px',
    lineHeight: '28px',
    fontWeight: 'bold',
    letterSpacing: 'normal',
  },

  /** Body Bold */
  subtitle1: {
    fontFamily: 'ProximaNova-Semibold',
    fontSize: '18px',
    lineHeight: '28px',
    fontWeight: 600,
    letterSpacing: 'normal',
  },

  /** Body */
  subtitle2: {
    fontFamily: 'ProximaNova-Regular',
    fontSize: '18px',
    lineHeight: '28px',
    fontWeight: 'normal',
    letterSpacing: 'normal',
  },

  /** Subtext Bold */
  body1: {
    fontFamily: 'ProximaNova-Semibold',
    fontSize: '16px',
    lineHeight: '24px',
    fontWeight: 600,
    letterSpacing: 'normal',
  },

  /** Subtext */
  body2: {
    fontFamily: 'ProximaNova-Regular',
    fontSize: '16px',
    lineHeight: '24px',
    fontWeight: 'normal',
    letterSpacing: 'normal',
  },

  button: {
    fontFamily: 'ProximaNova-Semibold',
    fontSize: '16px',
    lineHeight: '24px',
    fontWeight: 600,
    letterSpacing: 'normal',
  },

  caption: {
    fontFamily: 'ProximaNova-Regular',
    fontSize: '14px',
    lineHeight: '20px',
    fontWeight: 'normal',
    letterSpacing: 'normal',
  },

  overline: {
    fontFamily: 'ProximaNova-Semibold',
    fontSize: '14px',
    lineHeight: '20px',
    textTransform: 'uppercase',
    letterSpacing: '2px',
    fontWeight: 600,
  },
} as const;
