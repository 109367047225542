import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { Link, Typography, Box } from '@mui/material';

import { EVENTS, trackEvent } from 'helpers/tracking';

import { gmConfig } from 'config/index';
import styles from './styles';

type HeaderTableDropDownProps = {
  isShow: boolean;
  hasSelectedVendor: boolean;
  tabAnchorPosition?: number;
  onHoverBlock: (isShow: boolean) => void;
};

const HeaderTabDropDown = (props: HeaderTableDropDownProps) => {
  const { isShow, hasSelectedVendor, tabAnchorPosition = 0, onHoverBlock } = props;

  return (
    <>
      <Box
        sx={{
          display: isShow ? 'block' : 'none',
        }}
        data-testid="div-drop-down-block"
      >
        <Typography
          variant="body2"
          sx={styles.root}
          component="div"
          role="tabpanel"
          onMouseEnter={() => {
            onHoverBlock(isShow);
          }}
          onMouseLeave={() => onHoverBlock(false)}
        >
          <Box
            sx={[
              styles.accountTabList,
              {
                left: tabAnchorPosition,
              },
            ]}
          >
            {hasSelectedVendor ? (
              <Link
                component={RouterLink}
                to="/dashboard"
                sx={styles.accountTabLink}
                onClick={() => {
                  trackEvent(EVENTS.goToDashboardClicked, {
                    source: 'header_dropdown',
                  });
                }}
                data-testid="link-header-drop-down-dashboard"
              >
                Dashboard
              </Link>
            ) : (
              <Link
                sx={styles.accountTabLink}
                component={RouterLink}
                to="/membership-selection"
                onClick={() => {
                  trackEvent(EVENTS.switchMembershipClicked, {
                    source: 'header_dropdown',
                  });
                }}
                data-testid="link-header-drop-down-membership-selection"
              >
                Membership Selection
              </Link>
            )}
            <Link
              title="Go to main site"
              rel="nofollow"
              role="button"
              aria-hidden="true"
              sx={styles.accountTabLink}
              aria-label="The Bash Home"
              href={gmConfig.domains.www}
              onClick={() => {
                trackEvent(EVENTS.headerGoToPublicSiteClicked, {
                  source: 'header_dropdown',
                });
              }}
              data-testid="link-header-drop-down-go-to-public-site"
            >
              Go to main site
            </Link>
            <Link
              component={RouterLink}
              title="Logout"
              rel="nofollow"
              role="button"
              aria-hidden="true"
              sx={styles.accountTabLink}
              to="/logout"
              onClick={() => {
                trackEvent(EVENTS.logoutClicked, {
                  source: 'header_dropdown',
                });
              }}
              data-testid="link-header-drop-down-log-out"
            >
              Log out
            </Link>
          </Box>
        </Typography>
      </Box>
    </>
  );
};

export default HeaderTabDropDown;
