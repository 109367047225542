import React from 'react';
import classNames from 'classnames';

import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';

import styles from './styles';

// @ts-expect-error typescript doesn't like mui's styled
const StyledIconButton = styled(IconButton)(styles.closeButton);

type CloseButtonProps = {
  'data-testid'?: string;
  className?: IconButtonProps['className'];
  onClick: IconButtonProps['onClick'];
};

const CloseButton = (props: CloseButtonProps) => {
  const { 'data-testid': dataTestId = '', className, onClick, ...otherProps } = props;

  return (
    <StyledIconButton
      {...otherProps}
      aria-label="close"
      className={classNames(className, 'Dialog-closeButton')}
      data-testid={`button-${dataTestId}-close`}
      onClick={onClick}
    >
      <CloseIcon />
    </StyledIconButton>
  );
};

export default CloseButton;
