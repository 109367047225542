import React, { PropsWithChildren, useState } from 'react';
import { noop } from 'utils/function';
import { useSnackbar } from 'hooks/useSnackbar';
import { useInstantFeedback } from 'hooks/useInstantFeedback';
import { SnackbarOptions } from './snackbar';
import { InstantFeedbackOptions } from './instantFeedback';

export type UIGlobalContextState = {
  ui: {
    isLoading: boolean;
    snackbarState: {
      value: unknown;
      set: React.Dispatch<SnackbarOptions>;
    };
    instantFeedback: InstantFeedbackOptions;
  };
  setIsLoading: (value: boolean) => void;
  setSnackbar: (value: SnackbarOptions) => void;
  setInstantFeedback: (value: InstantFeedbackOptions) => void;
};

const initialGlobal = {
  ui: {
    isLoading: false,
    snackbarState: {
      value: '',
      set: noop,
    },
    instantFeedback: {
      open: false,
      message: '',
      actionText: '',
      notWithStickyBottomBar: false,
    },
  },
  setIsLoading: () => {},
  setSnackbar: () => {},
  setInstantFeedback: () => {},
};
export const UIContext = React.createContext<UIGlobalContextState>(initialGlobal);

export const GlobalContextProvider = ({ children }: PropsWithChildren<unknown>) => {
  const [isLoading, setIsLoading] = useState(initialGlobal.ui.isLoading);
  const { snackbar, setSnackbar } = useSnackbar();

  const { instantFeedback, setInstantFeedback } = useInstantFeedback();

  return (
    <UIContext.Provider
      value={{
        ui: {
          isLoading,
          snackbarState: { value: snackbar, set: setSnackbar },
          instantFeedback,
        },
        setIsLoading,
        setSnackbar,
        setInstantFeedback,
      }}
    >
      {children}
    </UIContext.Provider>
  );
};

export default UIContext;
