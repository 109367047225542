import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import { styled, useTheme } from '@mui/material/styles';

const StyledSvgIcon = styled(SvgIcon)({
  width: '300px',
  height: '88px',
});

interface InstallBannerRightProps extends SvgIconProps {}

const InstallBannerRight = (props: InstallBannerRightProps) => {
  const theme = useTheme();
  return (
    <StyledSvgIcon viewBox="0 0 300 88" fill="none" data-testid="svg" {...props}>
      <g clipPath="url(#clip0_2135_17645)">
        <circle
          cx="77"
          cy="77"
          r="77"
          transform="matrix(1 0 0 -1 240 177)"
          fill={theme.palette.blue[800]}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M262 38L269 45L262 52L255 45L262 38Z"
          fill={theme.palette.blue[500]}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M230 74L233 77L230 80L227 77L230 74Z"
          fill={theme.palette.blue[400]}
        />
      </g>
      <defs>
        <clipPath id="clip0_2135_17645">
          <rect width="300" height="88" rx="3" fill={theme.palette.common.white} />
        </clipPath>
      </defs>
    </StyledSvgIcon>
  );
};

export default InstallBannerRight;
