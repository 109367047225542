import { Theme } from '@mui/material';
import zIndex from 'theme/zIndex';

export const HEADER_HEIGHT = 60;

const styles = {
  wrapper: {
    transition: 'top 0.3s ease-in',
  },
  appBar: ({ theme }: { theme: Theme }) => ({
    zIndex: zIndex.header,
    backgroundColor: theme.palette.common.white,
    boxShadow: `inset 0px -1px 0px ${theme.palette.box.shadow}`,
    '@media print': {
      display: 'none',
    },
  }),
  toolBar: ({ theme }: { theme: Theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: '0px 15px',
    justifyContent: 'space-between',
    [theme.breakpoints.up('sm')]: {
      padding: '0px 15px',
    },
    [theme.breakpoints.up('md')]: {
      padding: '0px 32px',
    },
    '&.MuiToolbar-regular': {
      height: '100%',
      minHeight: '60px',
    },
  }),
  brandLogo: ({ theme }: { theme: Theme }) => ({
    display: 'flex',
    margin: 'auto',
    position: 'relative',
    left: 0,
    transform: 'none',
    color: theme.palette.common.black,
    '&:hover': {
      color: theme.palette.common.black,
    },
    '&:focus': {
      outline: `1px solid ${theme.palette.action.focus}`,
    },
    [theme.breakpoints.up('md')]: {
      marginLeft: 7,
    },
  }),
  menuIconButtonRoot: ({ theme }: { theme: Theme }) => ({
    display: 'flex',
    padding: '3px',
    [theme.breakpoints.up('md')]: {
      display: 'none',
      marginRight: 20,
    },
    '&:hover': { backgroundColor: 'transparent' },

    '& .MuiSvgIcon-root': {
      height: 32,
      width: 32,
      color: theme.palette.blue[500],
      '&:hover': { color: theme.palette.blue[700] },
      '&:focus': { color: theme.palette.blue[700] },
      '&:active': { color: theme.palette.blue[800] },
    },
  }),
  searchButton: ({ theme }: { theme: Theme }) => ({
    margin: 0,
    height: '100%',
    [theme.breakpoints.up('md')]: {
      margin: '0 20px',
      '&:last-child': {
        marginRight: 0,
      },
    },
  }),
};

export default styles;
