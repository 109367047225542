export const SECONDARY_CATEGORY_EVENTS = {
  categorySearchOptionsAiClick: 'Category Search Options AI Clicked',
  categorySearchOptionsEditCurrentClick: 'Category Search Options Edit Current Clicked',
  categorySearchOptionsUploadNewPhotoClick: 'Category Search Options Upload New Photo Clicked',
  categorySearchOptionsSelectFromGalleryClick: 'Category Search Options Select Gallery Clicked',
  categorySearchOptionsResetPhotoClick: 'Category Search Options Reset Photo Clicked',
  categorySearchOptionsSaveClickUnsuccessfully: 'Category Search Options Save Clicked Fail',
  categorySearchOptionsSaveClickSuccessfully: 'Category Search Options Save Clicked Ok',
} as const;

export interface SecondaryCategoryRowEventProps {
  vendorId: number;
  serviceTypeId: number;
  serviceTypeName: string;
}

export interface VendorCategoriesSaveEventProps {
  vendorId: number;
  numberOfSecondaryCategoriesSelected: number;
  numberOfSecondaryDescriptionsSet: number;
  numberOfSecondaryPhotosSet: number;
}

export type SecondaryCategoryEventPropsMap = {
  [SECONDARY_CATEGORY_EVENTS.categorySearchOptionsAiClick]: SecondaryCategoryRowEventProps;
  [SECONDARY_CATEGORY_EVENTS.categorySearchOptionsEditCurrentClick]: SecondaryCategoryRowEventProps;
  [SECONDARY_CATEGORY_EVENTS.categorySearchOptionsUploadNewPhotoClick]: SecondaryCategoryRowEventProps;
  [SECONDARY_CATEGORY_EVENTS.categorySearchOptionsSelectFromGalleryClick]: SecondaryCategoryRowEventProps;
  [SECONDARY_CATEGORY_EVENTS.categorySearchOptionsResetPhotoClick]: SecondaryCategoryRowEventProps;
  [SECONDARY_CATEGORY_EVENTS.categorySearchOptionsSaveClickUnsuccessfully]: VendorCategoriesSaveEventProps & {
    validationErrors: Record<string, string>;
  };
  [SECONDARY_CATEGORY_EVENTS.categorySearchOptionsSaveClickSuccessfully]: VendorCategoriesSaveEventProps;
};
