import { gigMasterAPIRequest } from 'api/index';
import { LegacyId } from 'types/vendor';
import {
  FoldersSummaryAPIModel,
  UpdateFoldersAPIRequest,
  UpdateFoldersAPIResponse,
} from './types/leadsFolder';

const updateFolders = ({ legacyId, folders }: UpdateFoldersAPIRequest) =>
  gigMasterAPIRequest.post<UpdateFoldersAPIResponse>(
    `/api/v1/members/${legacyId}/folders`,
    folders
  );

const getLeadsFolder = ({ legacyId }: { legacyId: LegacyId }) =>
  gigMasterAPIRequest.get<FoldersSummaryAPIModel[]>(`/api/v1/members/${legacyId}/folders/summary`);

export default {
  updateFolders,
  getLeadsFolder,
};
