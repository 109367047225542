import React from 'react';
import PropTypes from 'prop-types';
import sumBy from 'lodash/sumBy';
import { useLocation } from 'react-router-dom';
import useRequest from 'hooks/useRequest';
import accountAPI from 'api/account';
import vendorApi from 'api/vendor';
import { MemberSelectionVendor } from 'api/types/vendor';
import { Account } from 'api/types/account';

const initial = {};

const checkAppBadge = () =>
  'Notification' in window && Notification.permission === 'granted' && navigator.setAppBadge;

export const AccountStateContext = React.createContext<Account | Record<string, never>>(initial);
export const AccountDispatchContext = React.createContext<{
  refreshAccount: () => Promise<Account>;
} | null>(null);

export const useAccountContextState = () => React.useContext(AccountStateContext);
export const useAccountContextDispatch = () => React.useContext(AccountDispatchContext);

const GetAccountFetches = {
  init: 'init',
  refresh: 'refresh',
} as const;

export type GetAccountFetchesKey = keyof typeof GetAccountFetches;
export type GetAccountFetchesValue = (typeof GetAccountFetches)[GetAccountFetchesKey];

const GetVendorMembershipFetches = {
  fetch: 'fetch',
} as const;

export type GetVendorMembershipFetchesKey = keyof typeof GetVendorMembershipFetches;
export type GetVendorMembershipFetchesValue =
  (typeof GetVendorMembershipFetches)[GetVendorMembershipFetchesKey];

export const AccountContextProvider = ({
  children,
}: React.PropsWithChildren<Record<string, never>>) => {
  const { pathname } = useLocation();

  const {
    response,
    fetches: { [GetAccountFetches.init]: { isLoading } = { isLoading: true } },
    exec: getAccountRaw,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  }: any = useRequest(accountAPI.getAccount, {
    manual: false,
    params: {
      key: GetAccountFetches.init,
    },
    fetchKey: (p: { key: string }) => p.key,
  });

  const account = response?.data as Account;
  const getAccount: (params: { key: GetAccountFetchesValue }) => Promise<Account> = (params) =>
    getAccountRaw(params);

  const {
    response: { data: membershipListData },
    exec: fetchMembershipListData,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  }: any = useRequest(vendorApi.getListByBearerToken, {
    manual: true,
    params: {
      key: GetVendorMembershipFetches.fetch,
    },
    fetchKey: (p: { key: GetVendorMembershipFetchesKey }) => p.key,
  });

  const membershipList = membershipListData as MemberSelectionVendor[];

  const totalUnansweredLeadsNumberRef = React.useRef(0);

  totalUnansweredLeadsNumberRef.current = sumBy(membershipList, 'numberOfUnansweredLeads');

  // FIXME: This useEffect is being triggered on every pathname change which should not be necessary
  React.useEffect(() => {
    fetchMembershipListData({ key: GetVendorMembershipFetches.fetch });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  React.useEffect(() => {
    if (checkAppBadge()) {
      navigator.setAppBadge(totalUnansweredLeadsNumberRef.current);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [membershipListData]);

  React.useEffect(() => {
    const fetchBadgeNumber = () => {
      if (document.visibilityState === 'visible') {
        fetchMembershipListData({ key: 'fetch' });
      }
    };

    document.addEventListener('visibilitychange', fetchBadgeNumber);

    return () => {
      window.removeEventListener('visibilitychange', fetchBadgeNumber);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const dispatch = React.useMemo(
    () => ({
      refreshAccount: () =>
        getAccount({
          key: GetAccountFetches.refresh,
        }),
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <AccountStateContext.Provider value={account ?? initial}>
      <AccountDispatchContext.Provider value={dispatch}>
        {isLoading ? null : children}
      </AccountDispatchContext.Provider>
    </AccountStateContext.Provider>
  );
};

AccountContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AccountContextProvider;
