import React from 'react';
import { ThemeProvider } from '@mui/material/styles';
import { SlimFooter, muiTheme as sharedTheme } from '@tkww/the-bash-frontend';

import { gmConfig } from 'config/index';

export const Footer = () => (
  <ThemeProvider theme={sharedTheme}>
    <SlimFooter configDomains={gmConfig.domains} isLeftAligned />
  </ThemeProvider>
);
