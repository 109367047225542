export default {
  values: {
    xs: 0,
    sm: 768,
    md: 992,
    lg: 1340,
    xl: 1920,
  },
} as const;

// Temporary breakpoint until we refactor our current breakpoints
export const MobileBreakpoint = 440;
