export const OnDismissSources = {
  CLOSE_BUTTON: 'close_button',
  DISMISS_BUTTON: 'dismiss_button',
} as const;

export type OnDismissSourceKey = keyof typeof OnDismissSources;
export type OnDismissSourceValue = (typeof OnDismissSources)[OnDismissSourceKey];

export const OnCloseSources = {
  CLOSE_BUTTON: 'close_button',
  REMIND_ME_LATER_BUTTON: 'remind_me_later_button',
  DISMISS_BUTTON: 'dismiss_button',
} as const;

export type OnCloseSourceKey = keyof typeof OnCloseSources;
export type OnCloseSourceValue = (typeof OnCloseSources)[OnCloseSourceKey];
