import { Theme } from '@mui/material';

const styles = {
  dialogPaper: {
    '& > .MuiDialog-container > .MuiDialog-paper': {
      marginLeft: '20px',
      marginRight: '20px',
      maxWidth: '600px',
    },
  },

  title: {
    display: 'flex',
    padding: '16px 16px 8px 24px',
    justifyContent: 'space-between',
    alignItems: 'center',
  },

  dialogContent: (theme: Theme) => ({
    padding: '0px 32px 0px 32px',

    [theme.breakpoints.down('sm')]: {
      padding: '4px 32px 0px 32px',
    },
  }),

  dialogActions: (theme: Theme) => ({
    padding: '16px',
    [theme.breakpoints.up('sm')]: {
      borderTop: `1px solid ${theme.palette.grey[300]}`,
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      padding: '16px 0px 28px 0px',
    },
  }),

  actionsButton: (theme: Theme) => ({
    whiteSpace: 'nowrap',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  }),
};

export default styles;
