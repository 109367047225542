import { generateTrackEventAction } from 'redux/action';
import store from 'redux/store';
import { LegacyId } from 'types/vendor';
import { DescriptionAITermsDialogEventSourceValue } from 'components/Dialog/DescriptionAITermsDialog/constants';
import {
  BILLINGINFO_EVENTS,
  BillingInformationEventPropsMap,
} from 'helpers/tracking/billingInformation';
import { LEAD_DETAIL_EVENTS, LeadDetailEventPropsMap } from './leadDetail';
import { SEND_QUOTE_EVENTS, SendQuoteEventPropsMap } from './sendQuote';
import { MESSAGES_EVENTS, MessagesEventPropsMap } from './messages';
import { BOOK_NOW_EVENTS, BookNowEventPropsMap } from './bookNow';
import { REVIEWS_EVENTS, ReviewsEventPropsMap } from './reviews';
import { SAVED_RESPONSES_EVENTS, SavedResponsesEventPropsMap } from './savedResponses';
import { SECONDARY_CATEGORY_EVENTS, SecondaryCategoryEventPropsMap } from './secondaryCategory';
import { LEADS_FOLDER_EVENTS, LeadsFolderEventPropsMap } from './leadsFolder';
import { PageSourceValue } from './sources';
import { EVENT_NAME_MAX_LENGTH, EVENT_NAME_REGEX } from './constants';
import { transformEventPropertiesCase } from './helpers';
import { FeaturedProfileEventPropsMap, FEATURED_PROFILE_EVENTS } from './featuredProfile';
import { ManageMembershipEventPropsMap, MEMBERSHIP_EVENTS } from './membership';
import { ManageFeaturedProfilesEventPropsMap } from './manageFeaturedProfiles';

export const EVENTS = {
  passwordChanged: 'Account New Password Changed Ok',
  authRefreshTokenFailure: 'Auth Refresh Token Failed',
  profileDescriptionEnhanceWithAI: 'Profile Modify Description Start AI Enhanced',
  profileDescriptionChangeAITone: 'Profile Modify Description Changed AI Tone',
  profileDescriptionEnhancedWithAI: 'Profile Modify Description Enhanced With AI',
  /** Vendor clicked on see The Bash AI Policy */
  aiPolicyClick: 'AI Policy Modal See Policy Clicked',
  aiPolicyAccepted: 'AI Policy Modal Policy Accepted',
  dragReview: 'Review List Review Dragged',
  orderReviewsByDate: 'Review List Sort By Date Button Clicked',
  saveReviewsOrderChanges: 'Review List Reviews Order Saved',
  logoutClicked: 'Logout Clicked',
  goToDashboardClicked: 'Dashboard Button Clicked',
  headerGoToPublicSiteClicked: 'Header Public Site Button Clicked',
  headerTapOnPWARefresh: 'Header Pwa Refresh Button Clicked',
  switchMembershipClicked: 'Membership Selection Button Clicked',
  contactUsClicked: 'Contact Us Button Clicked',
  leftNavMenuItemClicked: 'Left Nav Menu Item Clicked',
  stickyBottomBarConfirmButtonClicked: 'Sticky Bottom Bar Confirm Button Clicked',
  stickyBottomBarCancelButtonClicked: 'Sticky Bottom Bar Cancel Button Clicked',
  ...LEAD_DETAIL_EVENTS,
  ...SEND_QUOTE_EVENTS,
  ...MESSAGES_EVENTS,
  ...BOOK_NOW_EVENTS,
  ...REVIEWS_EVENTS,
  ...SAVED_RESPONSES_EVENTS,
  ...SECONDARY_CATEGORY_EVENTS,
  ...LEADS_FOLDER_EVENTS,
  ...FEATURED_PROFILE_EVENTS,
  ...MEMBERSHIP_EVENTS,
  ...BILLINGINFO_EVENTS,
} as const;

type NoProps = Record<string, never>;

export type EventPropsMap = {
  [EVENTS.passwordChanged]: NoProps;
  [EVENTS.authRefreshTokenFailure]: NoProps;
  [EVENTS.profileDescriptionEnhanceWithAI]: {
    legacyId: LegacyId;
  };
  [EVENTS.profileDescriptionChangeAITone]: {
    legacyId: LegacyId;
  };
  [EVENTS.profileDescriptionEnhancedWithAI]: {
    legacyId: LegacyId;
    tone: string;
    /** Whether the user made changes to the description after it was enhanced */
    changesMade: boolean;
  };
  [EVENTS.aiPolicyClick]: {
    legacyId: LegacyId;
  };
  [EVENTS.aiPolicyAccepted]: {
    legacyId: LegacyId;
    source: DescriptionAITermsDialogEventSourceValue;
  };
  [EVENTS.dragReview]: {
    legacyId: LegacyId;
    accountId: number;
    reviewId: number;
    fromIndex: number;
    toIndex: number;
  };
  [EVENTS.orderReviewsByDate]: {
    legacyId: LegacyId;
    accountId: number;
  };
  [EVENTS.saveReviewsOrderChanges]: {
    legacyId: number;
    accountId: number;
  };
  [EVENTS.logoutClicked]: {
    source: 'header_dropdown' | 'left_nav';
  };
  [EVENTS.goToDashboardClicked]: {
    source: 'logo' | 'header_dropdown';
  };
  [EVENTS.headerGoToPublicSiteClicked]: {
    source: 'header_dropdown' | 'left_nav';
  };
  [EVENTS.headerTapOnPWARefresh]: NoProps;
  [EVENTS.switchMembershipClicked]: {
    // TODO: should we have our event sources in a shared separate place?
    source: 'header_dropdown' | 'left_nav';
  };
  [EVENTS.contactUsClicked]: {
    source: 'header' | 'left_nav';
  };
  [EVENTS.leftNavMenuItemClicked]: {
    title: string;
    url: string;
  };
  [EVENTS.leadsFoldersToggleClicked]: {
    expanded: boolean;
  };
  [EVENTS.stickyBottomBarConfirmButtonClicked]: {
    source: PageSourceValue;
  };
  [EVENTS.stickyBottomBarCancelButtonClicked]: {
    source: PageSourceValue;
  };
} & LeadDetailEventPropsMap &
  SendQuoteEventPropsMap &
  MessagesEventPropsMap &
  BookNowEventPropsMap &
  ReviewsEventPropsMap &
  SavedResponsesEventPropsMap &
  SecondaryCategoryEventPropsMap &
  LeadsFolderEventPropsMap &
  FeaturedProfileEventPropsMap &
  ManageMembershipEventPropsMap &
  ManageFeaturedProfilesEventPropsMap &
  BillingInformationEventPropsMap;

const validateEventName = (name: string) => {
  if (name.length > EVENT_NAME_MAX_LENGTH) {
    throw new Error(
      `Event name "${name}" is too long (${name.length} characters vs ${EVENT_NAME_MAX_LENGTH} max allowed)`
    );
  }
  if (!EVENT_NAME_REGEX.test(name)) {
    throw new Error(`Event name "${name}" does not match the expected format (no special chars)`);
  }
};

export const trackEvent = <K extends keyof EventPropsMap>(
  name: K,
  props: EventPropsMap[K]
): void => {
  if (process.env.NODE_ENV !== 'production') {
    validateEventName(name);
  }

  store.dispatch(generateTrackEventAction(name, transformEventPropertiesCase(props)));
};
