import cookie from 'js-cookie';
import { gmConfig } from 'config/index';

const cookieHelper = {
  get: (key: string) => cookie.get(`${gmConfig.cookie.prefix}${key}`),
  remove: (key: string, hasPrefix = true) => {
    const { prefix, domain } = gmConfig.cookie;
    cookie.remove(hasPrefix ? `${prefix}${key}` : key, { domain });
  },

  set: (key: string, value: string, options = {}) => {
    cookie.set(`${gmConfig.cookie.prefix}${key}`, value, {
      ...options,
    });
  },
};

export default cookieHelper;
