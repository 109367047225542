import { gmConfig } from 'config/index';
import {
  BEARER_TOKEN_COOKIE_NAME,
  REFRESH_TOKEN_COOKIE_NAME,
  REFRESH_TOKEN_COOKIE_EXPIRATION_DAYS,
  JWT_COOKIE_NAME,
} from 'constants/storage';
import cookieHelper from 'helpers/cookieHelper';
import accountApi from 'api/account';
import { DEFAULT_PERFORMER_CONTEXT } from '../constants/storage';

export const redirectToLoginPage = () => {
  let query = '';
  const returnUrl = `${window.location.pathname}${window.location.search}`;
  if (!/^\/logout/.test(returnUrl)) {
    query = `?ReturnUrl=${encodeURIComponent(returnUrl)}`;
  }

  if ('caches' in window) {
    caches.delete('whoami');
  }
  window.location.href = gmConfig.auth.loginRoute + query;
};

export const setTokens = ({
  accessToken,
  refreshToken,
}: {
  accessToken?: string;
  refreshToken?: string;
}) => {
  cookieHelper.set(BEARER_TOKEN_COOKIE_NAME, accessToken || '', {
    expires: REFRESH_TOKEN_COOKIE_EXPIRATION_DAYS,
    domain: gmConfig.cookie.domain,
  });
  cookieHelper.set(REFRESH_TOKEN_COOKIE_NAME, refreshToken || '', {
    expires: REFRESH_TOKEN_COOKIE_EXPIRATION_DAYS,
    domain: gmConfig.cookie.domain,
  });
  cookieHelper.remove(JWT_COOKIE_NAME, true);
};

export const refreshTokens = async () => {
  const refreshToken = cookieHelper.get(REFRESH_TOKEN_COOKIE_NAME);
  if (!refreshToken) {
    throw new Error('No refresh token found');
  }

  const payload = new URLSearchParams({
    grant_type: 'refresh_token',
    refresh_token: refreshToken,
    client_id: gmConfig.auth.client.id!,
    client_secret: gmConfig.auth.client.secret!,
  });
  const { data } = await accountApi.grantToken(payload);
  setTokens({
    accessToken: data.access_token,
    refreshToken: data.refresh_token,
  });
};

export const redirectWithCheckingAuth = async (redirectUrl: string) => {
  try {
    await refreshTokens();
  } catch (e) {
    redirectToLoginPage();
    return;
  }
  window.location.href = redirectUrl;
};

export const switchMembership = (legacyId: number, redirectUrl: string) => {
  if (legacyId) {
    cookieHelper.set(DEFAULT_PERFORMER_CONTEXT, `${legacyId}`, {
      domain: gmConfig.cookie.domain,
    });
  }
  redirectWithCheckingAuth(`/Authenticate?ReturnUrl=${encodeURIComponent(redirectUrl)}`);
};
