import { Theme } from '@mui/material';
import { HEADER_HEIGHT } from '../styles';

const styles = {
  root: (theme: Theme) => ({
    boxSizing: 'border-box',
    position: 'fixed',
    top: HEADER_HEIGHT,
    width: '100%',
    paddingTop: '32px',
    paddingBottom: '32px',
    backgroundColor: theme.palette.background.default,
    zIndex: theme.zIndex.appBar,
  }),
  accountTabList: {
    position: 'relative',
    margin: '0px',
    display: 'flex',
    flexDirection: 'column',
    height: '258px',
    '& a': {
      margin: '8px 0',
    },
  },
  accountTabLink: (theme: Theme) => ({
    fontFamily: 'SofiaPro-Regular',
    fontSize: '16px',
    color: theme.palette.black[900],
    textDecoration: 'none',
    '&:hover': {
      color: theme.palette.blue[500],
      textDecoration: 'underline',
    },
  }),
};

export default styles;
