import axios from 'axios';
import React from 'react';
import { useTheme } from '@mui/material/styles';
import { useQuery } from '@tanstack/react-query';
import { QUERY_KEY } from 'constants/queryKeys';
import styles from './styles';

export const devAPIRequest = axios.create({
  baseURL: `http://localhost:3002/dev/api`,
  withCredentials: true,
});

interface LocalDevVendorAccount {
  accountId: string;
  vendorCount: number;
}

const DevelopmentLogin = () => {
  const {
    data: availableAccounts,
    isLoading: areAccountsLoading,
    isError: isLoadAccountsError,
  } = useQuery({
    queryKey: [QUERY_KEY.DEVELOPMENT_ACCOUNTS],
    queryFn: async () => (await devAPIRequest.get<LocalDevVendorAccount[]>('/accounts')).data,
    initialData: [],
  });

  const theme = useTheme();

  const [isLoginLoading, setIsLoginLoading] = React.useState(false);

  const onAccountClick = async (accountId: string) => {
    setIsLoginLoading(true);

    try {
      await devAPIRequest.put('/login', { accountId });

      window.location.href = `/membership-selection`;
    } catch (error) {
      setIsLoginLoading(false);
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };

  return (
    <div style={styles.root}>
      <h1>Development Login Page</h1>

      {/* eslint-disable-next-line no-nested-ternary */}
      {areAccountsLoading ? (
        <p style={styles.loadingMessage(theme)}>Loading accounts, please wait...</p>
      ) : isLoadAccountsError ? (
        <p style={styles.errorMessage}>Load accounts request failed.</p>
      ) : (
        <>
          <p>Please, select an account to log in:</p>

          <ul>
            {availableAccounts.map((account) => (
              <li style={styles.accountListItem} key={account.accountId}>
                <button
                  type="button"
                  data-testid={`account-${account.accountId}-button`}
                  disabled={isLoginLoading}
                  onClick={() => onAccountClick(account.accountId)}
                >
                  Account {account.accountId} ({account.vendorCount} profiles)
                </button>
              </li>
            ))}
          </ul>
        </>
      )}
    </div>
  );
};

export default DevelopmentLogin;
