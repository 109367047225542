import React, { Suspense, lazy, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Switch, Route, Redirect, useLocation } from 'react-router-dom';
import Box from '@mui/material/Box';
import { ErrorBoundary } from 'react-error-boundary';

import Header from 'components/Header';
import { Footer } from 'components/Footer';
import LeftNav from 'components/LeftNav';
import VendorRoute from 'components/VendorRoute';
import cookieHelper from 'helpers/cookieHelper';
import { gmConfig } from 'config/index';
import {
  BEARER_TOKEN_COOKIE_NAME,
  REFRESH_TOKEN_COOKIE_NAME,
  SCOPES,
  WELCOME_NAME,
  DEFAULT_PERFORMER_CONTEXT,
  JWT_COOKIE_NAME,
  ASPXAUTH_LEGACY,
  K,
  AUTHENTICATED_USER_LOCAL_STORAGE_NAME,
} from 'constants/storage';
import SnackbarList from 'components/SnackbarList';
import GlobalLoader from 'components/GlobalLoader';
import { useScrollToTop } from 'hooks/useScrollToTop';
import { trackPageViewEvent } from 'helpers/tracking/pageView';
import InstantFeedbackSnackbar from 'components/InstantFeedbackSnackbar';
import PageView from 'components/PageView';
import PageLoader from 'components/Layout/PageLoader';
import NotificationPermissionRequest from 'components/NotificationPermissionRequest';

import { ParentContainerContextProvider } from 'context/parentContainer';
import SEOFeatureNotificationDialog from 'components/Dialog/SEOFeatureNotificationDialog';
import styles, { classes as appClasses } from './styles';
import localStorageHelper from './helpers/localStorageHelper';

const setRefreshCount = () => {
  if (window.localStorage) {
    if (window.localStorage.getItem('refreshCount')) {
      const c = Number(window.localStorage.getItem('refreshCount'));

      window.localStorage.setItem('refreshCount', `${c + 1}`);
    } else {
      window.localStorage.setItem('refreshCount', '0');
    }

    return window.localStorage.getItem('refreshCount');
  }
};

const tryToReloadPage = (fn) =>
  new Promise((resolve, reject) => {
    fn()
      .then((resp) => {
        window.localStorage.setItem('refreshCount', '0');
        resolve(resp);
      })
      .catch((error) => {
        const c = setRefreshCount();

        if (c > 3) {
          reject(error);
          return;
        }

        window.location.reload();
      });
  });

const MembershipSelection = lazy(() => tryToReloadPage(() => import('views/MembershipSelection')));
const DevelopmentFeatureFlags = lazy(() =>
  tryToReloadPage(() => import('views/Development/DevelopmentFeatureFlags'))
);
const Dashboard = lazy(() => tryToReloadPage(() => import('views/Dashboard')));
const Membership = lazy(() => tryToReloadPage(() => import('views/Membership')));
const EventTypes = lazy(() => tryToReloadPage(() => import('views/EventTypes')));
const ProfileModification = lazy(() =>
  tryToReloadPage(() => import('./views/ProfileModification'))
);
const ManageFolders = lazy(() => tryToReloadPage(() => import('views/ManageFolders')));
const WrapperFolderLeads = lazy(() =>
  tryToReloadPage(() => import('views/Leads/WrapperFolderLeads'))
);
const UnansweredLeads = lazy(() => tryToReloadPage(() => import('views/Leads/UnansweredLeads')));
const SavedResponses = lazy(() => tryToReloadPage(() => import('views/SavedResponses')));
const LeadDetail = lazy(() => tryToReloadPage(() => import('views/LeadDetail')));
const LeadMessages = lazy(() => tryToReloadPage(() => import('views/Leads/Messages')));
const RespondLeadRequest = lazy(() => tryToReloadPage(() => import('views/RespondLeadRequest')));
const Profile = lazy(() => tryToReloadPage(() => import('views/Profile')));
const Categories = lazy(() => tryToReloadPage(() => import('views/Categories')));
const GalleryPhotos = lazy(() => tryToReloadPage(() => import('views/GalleryPhotos')));
const ProfilePhoto = lazy(() => tryToReloadPage(() => import('views/ProfilePhoto')));
const RelatedProfiles = lazy(() => tryToReloadPage(() => import('views/RelatedProfiles')));
const SongList = lazy(() => tryToReloadPage(() => import('views/SongList')));
const GroupMembers = lazy(() => tryToReloadPage(() => import('views/GroupMembers')));
const Reviews = lazy(() => tryToReloadPage(() => import('views/Reviews')));
const Videos = lazy(() => tryToReloadPage(() => import('views/Videos')));
const Account = lazy(() => tryToReloadPage(() => import('views/Account')));
const VacationMode = lazy(() => tryToReloadPage(() => import('views/VacationMode')));
const OptionalFeatures = lazy(() => tryToReloadPage(() => import('views/OptionalFeatures')));
const ContactInfo = lazy(() => tryToReloadPage(() => import('views/ContactInfo')));
const Password = lazy(() => tryToReloadPage(() => import('views/Password')));
const ReferralBonus = lazy(() => tryToReloadPage(() => import('views/ReferralBonus')));
const ManageEventPay = lazy(() => tryToReloadPage(() => import('views/ManageEventPay')));
const CreateEventPay = lazy(() => tryToReloadPage(() => import('views/EventPayAccount/Create')));
const UpdateEventPay = lazy(() => tryToReloadPage(() => import('views/EventPayAccount/Update')));
const PhotoIDVerification = lazy(() => tryToReloadPage(() => import('views/PhotoIDVerification')));
const SSNVerification = lazy(() => tryToReloadPage(() => import('views/SSNVerification')));
const Tools = lazy(() => tryToReloadPage(() => import('views/Tools')));
const Calendar = lazy(() => tryToReloadPage(() => import('views/Calendar')));
const CreateVerifiedBooking = lazy(() =>
  tryToReloadPage(() => import('views/CreateVerifiedBooking'))
);
const PortableContactWidget = lazy(() =>
  tryToReloadPage(() => import('views/PortableContactWidget'))
);
const ReviewsWidget = lazy(() => tryToReloadPage(() => import('views/ReviewsWidget')));
const ReviewDetail = lazy(() => tryToReloadPage(() => import('views/ReviewDetail')));
const AwardsAndBadges = lazy(() => tryToReloadPage(() => import('views/AwardsAndBadges')));
const ProfileExposure = lazy(() => tryToReloadPage(() => import('views/ProfileExposure')));
const SearchResultsAnalyzer = lazy(() =>
  tryToReloadPage(() => import('views/SearchResultsAnalyzer'))
);
const BillingInformation = lazy(() => tryToReloadPage(() => import('views/BillingInformation')));
const ReactivateLiteAccount = lazy(() =>
  tryToReloadPage(() => import('views/ReactivateLiteAccount'))
);
const Transactions = lazy(() => tryToReloadPage(() => import('views/Transactions')));
const BookingDollars = lazy(() => tryToReloadPage(() => import('views/BookingDollars')));
const CreateSavedResponse = lazy(() =>
  tryToReloadPage(() => import('views/SavedResponses/CreateSavedResponse'))
);
const EditSavedResponse = lazy(() =>
  tryToReloadPage(() => import('views/SavedResponses/EditSavedResponse'))
);
const PurchaseFeaturedProfiles = lazy(() =>
  tryToReloadPage(() => import('views/PurchaseFeaturedProfiles'))
);
const FeaturedProfile = lazy(() => tryToReloadPage(() => import('views/FeaturedProfile')));

const handleLogout = () => {
  document.getElementById('root').style.display = 'none';
  cookieHelper.remove(BEARER_TOKEN_COOKIE_NAME);
  cookieHelper.remove(REFRESH_TOKEN_COOKIE_NAME);
  cookieHelper.remove(SCOPES);
  cookieHelper.remove(WELCOME_NAME);
  cookieHelper.remove(DEFAULT_PERFORMER_CONTEXT);
  cookieHelper.remove(JWT_COOKIE_NAME);
  cookieHelper.remove(ASPXAUTH_LEGACY, false);
  cookieHelper.remove(K);
  localStorageHelper.remove(AUTHENTICATED_USER_LOCAL_STORAGE_NAME);

  if (navigator.setAppBadge) {
    navigator.clearAppBadge();
  }

  if ('caches' in window) {
    caches.delete('whoami');
  }

  trackPageViewEvent('Log Out', {
    title: '',
  });
  window.location = gmConfig.auth.loginRoute;
};

const StarRatingRedirect = () => <Redirect from="/tools/star-rating" to="/profile/reviews" />;
const LeadsRedirect = ({ inboxFolderId }) => (
  <Redirect from="/leads" to={`/leads/folders/${inboxFolderId}`} />
);
LeadsRedirect.propTypes = {
  inboxFolderId: PropTypes.number.isRequired,
};

const TheBashMCPApp = () => {
  const location = useLocation();

  const appContentBoxRef = useRef();

  const scrollToTop = useScrollToTop();

  useEffect(() => {
    scrollToTop();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  return (
    <>
      <GlobalLoader />
      <Route
        path="/logout"
        render={() => {
          handleLogout();
          return null;
        }}
      />
      <PageView />
      <Header />
      <Box sx={styles.coloredBackground}>
        <LeftNav />
        <ParentContainerContextProvider value={appContentBoxRef}>
          <Box ref={appContentBoxRef} className={appClasses.root} sx={styles.appContentBox}>
            <SnackbarList />
            <ErrorBoundary
              key={location.pathname}
              fallback={<PageLoader isError isLoading={false} />}
            >
              <Suspense fallback={<PageLoader isLoading />}>
                <Switch>
                  <Route path="/membership-selection" component={MembershipSelection} />
                  {gmConfig.featureFlags.mock && (
                    <Route path="/dev/feature-flags" component={DevelopmentFeatureFlags} />
                  )}
                  <Route>
                    <Switch>
                      <VendorRoute path="/dashboard" component={Dashboard} />
                      <VendorRoute path="/membership" component={Membership} />
                      <VendorRoute path="/profile/modify" component={ProfileModification} />
                      <VendorRoute path="/profile/profile-photo" component={ProfilePhoto} />
                      <VendorRoute path="/profile/categories" component={Categories} />
                      <VendorRoute path="/profile/event-types" component={EventTypes} />
                      <VendorRoute path="/profile/gallery-photos" component={GalleryPhotos} />
                      <VendorRoute path="/profile/related-profiles" component={RelatedProfiles} />
                      <VendorRoute path="/profile/song-list" component={SongList} />
                      <VendorRoute path="/profile/group-members" component={GroupMembers} />
                      <VendorRoute path="/profile/reviews" component={Reviews} />
                      <VendorRoute path="/profile/videos" component={Videos} />
                      <VendorRoute path="/profile" component={Profile} />
                      <VendorRoute
                        path="/featured-profile/create"
                        component={PurchaseFeaturedProfiles}
                      />
                      <VendorRoute path="/featured-profile" component={FeaturedProfile} />
                      <VendorRoute path="/account/vacation-mode" component={VacationMode} />
                      <VendorRoute path="/account/optional-features" component={OptionalFeatures} />
                      <VendorRoute path="/account/contact-info" component={ContactInfo} />
                      <VendorRoute path="/account/password" component={Password} />
                      <VendorRoute path="/account/referral-bonus" component={ReferralBonus} />
                      <VendorRoute
                        path="/account/billing-information"
                        component={BillingInformation}
                      />
                      <VendorRoute path="/account/reactivate" component={ReactivateLiteAccount} />
                      <VendorRoute
                        path="/account/eventpay/:stripeAccountId/upload-document"
                        component={PhotoIDVerification}
                      />
                      <VendorRoute
                        path="/account/eventpay/:stripeAccountId/ssn"
                        component={SSNVerification}
                      />
                      <VendorRoute path="/account/eventpay/create" component={CreateEventPay} />
                      <VendorRoute
                        path="/account/eventpay/:stripeAccountId"
                        component={UpdateEventPay}
                      />
                      <VendorRoute path="/account/eventpay" component={ManageEventPay} />
                      <VendorRoute path="/account/transactions" component={Transactions} />
                      <VendorRoute path="/account" component={Account} />
                      <VendorRoute path="/tools/search-results" component={SearchResultsAnalyzer} />
                      <VendorRoute path="/tools/contact-widget" component={PortableContactWidget} />
                      <VendorRoute path="/tools/reviews-widget" component={ReviewsWidget} />
                      <VendorRoute path="/reviews/:reviewId" component={ReviewDetail} />
                      <VendorRoute path="/tools/awards-badges" component={AwardsAndBadges} />
                      <VendorRoute path="/tools/profile-exposure" component={ProfileExposure} />
                      <VendorRoute path="/tools/star-rating" component={StarRatingRedirect} />
                      <VendorRoute path="/tools/booking-dollars" component={BookingDollars} />
                      <VendorRoute path="/tools" component={Tools} />
                      <VendorRoute path="/leads/manage-folders" component={ManageFolders} />
                      <VendorRoute path="/leads/folders/:folderId" component={WrapperFolderLeads} />
                      <VendorRoute path="/leads/unanswered" component={UnansweredLeads} />
                      <VendorRoute path="/leads/:leadId/response" component={RespondLeadRequest} />
                      <VendorRoute exact path="/leads/saved-responses" component={SavedResponses} />
                      <VendorRoute
                        path="/leads/saved-responses/create"
                        component={CreateSavedResponse}
                      />
                      <VendorRoute
                        path="/leads/saved-responses/:savedResponseId"
                        component={EditSavedResponse}
                      />
                      <VendorRoute exact path="/leads/:leadId" component={LeadDetail} />
                      <VendorRoute exact path="/leads/:leadId/messages" component={LeadMessages} />
                      <VendorRoute exact path="/leads" component={LeadsRedirect} />
                      <VendorRoute
                        path="/calendar/create-booking"
                        component={CreateVerifiedBooking}
                      />
                      <VendorRoute path="/calendar" component={Calendar} />
                      <Redirect from="/" to="/dashboard" />
                    </Switch>
                    <div>
                      <NotificationPermissionRequest />
                      <SEOFeatureNotificationDialog />
                    </div>
                  </Route>
                </Switch>
              </Suspense>
              <InstantFeedbackSnackbar />
              <Box sx={styles.footer}>
                <Footer />
              </Box>
            </ErrorBoundary>
          </Box>
        </ParentContainerContextProvider>
      </Box>
    </>
  );
};

export default TheBashMCPApp;
