export const REVIEWS_EVENTS = {
  reviewsPostButtonClickSuccessfully: 'Reviews Post Button Clicked Ok',
  reviewsDeleteButtonClick: 'Reviews Delete Button Clicked',
  reviewsConfirmDeleteButtonClickSuccessfully: 'Reviews Confirm Delete Button Clicked Ok',
  reviewsReplyButtonClick: 'Reviews Reply Button Clicked',
  reviewsEditButtonClick: 'Reviews Edit Button Clicked',
  reviewsReviewRatingButtonClick: 'Reviews Review Rating Button Clicked',
  reviewsTypeOfEventButtonClick: 'Reviews Type Of Event Button Clicked',
} as const;

export interface ReviewsBaseEventProperties {
  vendorId: number;
  reviewsTotal: number;
  averageRating: number;
}

export interface ReviewDetailBaseEventProperties {
  vendorId: number;
  reviewId: number;
  averageRating: number;
}

export type ReviewsEventPropsMap = {
  [REVIEWS_EVENTS.reviewsPostButtonClickSuccessfully]: ReviewDetailBaseEventProperties & {
    isEditing: boolean;
  };
  [REVIEWS_EVENTS.reviewsDeleteButtonClick]: ReviewDetailBaseEventProperties;
  [REVIEWS_EVENTS.reviewsConfirmDeleteButtonClickSuccessfully]: ReviewDetailBaseEventProperties;
  [REVIEWS_EVENTS.reviewsReplyButtonClick]: ReviewDetailBaseEventProperties &
    ReviewsBaseEventProperties;
  [REVIEWS_EVENTS.reviewsEditButtonClick]: ReviewDetailBaseEventProperties &
    ReviewsBaseEventProperties;
  [REVIEWS_EVENTS.reviewsReviewRatingButtonClick]: ReviewDetailBaseEventProperties &
    ReviewsBaseEventProperties;
  [REVIEWS_EVENTS.reviewsTypeOfEventButtonClick]: ReviewDetailBaseEventProperties &
    ReviewsBaseEventProperties;
};
