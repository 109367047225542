import { LeadsSortByFieldValue } from 'views/Leads/constant';

export const LEADS_FOLDER_EVENTS = {
  leadsFolderSortAndFilterButtonClicked: 'Leads Folder SAF Button Clicked',
  leadsFolderSortAndFilterSortByClicked: 'Leads Folder SAF Sort By Clicked',
  leadsFolderSortAndFilterStatusFilterClicked: 'Leads Folder SAF Status Filter Clicked',
  leadsFolderSortAndFilterAwaitingReviewFilterClicked:
    'Leads Folder SAF Awaiting Review Filter Clicked',
  // FIXME clicked or used?
  leadsFolderSortAndFilterLocationFilterClicked: 'Leads Folder SAF Location Filter Used',
  leadsFolderSortAndFilterPhoneFilterUsed: 'Leads Folder SAF Phone Filter Used',
  leadsFolderSortAndFilterEmailFilterUsed: 'Leads Folder SAF Email Filter Used',
  leadsFolderSortAndFilterFirstNameFilterUsed: 'Leads Folder SAF First Name Filter Used',
  leadsFolderSortAndFilterLastNameFilterUsed: 'Leads Folder SAF Last Name Filter Used',
  leadsFolderSortAndFilterEventDateRangeFromFilterUsed:
    'Leads Folder SAF Event Date Range From Filter Used',
  leadsFolderSortAndFilterEventDateRangeToFilterUsed:
    'Leads Folder SAF Event Date Range To Filter Used',
  leadsFolderSortAndFilterViewLeadsButtonClicked: 'Leads Folder SAF View Leads Button Clicked',
  leadsFolderSortAndFilterCancelButtonClicked: 'Leads Folder SAF Cancel Button Clicked',
  leadsFolderSearchByGigIdClicked: 'Leads Folder Search By Gig Id Clicked',
} as const;

interface LeadFolderProperties {
  leadFolder: number;
}

const SortByLabels = {
  DATE_CREATED: 'date_created',
  NEWEST_TO_OLDEST: 'newest_to_oldest',
  OLDEST_TO_NEWEST: 'oldest_to_newest',
} as const;

type SortByLabelsKey = keyof typeof SortByLabels;
export type SortByLabelsValue = (typeof SortByLabels)[SortByLabelsKey];

interface SortByOptionItem {
  label: SortByLabelsValue;
  value: LeadsSortByFieldValue;
}

export const LEADS_SORT_BY_ITEMS: SortByOptionItem[] = [
  { label: 'date_created', value: '0' },
  { label: 'newest_to_oldest', value: '2' },
  { label: 'oldest_to_newest', value: '3' },
];

interface SortByProperties extends LeadFolderProperties {
  sortBy: SortByLabelsValue;
}

type SortByEventPropsMap = {
  [LEADS_FOLDER_EVENTS.leadsFolderSortAndFilterSortByClicked]: SortByProperties;
};

const Status = {
  UNANSWERED: 'unanswered',
  BOOKED: 'booked',
  DECLINED: 'declined',
  QUOTE_SENT: 'quoteSent',
  QUOTE_EXPIRED: 'quoteExpired',
  PENDING_PAYMENT: 'pendingPayment',
  INACTIVE: 'inactive',
} as const;

type StatusKey = keyof typeof Status;
export type StatusValue = (typeof Status)[StatusKey];

interface StatusSelectedProperties extends LeadFolderProperties {
  statusSelected: StatusValue;
}

type StatusSelectedEventPropsMap = {
  [LEADS_FOLDER_EVENTS.leadsFolderSortAndFilterStatusFilterClicked]: StatusSelectedProperties;
};

interface AwaitingReviewProperties extends LeadFolderProperties {
  awaitingReviewChecked: boolean;
}

type AwaitingReviewPropsMap = {
  [LEADS_FOLDER_EVENTS.leadsFolderSortAndFilterAwaitingReviewFilterClicked]: AwaitingReviewProperties;
};

interface DateRangeSelectedProperties extends LeadFolderProperties {
  dateSelected: string;
}

type DateRangeSelectedPropsMap = {
  [LEADS_FOLDER_EVENTS.leadsFolderSortAndFilterEventDateRangeFromFilterUsed]: DateRangeSelectedProperties;
  [LEADS_FOLDER_EVENTS.leadsFolderSortAndFilterEventDateRangeToFilterUsed]: DateRangeSelectedProperties;
};

export interface ApplyFiltersEventProperties extends LeadFolderProperties {
  sortByOptionSelected: boolean;
  statusSelected: boolean;
  awaitingReviewOptionSelected: boolean;
  locationSelected: boolean;
  photeSelected: boolean;
  emailSelected: boolean;
  firstNameSelected: boolean;
  lastNameSelected: boolean;
  eventDateRangeSelected: boolean;
}

type ApplyFiltersEventPropsMap = {
  [LEADS_FOLDER_EVENTS.leadsFolderSortAndFilterViewLeadsButtonClicked]: ApplyFiltersEventProperties;
  [LEADS_FOLDER_EVENTS.leadsFolderSortAndFilterCancelButtonClicked]: ApplyFiltersEventProperties;
};

export const GigIdSearchResult = {
  SUCCESSFUL: 'successfully',
  UNSUCCESSFUL: 'unsuccessfully (invalid GIG ID)',
} as const;

type SearchResultKey = keyof typeof GigIdSearchResult;
export type SearchResultValue = (typeof GigIdSearchResult)[SearchResultKey];

export interface SearchByGigIdProperties extends LeadFolderProperties {
  gigId: number;
  searchResult: SearchResultValue;
}

type SearchByGigIdPropsMap = {
  [LEADS_FOLDER_EVENTS.leadsFolderSearchByGigIdClicked]: SearchByGigIdProperties;
};
export type LeadsFolderEventPropsMap = {
  [LEADS_FOLDER_EVENTS.leadsFolderSortAndFilterButtonClicked]: LeadFolderProperties;
  [LEADS_FOLDER_EVENTS.leadsFolderSortAndFilterLocationFilterClicked]: LeadFolderProperties;
  [LEADS_FOLDER_EVENTS.leadsFolderSortAndFilterPhoneFilterUsed]: LeadFolderProperties;
  [LEADS_FOLDER_EVENTS.leadsFolderSortAndFilterEmailFilterUsed]: LeadFolderProperties;
  [LEADS_FOLDER_EVENTS.leadsFolderSortAndFilterFirstNameFilterUsed]: LeadFolderProperties;
  [LEADS_FOLDER_EVENTS.leadsFolderSortAndFilterLastNameFilterUsed]: LeadFolderProperties;
} & SortByEventPropsMap &
  StatusSelectedEventPropsMap &
  AwaitingReviewPropsMap &
  DateRangeSelectedPropsMap &
  ApplyFiltersEventPropsMap &
  SearchByGigIdPropsMap;
