import React from 'react';
import { UIContext } from 'context/global';

/**
 * @deprecated use either useSnackbar or useInstantFeedback
 * @returns {{ui: {isLoading, snackbar, instantFeedback}}}
 */
export const useGlobalUIState = () => React.useContext(UIContext);
/**
 * @deprecated use either useSnackbar or useInstantFeedback
 * @returns {{setSnackbar, setInstantFeedback, setIsLoading}}
 */
export const useGlobalUIDispatch = () => useGlobalUIState();
