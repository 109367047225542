export default {
  mobileStepper: 1000,
  speedDial: 1050,
  fab: 1050,
  appBar: 1100,
  integratedSnackbar: 1101,
  header: 1150,
  drawer: 1200,
  modal: 1300,
  tooltip: 1375,
  popover: 1400,
  snackbar: 1500,
} as const;
