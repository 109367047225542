import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import palette from 'theme/palette';

interface SwitchIconProps extends SvgIconProps {}

const SwitchIcon = (props: SwitchIconProps) => (
  <SvgIcon width="24" height="14" viewBox="0 0 24 14" fill="none" data-testid="svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 7C0 3.13401 3.13401 0 7 0H17C20.866 0 24 3.13401 24 7C24 10.866 20.866 14 17 14H7C3.13401 14 0 10.866 0 7Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.38398 6.375H17.5514C17.7274 6.375 17.8874 6.28209 17.9514 6.12723C18.0474 5.89495 17.9994 5.69364 17.8234 5.50782L14.2556 2.10102C14.1276 1.97713 13.9196 1.96165 13.7756 2.08553C13.6156 2.20942 13.6156 2.4417 13.7596 2.58107L17.0874 5.75558H6.35198C6.15999 5.75558 6 5.89495 6 6.08078C6.016 6.2666 6.17599 6.375 6.38398 6.375Z"
      fill={palette.common.black}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.616 7.625H6.44859C6.2726 7.625 6.1126 7.71758 6.04861 7.87189C5.95261 8.10336 6.00061 8.30396 6.1766 8.48913L9.74442 11.8993C9.87241 12.0228 10.0804 12.0382 10.2244 11.9148C10.3844 11.7913 10.3844 11.5599 10.2404 11.421L6.91256 8.24223H17.648C17.84 8.24223 18 8.10336 18 7.91819C17.984 7.73302 17.824 7.625 17.616 7.625Z"
      fill={palette.common.black}
    />
  </SvgIcon>
);

export default SwitchIcon;
