import every from 'lodash/every';
import isEmpty from 'lodash/isEmpty';
import forEach from 'lodash/forEach';
import isEqual from 'lodash/isEqual';
import without from 'lodash/without';
import pick from 'lodash/pick';
import orderBy from 'lodash/orderBy';
import { gmConfig } from 'config/index';
import { ChangeEvent } from 'react';
import { CropParams, RotationParams } from 'api/photo';

interface ScrollIntoViewOptions {
  block?: ScrollLogicalPosition;
  behavior?: ScrollBehavior;
}

export const verticalScroll = (
  selectors: string,
  scrollIntoViewOptions: ScrollIntoViewOptions = { block: 'start', behavior: 'smooth' }
): void => {
  const el = document.querySelector(selectors);
  if (!el) return;

  el.scrollIntoView(scrollIntoViewOptions);
};

interface FormatOptions extends RotationParams, CropParams {
  resizeWidth?: number;
  resizeHeight?: number;
}

const generateMediaImageQueryParams = (format: FormatOptions = {}) => {
  const queryParams: Record<string, string> = {};
  const croppedPoints = {
    cropLeft: format.cropLeft || 0,
    cropTop: format.cropTop || 0,
    cropRight: format.cropRight || 0,
    cropBottom: format.cropBottom || 0,
  };

  if (format.resizeWidth) {
    queryParams.rs = `${format.resizeWidth}.h`;
  }
  if (format.resizeHeight) {
    queryParams.rs = `w.${format.resizeHeight}`;
  }
  if (!every(croppedPoints, (el) => el === 0)) {
    queryParams.cr = `${croppedPoints.cropLeft}.${croppedPoints.cropTop}.${croppedPoints.cropRight}.${croppedPoints.cropBottom}`;
  }
  if (format.rotation) {
    queryParams.rt = format.rotation.toString();
  }

  return queryParams;
};

export const generateMediaImageUrl = (id: string, format: FormatOptions = {}) => {
  const imageUrl = `${gmConfig.domains.mediaApi}/images/${id}`;
  const queryParams = generateMediaImageQueryParams(format);

  if (isEmpty(queryParams)) {
    return imageUrl;
  }

  const querystring = new URLSearchParams(queryParams)
    .toString()
    .replace(/&/g, '-')
    .replace(/=/g, '_');

  return `${imageUrl}~${querystring}`;
};

export const isEqualBy = <T>(
  array: T[] | null | undefined,
  other: T[] | null | undefined,
  pickKeys: Array<keyof T>,
  sortBy?: Array<keyof T>
): boolean => {
  let a = array || [];
  let b = other || [];

  if (sortBy) {
    a = orderBy(a, sortBy);
    b = orderBy(b, sortBy);
  }

  if (a.length !== b.length) return false;

  let result = true;

  forEach(a, (item, index) => {
    if (!isEqual(pick(item, pickKeys), pick(b[index], pickKeys))) {
      result = false;
      return false;
    }
  });

  return result;
};

export const getCaseInsensitiveQuery = (query: string) => {
  const queryObject: { [key: string]: string } = {};
  const searchParams = new URLSearchParams(query);
  // eslint-disable-next-line no-restricted-syntax
  for (const [name, value] of searchParams) {
    queryObject[name.toLowerCase()] = value;
  }

  return queryObject;
};

export const delay = (milliseconds: number) =>
  new Promise((resolve) => {
    setTimeout(resolve, milliseconds);
  });

export const trimCssUnit = (value: string) => Number(value.replace(/[^.|^\d]/g, '')) || 0;

export const formatPhone = (value: string | null = '', interval = ' ') => {
  const inputValue = value || '';
  if (!/^[\d|\s]*$/.test(inputValue)) {
    return inputValue.substring(0, 12);
  }
  if (/^(\d{3}\s+){1,2}$/g.test(inputValue)) {
    return inputValue.replace(/\s{2,}/g, ' ');
  }

  const matches = /^(\d{3})(\d{1,3})(\d{1,4})?$/g.exec(
    inputValue.replace(/\s+/g, '').substring(0, 10)
  );

  if (matches) {
    return without([matches[1], matches[2], matches[3]], undefined).join(interval);
  }
  return inputValue.replace(/\s+/g, '').substring(0, 12);
};

/**
 * Shortens a number to a more readable string format.
 *
 * - Numbers greater than or equal to 1,000,000 are shortened to "XM" (e.g., 1,500,000 becomes "1M").
 * - Numbers greater than or equal to 10,000 are shortened to "XK" (e.g., 15,000 becomes "15K").
 * - Numbers less than 10,000 are returned as is, formatted with commas.
 *
 * @param {number} number - The number to shorten.
 * @returns {string} The shortened string representation of the number.
 */
export const shortenNumberToString = (number: number): string => {
  let num;
  const sign = Math.sign(number);
  const abs = Math.abs(number);
  if (abs >= 1000000) {
    num = `${(sign * Math.trunc(abs / 1000000)).toLocaleString()}M`;
  } else if (abs >= 10000) {
    num = `${(sign * Math.trunc(abs / 1000)).toLocaleString()}K`;
  } else {
    num = number.toLocaleString();
  }

  return num;
};

export const inputOnlyNumericFormat = (
  event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  { length = 0 }: { length: number }
) => {
  // eslint-disable-next-line no-param-reassign
  event.target.value = event.target.value.replace(/[^\d]*/g, '');

  const cursorPosition = event.target.selectionStart;

  while (event.target.value.length > length) {
    // eslint-disable-next-line no-param-reassign
    event.target.value = event.target.value.slice(0, event.target.value.length - 1);
  }

  event.target.setSelectionRange(cursorPosition, cursorPosition);

  return event;
};

export const getImageResolution = (src: string) =>
  new Promise((resolve, reject) => {
    try {
      const img = new Image();
      img.onload = () =>
        resolve({
          width: img.width,
          height: img.height,
        });
      img.src = src;
    } catch (e) {
      reject(e);
    }
  });

export const toMonthName = (monthNumber: number) => {
  const date = new Date();
  date.setDate(1);
  date.setMonth(monthNumber - 1);

  return date.toLocaleString('en-US', {
    month: 'long',
  });
};

export const removeInvalidCharacters = (text: string) => {
  if (!text) {
    return '';
  }
  return text.replace(/[^`\-=[\]\\;',./~!@#$%^&*()_+{}|:"<>?\dA-Za-z\s]/g, '');
};

interface ShiftByCallback<T> {
  (value: T, index: number, array: T[]): boolean;
}

export const shiftBy = <T>(array: T[], callback: ShiftByCallback<T>): T | undefined => {
  const foundIndex = array.findIndex(callback);
  const found = array[foundIndex];
  if (foundIndex >= 0) array.splice(foundIndex, 1);

  return found;
};

export const numberToOrdinal = (numb: number) => {
  let ord = 'th';
  if (numb % 10 === 1 && numb % 100 !== 11) {
    ord = 'st';
  } else if (numb % 10 === 2 && numb % 100 !== 12) {
    ord = 'nd';
  } else if (numb % 10 === 3 && numb % 100 !== 13) {
    ord = 'rd';
  }

  return `${numb}${ord}`;
};

export const formatCamelCase = (label: string) =>
  label
    .split(/\s+/)
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join('');

/**
 * Checks if a value is defined and not empty.
 * - For strings, checks that it's not an empty string.
 * - For arrays, checks that it has at least one item.
 * - For other values, ensures it's neither `null` nor `undefined`.
 *
 * @param value - The value to check.
 * @returns `true` if the value is defined and not empty, otherwise `false`.
 */
export const isDefinedAndNotEmpty = (value: unknown): boolean =>
  value !== null &&
  value !== undefined &&
  (typeof value !== 'string' || value !== '') &&
  (!Array.isArray(value) || value.length > 0);

export default {
  verticalScroll,
  generateMediaImageUrl,
  isEqualBy,
  getCaseInsensitiveQuery,
  delay,
  trimCssUnit,
  formatPhone,
  shortenNumberToString,
  inputOnlyNumericFormat,
  getImageResolution,
  removeInvalidCharacters,
  formatCamelCase,
  isDefinedAndNotEmpty,
};
