import { CancelToken } from 'axios';
import { vendorAPIRequest, gigMasterAPIRequest } from 'api/index';
import { LegacyId, VendorId } from 'types/vendor';
import {
  Alert,
  LegacyVendor,
  MemberSelectionVendor,
  RelatedProfile,
  SearchResults,
  UpdateVendorStripeAccountResponse,
  VendorFromAPI,
  VendorFromLegacyApi,
} from './types/vendor';
import { MediaStatsAPIModel } from './types/media';

interface GetByIdParams {
  vendorId: VendorId;
}

interface UpdateByIdParams {
  vendorId: VendorId;
}

interface GetVendorRankingParams {
  legacyId: LegacyId;
  category: string;
  location: string;
  isSingleSearchPage: boolean;
}

interface UpdateVendorStripeAccountParams {
  vendorId: VendorId;
}

interface GetNotificationsByVendorIdWithTypeParams {
  vendorId: VendorId;
  type: string;
}

interface CreateNotificationParams {
  vendorId: VendorId;
  type: string;
}

interface GetGPTDescriptionParams {
  vendorId: VendorId;
  tone: string;
  description: string;
}

export interface Notification {
  vendorId: string;
  type: string;
  createdAt: string;
}

export interface GetStatsByLegacyIdAPI {
  lifeTimeEarnings: number;
  lastYearsEarnings: number;
  leadsReceived: number;
  quotesSent: number;
  leadsDeclined: number;
  responseRate: number;
  leadResponseTime: number;
  siteAverage: number;
  bookings: number;
  profileViews: number;
  searchAppearances: number;
  overallRating: number;
  totalReviews: number;
  leadsResponded: number;
}
const getById = ({ vendorId }: GetByIdParams) =>
  vendorAPIRequest.get<VendorFromAPI>(`/vendors/${vendorId}`);

const getByIdFromLegacy = ({ legacyId }: { legacyId: LegacyId }) =>
  gigMasterAPIRequest.get<VendorFromLegacyApi>(`/api/v1/members/${legacyId}/profile`);

const updateById = ({ vendorId, ...data }: UpdateByIdParams) =>
  vendorAPIRequest.put<void>(`/vendors/${vendorId}`, data);

const getAlertsByLegacyId = (legacyId: LegacyId) =>
  gigMasterAPIRequest.get<Alert[]>(`api/v1/members/${legacyId}/alerts`);

const dismissAlert = (legacyId: LegacyId, alertId: number, gigId: number) =>
  gigMasterAPIRequest.delete<void>(`api/v1/members/${legacyId}/alerts/${alertId}?gigId=${gigId}`);

const getByProfileName = (profileName: string, axiosCancelToken: CancelToken) =>
  vendorAPIRequest.get<RelatedProfile>('vendors/related-vendors', {
    params: { profileName },
    cancelToken: axiosCancelToken,
  });

const getListByBearerToken = () =>
  gigMasterAPIRequest.get<MemberSelectionVendor>('/api/v1/accounts/member-selection');

const getStatsByLegacyId = ({ legacyId }: { legacyId: LegacyId }) =>
  gigMasterAPIRequest.get<GetStatsByLegacyIdAPI>(`/api/v1/members/${legacyId}/stats`);

const removeByVendorId = (vendorId: VendorId) =>
  vendorAPIRequest.put<void>(`/vendors/${vendorId}/discontinued`);

const validateEmail = (email: string) =>
  vendorAPIRequest.get<{ isValid: boolean }>(`/vendors/validation/email/${email}`);

const getLegacyVendorById = ({
  legacyId,
  load = 'PrimaryServiceType,providedservicetypes',
}: {
  legacyId: LegacyId;
  load?: string;
}) => gigMasterAPIRequest.get<LegacyVendor>(`/api/v1/members/${legacyId}?load=${load}`);

const getVendorRanking = ({
  legacyId,
  category,
  location,
  isSingleSearchPage,
}: GetVendorRankingParams) =>
  gigMasterAPIRequest.get<{
    searchResults: SearchResults[];
  }>(
    `/api/v1/members/${legacyId}/searchanalyzer/${category}${isSingleSearchPage ? `?location=${location}` : `-${location}`}`
  );

const getMediaStatsByLegacyId = (legacyId: LegacyId) =>
  gigMasterAPIRequest.get<MediaStatsAPIModel>(`/api/v1/members/${legacyId}/media-stats`);

const updateVendorStripeAccount = ({ vendorId, ...data }: UpdateVendorStripeAccountParams) =>
  vendorAPIRequest.put<UpdateVendorStripeAccountResponse>(
    `/vendors/${vendorId}/stripe-accounts`,
    data
  );

const getNotificationsByVendorIdWithType = ({
  vendorId,
  type,
}: GetNotificationsByVendorIdWithTypeParams) =>
  vendorAPIRequest.get<Notification[]>(`/vendors/${vendorId}/notifications?type=${type}`);

const createNotification = ({ vendorId, type }: CreateNotificationParams) =>
  vendorAPIRequest.post<void>(`/vendors/${vendorId}/notifications`, {
    type,
  });

const getGPTDescription = ({ vendorId, tone, description }: GetGPTDescriptionParams) =>
  vendorAPIRequest.post<{ description: string }>(`/vendors/${vendorId}/ai-description`, {
    tone,
    description,
  });

export default {
  getById,
  getByIdFromLegacy,
  updateById,
  getAlertsByLegacyId,
  dismissAlert,
  getByProfileName,
  getListByBearerToken,
  getStatsByLegacyId,
  removeByVendorId,
  validateEmail,
  getMediaStatsByLegacyId,
  getLegacyVendorById,
  getVendorRanking,
  updateVendorStripeAccount,
  getNotificationsByVendorIdWithType,
  createNotification,
  getGPTDescription,
};
