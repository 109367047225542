import { LeadStatusKey, ReviewStatusKey, STATUS_TO_UI_MAPPING } from 'constants/leads';
import mapValues from 'lodash/mapValues';

export const LEADS_SORT_BY_FIELD = {
  dateCreated: '0',
  newestToOldestEvent: '2',
  oldestToNewestEvent: '3',
} as const;

export type LeadsSortByFieldKey = keyof typeof LEADS_SORT_BY_FIELD;
export type LeadsSortByFieldValue = (typeof LEADS_SORT_BY_FIELD)[LeadsSortByFieldKey];

export const LEADS_DEFAULT_FOLDERS = {
  leadsInbox: 'Leads Inbox',
  trash: 'Trash',
} as const;

export type LeadsDefaultFoldersKey = keyof typeof LEADS_DEFAULT_FOLDERS;
export type LeadsDefaultFoldersValue = (typeof LEADS_DEFAULT_FOLDERS)[LeadsDefaultFoldersKey];

export interface LeadsSortBySelectItem {
  label: string;
  value: LeadsSortByFieldValue;
}

export const LEADS_SORT_BY_SELECT_ITEMS: LeadsSortBySelectItem[] = [
  { label: 'Date created (default)', value: '0' },
  { label: 'Newest to oldest event', value: '2' },
  { label: 'Oldest to newest event', value: '3' },
];

export interface LeadStatusSelectItem {
  label: string;
  value: LeadStatusKey;
}

export interface ReviewStatusItem {
  value: ReviewStatusKey;
  label: string;
}

export const LEADS_STATUS_SELECT_ITEMS: LeadStatusSelectItem[] = [
  { value: 'unanswered', label: 'Unanswered' },
  { value: 'booked', label: 'Booked' },
  { value: 'declined', label: 'Declined' },
  { value: 'quoteSent', label: 'Quote sent' },
  { value: 'quoteExpired', label: 'Quote expired' },
  { value: 'pendingPayment', label: 'Pending booking' },
  { value: 'inactive', label: 'Inactive' },
];

export const AWAITING_REVIEW_STATUS: ReviewStatusItem = {
  value: 'awaitingReview',
  label: 'Awaiting review',
};

export const REVIEW_STATUS_ITEMS: ReviewStatusItem[] = [
  { value: 'futureDate', label: 'Future date' },
  { value: 'awaitingReview', label: 'Awaiting review' },
  AWAITING_REVIEW_STATUS,
];

export const PILL_LABEL = {
  sortBy: LEADS_SORT_BY_SELECT_ITEMS.reduce(
    (previousValue, currentValue) => ({
      ...previousValue,
      [currentValue.value]: currentValue.label,
    }),
    {}
  ) as Record<LeadsSortByFieldValue, string>,
  status: mapValues(STATUS_TO_UI_MAPPING, (value) => value.label),
};

export const LEADS_COUNT_ON_CURRENT_PAGE = 25;

export const LEADS_XLSX_OPTION = {
  headers: [
    'Gig ID',
    'Event date',
    'Client name',
    'Client email	',
    'Client phone',
    'Location	',
    'Status	',
    'Response sent date',
    'Quote seen date',
    'Booking amount',
    'Additional information',
  ],
  colWidths: [
    { wch: 10 },
    { wch: 15 },
    { wch: 20 },
    { wch: 35 },
    { wch: 15 },
    { wch: 30 },
    { wch: 15 },
    { wch: 18 },
    { wch: 18 },
    { wch: 15 },
    { wch: 40 },
  ],
};

export const GENERAL_INFO_XLSX_OPTION = {
  colWidths: [{ wch: 20 }, { wch: 50 }, { wch: 50 }],
};

export const LEADS_XLSX_FILENAME_PREFIX = 'The Bash Leads - Member';
