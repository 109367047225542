// As this file is imported from the service worker, we must use relative import paths
import { LOCAL_ENV_NAME, LOCAL_ENV_LOGIN_PATH } from '../constants/localEnv';

export const isProduction = process.env.VITE_APP_ENV === 'production';

export const env = process.env.VITE_APP_ENV;

export const gmConfig = {
  auth: {
    loginRoute:
      env === LOCAL_ENV_NAME
        ? `${process.env.VITE_APP_MCP_DOMAIN}${LOCAL_ENV_LOGIN_PATH}`
        : `${process.env.VITE_APP_WEB_DOMAIN}/login`,
    client: {
      id: process.env.VITE_APP_AUTH_CLIENT_ID,
      secret: process.env.VITE_APP_AUTH_CLIENT_SECRET,
    },
  },
  domains: {
    www: process.env.VITE_APP_WEB_DOMAIN,
    mcp: process.env.VITE_APP_MCP_DOMAIN,
    gmApi: process.env.VITE_APP_GM_API_DOMAIN,
    vendorApi: process.env.VITE_APP_VENDOR_API_DOMAIN,
    mediaApi: process.env.VITE_APP_MEDIA_API_DOMAIN,
    cdn: process.env.VITE_APP_CDN_DOMAIN,
    s3: process.env.VITE_APP_S3_DOMAIN,
    stripeFile: process.env.VITE_APP_STRIPE_FILE_DOMAIN,
  },
  cookie: {
    domain: process.env.VITE_APP_COOKIE_DOMAIN,
    prefix: process.env.VITE_APP_COOKIE_PREFIX,
  },
  email: {
    contactInfo: {
      templateId: 'd-372f96f96537401caad4aaab286a0ff5',
      fromName: 'The Bash',
      fromEmail: 'info@thebash.com',
      emailSubject: 'emailSubject',
      categories: ['ContactInfoChangedEmail', 'Member'],
    },
    referralBonus: {
      templateId: 'd-b7a301fb7da84881b242ca5cd4c9a83b',
      fromName: 'The Bash',
      fromEmail: 'info@thebash.com',
      emailSubject: 'Referral Bonus Email',
      categories: ['ReferralBonusEmail'],
    },
    eventPay: {
      templateId: 'd-838b952cddc74af4adbd3426b48f54b7',
      fromName: 'The Bash',
      fromEmail: 'info@thebash.com',
      emailSubject: 'Event Pay Email',
      categories: ['Member', 'EventpayInfoChangedEmail'],
    },
  },
  stripe: {
    publishableKey: process.env.VITE_APP_STRIPE_PUBLISHABLE_KEY,
  },
  notification: {
    webPushKey: process.env.VITE_APP_WEB_PUSH_PUBLIC_KEY,
  },
  honeyBadger: {
    apiKey: process.env.VITE_APP_HONEYBADGER_API_KEY,
    environment: env === LOCAL_ENV_NAME ? 'development' : env,
  },
  featureFlags: {
    mock: process.env.VITE_APP_MOCK_FEATURE_FLAGS === 'true',
    unleash: {
      url: process.env.VITE_APP_UNLEASH_URL,
      clientKey: process.env.VITE_APP_UNLEASH_CLIENT_KEY,
      appName: 'the-bash-mcp',
      options: {
        refreshInterval: 30,
      },
    },
  },
};
