import { LeadDetailBaseEventProperties } from './leadDetail';

export const MESSAGES_EVENTS = {
  leadDetailMessagesButtonClicked: 'Lead Detail Messages Button Clicked',
  messagesSendButtonClickSuccessfully: 'Messages Send Button Clicked Ok',
  messagesViewMoreLeadDetailsButtonClick: 'Messages View More Lead Details Button Clicked',
} as const;

export interface MessagesBaseProperties extends LeadDetailBaseEventProperties {
  hasUnreadMessages: boolean;
  messagesLength: number;
  serviceType: string;
  eventType: string;
  serviceLength: string;
  location: string;
}

export interface MessagesWithRestrictionsProperties extends MessagesBaseProperties {
  hasRestrictions: boolean;
}

export interface MessageSentProperties extends MessagesWithRestrictionsProperties {
  isAnswer: boolean;
  answeringTime?: number;
}

export type MessagesEventPropsMap = {
  [MESSAGES_EVENTS.leadDetailMessagesButtonClicked]: MessagesBaseProperties;
  [MESSAGES_EVENTS.messagesSendButtonClickSuccessfully]: MessageSentProperties;
  [MESSAGES_EVENTS.messagesViewMoreLeadDetailsButtonClick]: MessagesWithRestrictionsProperties;
};
