import { Theme } from '@mui/material';

const styles = {
  closeButton: ({ theme }: { theme: Theme }) => ({
    position: 'absolute',
    right: '6px',
    top: '8px',
    color: theme.palette.grey[900],
  }),
};

export default styles;
