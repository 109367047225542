import cookieHelper from 'helpers/cookieHelper';
import { redirectToLoginPage, refreshTokens } from 'helpers/auth';
import {
  REFRESH_TOKEN_COOKIE_NAME,
  REFRESH_BEARER_TOKEN_RETRY_COUNT,
  BEARER_TOKEN_COOKIE_NAME,
} from 'constants/storage';
import { trackEvent, EVENTS } from 'helpers/tracking';
import { AxiosError, AxiosInstance, AxiosRequestConfig } from 'axios';

export interface CustomAxiosRequestConfig extends AxiosRequestConfig {
  retriedCount: number;
}

export const requestUnauthorizedHandler = async (
  originalInstance: AxiosInstance,
  error: AxiosError
) => {
  const refreshToken = cookieHelper.get(REFRESH_TOKEN_COOKIE_NAME);
  const bearerToken = cookieHelper.get(BEARER_TOKEN_COOKIE_NAME);
  if (!refreshToken || !bearerToken) {
    redirectToLoginPage();
    throw new Error('Unauthorized');
  }
  const originalRequestConfig = error.config as CustomAxiosRequestConfig;

  if (originalRequestConfig.retriedCount >= REFRESH_BEARER_TOKEN_RETRY_COUNT) {
    redirectToLoginPage();
    throw new Error('Unauthorized');
  } else {
    originalRequestConfig.retriedCount = originalRequestConfig.retriedCount || 0;
    originalRequestConfig.retriedCount += 1;
    try {
      await refreshTokens();
    } catch (e) {
      trackEvent(EVENTS.authRefreshTokenFailure, {});
      // For retrying, not to redirect when calling refreshTokens failed
    }
    return originalInstance(originalRequestConfig);
  }
};
