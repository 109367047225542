import { Theme } from '@mui/material';

const styles = {
  linkBox: (theme: Theme) => ({
    height: '44px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0px 32px 0px 20px',
    paddingLeft: '42px',
    '& .MuiTypography-root': {
      color: theme.palette.grey[300],
    },
    '& .MuiTypography-root:hover': {
      color: theme.palette.blue[100],
    },
  }),
  routeLink: (theme: Theme) => ({
    paddingLeft: '22px',
    borderLeft: `1px solid ${theme.palette.grey[600]}`,
    alignItems: 'center',
    height: 'inherit',
  }),
  expandIcon: {
    marginLeft: '10px',
  },
  unansweredBox: (theme: Theme) => ({
    [theme.breakpoints.down('md')]: {
      display: 'flex',
      flexDirection: 'column',
      paddingTop: '2px',
    },
  }),
  unanswered: (theme: Theme) => ({
    padding: '0px 8px',
    marginLeft: '10px',
    backgroundColor: theme.palette.blue[400],
    borderRadius: '20px',
    ...theme.typography.caption,
    color: theme.palette.primary.contrastText,
  }),
};

export default styles;
