const styles = {
  loaderContainer: {
    position: 'relative',
    overflow: 'hidden',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  maskBox: {
    filter: 'blur(2.5px)',
    opacity: 0.3,
    width: '100%',
  },
  isLoading: {
    display: 'none',
  },
};

export default styles;
