import React from 'react';
import classNames from 'classnames';
import { useLocation } from 'react-router-dom';
import { styled, SnackbarProps } from '@mui/material';

import LinkButton from 'components/Form/LinkButton';
import ActionSnackbar from 'components/ActionSnackbar';
import { useGlobalUIState } from 'hooks/useGlobalUI';
import { classes, styles } from './styles';

const StyledActionSnackbar = styled(ActionSnackbar)(styles.styledRoot);

export const InstantFeedbackSnackbar = () => {
  const {
    ui: { instantFeedback },
    setInstantFeedback,
  } = useGlobalUIState();
  const {
    'data-testid': dataTestId,
    message,
    actionFunc,
    actionText,
    data,
    notWithStickyBottomBar = false,
    hideOnLeavePage = true,
    key,
    ...snackbarProps
  } = instantFeedback;

  const { pathname } = useLocation();

  React.useEffect(() => {
    if (!hideOnLeavePage) return;
    setInstantFeedback({ open: false, message: '' });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const onClose: SnackbarProps['onClose'] = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setInstantFeedback({ open: false, message: '' });
  };

  return (
    <StyledActionSnackbar
      onClose={onClose}
      {...snackbarProps}
      key={key}
      className={classNames(notWithStickyBottomBar && classes.notWithStickyBottomBar)}
      message={
        <>
          {message}
          {actionFunc && (
            <>
              {` `}
              <span className={classes.actionRoot}>
                <LinkButton
                  variant="body2"
                  size="small"
                  color="secondary"
                  onClick={() => actionFunc(data)}
                  underline="always"
                  data-testid={dataTestId ? `button-${dataTestId}` : undefined}
                >
                  {actionText}
                </LinkButton>
              </span>
            </>
          )}
        </>
      }
    />
  );
};

export default InstantFeedbackSnackbar;
