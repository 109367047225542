import axios, { AxiosError } from 'axios';
import isEmpty from 'lodash/isEmpty';
import { ErrorResponse } from '../types';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const mapAxiosErrorToErrorResponse = (error: AxiosError<any>): ErrorResponse => {
  const response: ErrorResponse = {
    name: error.name,
    message: error.message,
    details: error.response?.data.details,
    response: error.response,
    originalError: error,
  };

  if (error.response) {
    response.statusCode = error.response.status;
    response.message = error.response.data.message;

    if (!isEmpty(error.response.data.validation)) {
      // eslint-disable-next-line prefer-destructuring
      response.validationKey = error.response.data.validation.keys[0];
    }

    if (!isEmpty(error.response.data.errors)) {
      response.validationKey = error.response.data.errors?.[0]?.name;
    }
  }

  return response;
};

const mapGenericErrorToErrorResponse = (error: Error): ErrorResponse => ({
  name: error.name,
  message: error.message,
  originalError: error,
});

const createUnknownErrorResponse = (error: unknown): ErrorResponse => ({
  name: 'UnknownError',
  message: 'An unknown error occurred',
  originalError: error,
});

export const createErrorResponse = (error: unknown): ErrorResponse => {
  if (axios.isAxiosError(error)) {
    return mapAxiosErrorToErrorResponse(error);
  }

  if (error instanceof Error) {
    return mapGenericErrorToErrorResponse(error);
  }

  return createUnknownErrorResponse(error);
};
