import React from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import reject from 'lodash/reject';
import { styled } from '@mui/material';
import Collapse from '@mui/material/Collapse';
import Box from '@mui/material/Box';
import { withGlobalContext } from 'hoc/withGlobalContext';
import Snackbar from './Snackbar';
import { classes, styles } from './styles';

const StyledRootBox = styled(Box)(styles.styledRoot);

export const SnackbarList = ({ snackbarState }) => {
  const [snackbars, setSnackbars] = React.useState([]);

  React.useEffect(() => {
    if (snackbarState.value.open) {
      setSnackbars((prev) => [snackbarState.value, ...prev]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [snackbarState.value]);

  const handleExited = (alert) => {
    setSnackbars(reject(snackbars, alert));
    if (snackbarState.value.key === alert.key) {
      snackbarState.set({ open: false });
    }
  };

  return (
    !isEmpty(snackbars) && (
      <StyledRootBox>
        {snackbars.map((snackbarProps) => (
          <Snackbar
            {...snackbarProps}
            className={classes.snackbarRoot}
            key={snackbarProps.key}
            onClose={() => handleExited({ key: snackbarProps.key })}
            content={snackbarProps.content}
            TransitionComponent={Collapse}
            anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
          />
        ))}
      </StyledRootBox>
    )
  );
};

SnackbarList.propTypes = {
  snackbarState: PropTypes.shape({
    set: PropTypes.func.isRequired,
    value: PropTypes.shape({
      open: PropTypes.bool,
      key: PropTypes.string,
    }),
  }),
};

SnackbarList.defaultProps = {
  snackbarState: { set: () => {}, value: {} },
};

export const mapStateToProps = ({ ui: { snackbarState } }) => ({
  snackbarState,
});

const ContextSnackbarList = withGlobalContext(mapStateToProps)(React.memo(SnackbarList));

ContextSnackbarList.displayName = 'ContextSnackbarList';

export default ContextSnackbarList;
