import { combineReducers } from 'redux';
import {
  localStorageReducer,
  locationsReducer,
  searchLookupsReducer,
  pageLevelBasePropertiesReducer,
} from '@tkww/the-bash-frontend';

const combinedReducer = combineReducers({
  localStorage: localStorageReducer,
  locations: locationsReducer,
  pageLevelBaseProperties: pageLevelBasePropertiesReducer,
  searchLookups: searchLookupsReducer,
});

export default combinedReducer;
