import { toDate, formatInTimeZone } from 'date-fns-tz';

export const unifyTimestampToString = (time: string, parseFormat?: string) =>
  time.charAt(time.length - 1) === 'Z'
    ? formatInTimeZone(new Date(time), 'America/New_York', parseFormat || 'yyyy-MM-dd HH:mm:ssXXX')
    : formatInTimeZone(
        toDate(time, { timeZone: 'America/New_York' }),
        'America/New_York',
        parseFormat || 'yyyy-MM-dd HH:mm:ssXXX'
      );

export const unifyTimestampToDate = (time: string) =>
  toDate(time, { timeZone: 'America/New_York' });
