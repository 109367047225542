import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import palette from 'theme/palette';

interface CloseIconProps extends SvgIconProps {}

const CloseIcon = (props: CloseIconProps) => (
  <SvgIcon viewBox="0 0 32 32" data-testid="svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32Z"
      fill={palette.grey[100]}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.21159 9.1318C9.49276 8.85062 9.94864 8.85062 10.2298 9.1318L22.788 21.69C23.0692 21.9712 23.0692 22.4271 22.788 22.7082C22.5069 22.9894 22.051 22.9894 21.7698 22.7082L9.21159 10.15C8.93041 9.86885 8.93041 9.41298 9.21159 9.1318Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.21224 22.7082C8.93106 22.427 8.93106 21.9712 9.21224 21.69L21.7705 9.13176C22.0516 8.85059 22.5075 8.85059 22.7887 9.13176C23.0699 9.41294 23.0699 9.86882 22.7887 10.15L10.2305 22.7082C9.9493 22.9894 9.49342 22.9894 9.21224 22.7082Z"
    />
  </SvgIcon>
);

export default CloseIcon;
