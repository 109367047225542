import { Theme } from '@mui/material';

const PREFIX = 'component-InstantFeedbackSnackbar';

export const classes = {
  notWithStickyBottomBar: `${PREFIX}-notWithStickyBottomBar`,
  actionRoot: `${PREFIX}-actionRoot`,
};

export const styles = {
  styledRoot: ({ theme }: { theme: Theme }) => ({
    [theme.breakpoints.down('sm')]: {
      bottom: '104px',

      [`&.${classes.notWithStickyBottomBar}`]: {
        bottom: '16px',
      },
    },

    [theme.breakpoints.up('sm')]: {
      bottom: '96px',

      [`&.${classes.notWithStickyBottomBar}`]: {
        bottom: '16px',
      },
    },

    [theme.breakpoints.up('md')]: {
      bottom: '96px',

      [`&.${classes.notWithStickyBottomBar}`]: {
        bottom: '16px',
      },
    },

    [`& .${classes.actionRoot}`]: {
      display: 'inline-flex',
    },
    '& .MuiLink-root': {
      ...theme.typography.body2,
      color: theme.palette.blue['300'],
      '&:hover': {
        color: theme.palette.blue['300'],
      },
    },
  }),
};
