import { createStore, applyMiddleware, Middleware } from 'redux';
import createSagaMiddleWare from 'redux-saga';
import { createTracker } from 'redux-segment';
import { gmConfig } from 'config/index';
import reducer from 'redux/reducer';
import rootSaga from './rootSaga';

const sagaMiddleware = createSagaMiddleWare({
  context: {
    applicationName: 'mcp',
    cookiePrefix: gmConfig.cookie.prefix,
    cookieDomain: gmConfig.cookie.domain,
  },
});

const bindMiddleware = (middleware: Middleware[]) => applyMiddleware(...middleware);

const store = createStore(reducer, bindMiddleware([sagaMiddleware, createTracker()]));

sagaMiddleware.run(rootSaga);

export default store;
