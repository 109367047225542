import React from 'react';

import { Typography, Link, Box, BoxProps } from '@mui/material';

import styles from './styles';

const DefaultErrorMessage = () => (
  <>
    Sorry! We&apos;re having trouble displaying this information. <br /> Please{' '}
    <Link
      href="#"
      onClick={(e) => {
        e.preventDefault();
        window.location.reload();
      }}
    >
      refresh the page
    </Link>{' '}
    to try again.
  </>
);

interface LoaderErrorMessageProps extends BoxProps {
  errorMessage?: React.ReactNode;
  'data-testid'?: string;
}

const LoaderErrorMessage = ({
  errorMessage,
  'data-testid': dataTestid = 'LoaderError',
  ...otherProps
}: LoaderErrorMessageProps) => (
  <Box sx={styles.errorContainer} {...otherProps}>
    <Typography data-testid={dataTestid} variant="subtitle2" sx={styles.errorContext}>
      {errorMessage || <DefaultErrorMessage />}
    </Typography>
  </Box>
);

export default LoaderErrorMessage;
