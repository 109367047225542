import { LeadDetailBaseEventProperties } from './leadDetail';

export const BOOK_NOW_EVENTS = {
  leadDetailBookNowButtonClicked: 'Lead Detail Book Now Button Clicked',
  bookNowCancelButtonClicked: 'Book Now Cancel Button Clicked',
  bookNowConfirmBookingButtonClickedSuccessfully: 'Book Now Confirm Booking Button Clicked Ok',
  bookNowConfirmBookingButtonClickedUnsuccessfully: 'Book Now Confirm Booking Button Clicked Fail',
} as const;

export interface BookNowEventProperties {
  depositAlreadyPaid: boolean;
  balanceAlreadyPaid: boolean;
  blackoutDate: boolean;
  confirmedWithClient: boolean;
}

export type BookNowEventPropsMap = {
  [BOOK_NOW_EVENTS.leadDetailBookNowButtonClicked]: LeadDetailBaseEventProperties;
  [BOOK_NOW_EVENTS.bookNowCancelButtonClicked]: LeadDetailBaseEventProperties &
    BookNowEventProperties;
  [BOOK_NOW_EVENTS.bookNowConfirmBookingButtonClickedSuccessfully]: LeadDetailBaseEventProperties &
    BookNowEventProperties & {
      paymentType: string;
    };
  [BOOK_NOW_EVENTS.bookNowConfirmBookingButtonClickedUnsuccessfully]: LeadDetailBaseEventProperties &
    BookNowEventProperties & {
      paymentType: string;
      validationErrors: Record<string, string>;
    };
};
