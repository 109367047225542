import leadsFolderApi from 'api/leadsFolder';
import { DEFAULT_PERFORMER_CONTEXT } from 'constants/storage';
import cookieHelper from 'helpers/cookieHelper';
import useRequest from 'hooks/useRequest';
import { isEqual } from 'lodash';
import find from 'lodash/find';
import isEmpty from 'lodash/isEmpty';
import React, { createContext } from 'react';
import { noop } from 'utils/function';
import { LEADS_DEFAULT_FOLDERS } from 'views/Leads/constant';
import { FoldersSummaryAPIModel } from '../api/types/leadsFolder';

export interface LeadsFolder {
  id: number;
  name: string;
}

export interface LeadsFolderContextValue {
  leadsFolders: LeadsFolder[];
  isLoading: boolean;
  isError: boolean;
  refreshLeadsFolder: () => void;
  inboxFolderId?: number;
  trashFolderId?: number;
}

const initState: LeadsFolderContextValue = {
  leadsFolders: [],
  isLoading: true,
  isError: false,
  refreshLeadsFolder: noop,
  inboxFolderId: undefined,
  trashFolderId: undefined,
};

const GetLeadsFoldersFetches = {
  init: 'init',
  refresh: 'refresh',
} as const;

export type GetLeadsFoldersFetchesKey = keyof typeof GetLeadsFoldersFetches;
export type GetLeadsFoldersFetchesValue =
  (typeof GetLeadsFoldersFetches)[GetLeadsFoldersFetchesKey];

const Context = createContext<LeadsFolderContextValue>(initState);

export const LeadsFolderContextProvider = ({ children }: React.PropsWithChildren) => {
  const legacyId = cookieHelper.get(DEFAULT_PERFORMER_CONTEXT);

  const {
    response,
    fetches,
    exec: refresh,
    isError,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  }: any = useRequest(leadsFolderApi.getLeadsFolder, {
    manual: !legacyId,
    params: {
      key: GetLeadsFoldersFetches.init,
      legacyId,
    },
    fetchKey: (p: { key: GetLeadsFoldersFetchesKey }) => p.key,
  });

  const leadsFoldersData = response?.data as FoldersSummaryAPIModel[] | undefined;

  const refreshLeadsFolder = React.useCallback(
    () =>
      refresh({
        key: GetLeadsFoldersFetches.refresh,
        legacyId,
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [legacyId]
  );

  const [value, setValue] = React.useState(initState);

  const inboxFolderId = React.useMemo(
    () => find(leadsFoldersData, { name: LEADS_DEFAULT_FOLDERS.leadsInbox })?.id,
    [leadsFoldersData]
  );

  const trashFolderId = React.useMemo(
    () => find(leadsFoldersData, { name: LEADS_DEFAULT_FOLDERS.trash })?.id,
    [leadsFoldersData]
  );

  React.useEffect(() => {
    if (isEmpty(fetches)) return;

    setValue((pre) => {
      if (
        isEqual(
          {
            inboxFolderId,
            trashFolderId,
            refreshLeadsFolder,
            leadsFolders: leadsFoldersData || pre.leadsFolders,
            isLoading: Boolean(fetches[GetLeadsFoldersFetches.init]?.isLoading),
            isError,
          },
          pre
        )
      ) {
        return pre;
      }

      return {
        inboxFolderId,
        trashFolderId,
        refreshLeadsFolder,
        leadsFolders: leadsFoldersData || pre.leadsFolders,
        isLoading: Boolean(fetches[GetLeadsFoldersFetches.init]?.isLoading),
        isError,
      };
    });
  }, [
    leadsFoldersData,
    fetches.init,
    refreshLeadsFolder,
    isError,
    fetches,
    inboxFolderId,
    trashFolderId,
  ]);

  return <Context.Provider value={value}>{children}</Context.Provider>;
};

export default Context;
