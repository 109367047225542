import React from 'react';
import Box from '@mui/material/Box';

import LinkButton from 'components/Form/LinkButton';
import { useAccountContextState, useAccountContextDispatch } from 'context/account';
import notificationAPI from 'api/notification';
import { gmConfig } from 'config/index';
import { isMobile, getIOSVersion, isPwa } from 'helpers/deviceHelper';
import { useGlobalUIDispatch } from 'hooks/useGlobalUI';
import cookieHelper from 'helpers/cookieHelper';
import { NOTIFICATION_PERMISSION_REQUEST_NAME } from 'constants/storage';

const subscribeUser = async () => {
  try {
    const registration = await navigator.serviceWorker.ready;
    let newSubscription = null;
    const existedSubscription = await registration.pushManager.getSubscription();

    if (existedSubscription !== null) {
      newSubscription = existedSubscription;
    } else {
      const option = {
        userVisibleOnly: true,
        applicationServerKey: gmConfig.notification.webPushKey,
      };

      newSubscription = await registration.pushManager.subscribe(option);
    }

    try {
      notificationAPI.subscribe(newSubscription);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error('An error occurred during the subscription process.', e);
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('An error occurred during Service Worker registration.', e);
  }
};

const NotificationPermissionRequest = () => {
  const { id: accountId } = useAccountContextState();
  const { refreshAccount } = useAccountContextDispatch();
  const { setInstantFeedback } = useGlobalUIDispatch();

  const haveChosen = cookieHelper.get(NOTIFICATION_PERMISSION_REQUEST_NAME);

  const refUnMounted = React.useRef(false);

  const handleOnChosen = (chosen) => {
    const expires = chosen === 'cancel' ? 1 : 365;

    cookieHelper.set(NOTIFICATION_PERMISSION_REQUEST_NAME, chosen, {
      expires,
      domain: gmConfig.cookie.domain,
    });

    setInstantFeedback({ open: false, message: '' });
  };

  const handleClickAllowButton = () => {
    (async () => {
      const requestResult = await Notification.requestPermission();

      if (requestResult === 'granted') {
        subscribeUser();
        refreshAccount();
      }
      if (window.localStorage) {
        window.localStorage.setItem('notificationPermission', requestResult);
      }

      handleOnChosen(requestResult === 'default' ? 'cancel' : requestResult);
    })();
  };

  const messageContent = (
    <>
      <Box component="span" sx={{ marginRight: '12px' }}>
        Enable push notifications when you receive a new lead?
      </Box>
      <Box
        sx={{
          '& .MuiLink-button + .MuiLink-button': { marginLeft: '16px' },
          display: 'flex',
        }}
      >
        <LinkButton
          variant="body2"
          size="small"
          color="secondary"
          onClick={() => {
            handleOnChosen('no');
          }}
          underline="always"
          data-testid="button-no-thanks"
        >
          Don’t Allow
        </LinkButton>
        <LinkButton
          variant="body2"
          size="small"
          color="secondary"
          onClick={handleClickAllowButton}
          underline="always"
          data-testid="button-allow"
        >
          Allow
        </LinkButton>
      </Box>
    </>
  );

  React.useEffect(() => {
    if (haveChosen && haveChosen !== 'cancel') {
      cookieHelper.set(NOTIFICATION_PERMISSION_REQUEST_NAME, haveChosen, {
        expires: 365,
        domain: gmConfig.cookie.domain,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (!accountId || !getIOSVersion()) return;

    if (!('serviceWorker' in navigator) || !('Notification' in window)) {
      return;
    }

    if (isMobile() && !isPwa()) {
      return;
    }

    (async () => {
      try {
        const registration = await navigator.serviceWorker.ready;

        if (!registration.pushManager) {
          // eslint-disable-next-line no-console
          console.log(`A service worker is active: ${registration.active}`);
          // eslint-disable-next-line no-console
          console.log('Push manager unavailable.');
          return;
        }

        let notificationPermission = Notification.permission;

        const localNotificationPermission = window.localStorage.getItem('notificationPermission');

        if (
          notificationPermission === 'default' &&
          getIOSVersion() &&
          localNotificationPermission !== null
        ) {
          notificationPermission = localNotificationPermission;
        }

        if (
          notificationPermission === 'default' &&
          haveChosen !== 'no' &&
          haveChosen !== 'cancel' &&
          !refUnMounted.current
        ) {
          setInstantFeedback({
            open: true,
            message: messageContent,
            autoHideDuration: null,
            notWithStickyBottomBar: true,
            hideOnLeavePage: false,
          });
        }

        if (notificationPermission === 'granted') {
          subscribeUser();
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('An error occurred during Service Worker registration.', error);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountId]);

  React.useEffect(
    () => () => {
      refUnMounted.current = true;
    },
    [setInstantFeedback]
  );

  return null;
};

export default NotificationPermissionRequest;
