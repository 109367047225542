import browser from 'browser-detect';

const result = browser();

export const isMobile = () => result.mobile;

export const getIOSVersion = () => {
  if (/iP(hone|od|ad)/.test(navigator.platform)) {
    const v = navigator.userAgent.match(/OS (\d+)_(\d+)_?(\d+)?/);
    if (v) {
      return [parseInt(v[1], 10), parseInt(v[2], 10), parseInt(v[3] || '0', 10)];
    }
  }
  return null;
};

export const getIpadOSVersion = () => {
  if (
    (/MacIntel/.test(navigator.platform) &&
      navigator.maxTouchPoints &&
      navigator.maxTouchPoints > 2) ||
    /iPad/.test(navigator.platform)
  ) {
    const v =
      navigator.userAgent.match(/OS (\d+)_(\d+)_?(\d+)?/) ||
      navigator.userAgent.match(/OS X (\d+)_(\d+)_?(\d+)?/);

    if (v) {
      return [parseInt(v[1], 10), parseInt(v[2], 10), parseInt(v[3] || '0', 10)];
    }
  }
  return null;
};

export const isIOSOrIpadOSDevice = () => Boolean(getIOSVersion() || getIpadOSVersion());

export const isPwa = () =>
  window.matchMedia('(display-mode: standalone)').matches ||
  (window.navigator as Navigator & { standalone: boolean }).standalone ||
  document.referrer.includes('android-app://');

export const getEdgeVersion = () => {
  if (/Ed(g|gA|giOS)/.test(navigator.userAgent)) {
    const versionInfo = navigator.userAgent.match(/(Edg|EdgA|EdgiOS)\/(\S*)/);
    if (versionInfo) return versionInfo[2].split('.')[0];
  }
  return null;
};

export const isIosChrome = () => /CriOS/i.test(navigator.userAgent);

export const getChromeVersion = () => {
  const raw = navigator.userAgent.match(/Chrom(e|ium)\/([0-9]+)\./);
  return raw ? parseInt(raw[2], 10) : null;
};

export const isSupportXlsxDownload = () => {
  const iOSVersion = getIOSVersion();
  const ua = navigator.userAgent;
  const isiOSChrome = /CriOS/i.test(ua);
  const edgeVersion = getEdgeVersion();
  const ipadOSVersion = getIpadOSVersion();

  if (
    (iOSVersion && iOSVersion[0] < 15 && isiOSChrome) ||
    (edgeVersion && Number(edgeVersion) < 44) ||
    (isiOSChrome && ipadOSVersion && ipadOSVersion[0] < 15)
  ) {
    return false;
  }
  return true;
};

const deviceHelper = {
  isMobile,
  isIOSOrIpadOSDevice,
  isPwa,
  getIOSVersion,
  getIpadOSVersion,
  getEdgeVersion,
  getChromeVersion,
  isSupportXlsxDownload,
  isIosChrome,
};

export default deviceHelper;
