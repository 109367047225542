import accountApi from 'api/account';
import { NOTIFICATION_PERMISSION_REQUEST_NAME } from 'constants/storage';
import { useAccountContextState } from 'context/account';
import cookieHelper from 'helpers/cookieHelper';
import { getIOSVersion, isPwa } from 'helpers/deviceHelper';
import useRequest from 'hooks/useRequest';
import PropTypes from 'prop-types';
import React from 'react';
import { Notification } from '../api/account';
import { BeforeInstallPromptEvent, InstallPromptUserChoice } from '../types/beforeinstallprompt';

interface PwaContextState {
  webInstallWebPromptEvent: BeforeInstallPromptEvent | null;
  showIosInstallPromptEvent: boolean;
  openPWAVisualGuidance: boolean;
  guidanceStep: string | null;
}

interface PwaContextDispatch {
  handleWebInstallPrompt: () => Promise<InstallPromptUserChoice | undefined>;
  setOpenPWAVisualGuidance: React.Dispatch<React.SetStateAction<boolean>>;
  setGuidanceStep: React.Dispatch<React.SetStateAction<string | null>>;
}

const initialState: PwaContextState = {
  webInstallWebPromptEvent: null,
  showIosInstallPromptEvent: false,
  openPWAVisualGuidance: false,
  guidanceStep: null,
};

const initialDispatch: PwaContextDispatch = {
  handleWebInstallPrompt: async () => undefined,
  setOpenPWAVisualGuidance: () => {},
  setGuidanceStep: () => {},
};

export const StateContext = React.createContext<PwaContextState>(initialState);
export const DispatchContext = React.createContext<PwaContextDispatch>(initialDispatch);
export const usePwaContextState = () => React.useContext(StateContext);
export const usePwaContextDispatch = () => React.useContext(DispatchContext);

let eventInit: BeforeInstallPromptEvent | null = null;
(() => {
  const beforeInstallPromptHandler1 = (event: BeforeInstallPromptEvent) => {
    eventInit = event;

    window.removeEventListener('beforeinstallprompt', beforeInstallPromptHandler1);
  };

  window.addEventListener('beforeinstallprompt', beforeInstallPromptHandler1);
})();

export const PwaContextProvider = ({ children }: React.PropsWithChildren) => {
  const { id: accountId } = useAccountContextState();

  const [userShouldBePromptedToInstall, setUserShouldBePromptedToInstall] = React.useState(true);
  const [webInstallWebPromptEvent, setWebInstallPromptEvent] =
    React.useState<BeforeInstallPromptEvent | null>(() =>
      userShouldBePromptedToInstall ? eventInit : null
    );
  const [iosInstallPromptEvent, setIosInstallPromptEvent] = React.useState<boolean>(false);

  const [openPWAVisualGuidance, setOpenPWAVisualGuidance] = React.useState<boolean>(false);
  const [guidanceStep, setGuidanceStep] = React.useState<string | null>(null);

  const iOSVersion = React.useMemo(() => getIOSVersion(), []);

  const shouldSupportInstall = Boolean(
    !isPwa() && iOSVersion && iOSVersion[0] + iOSVersion[1] * 0.1 >= 16.4
  );

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { response: pwaNewFeatureCallOutDialogResponse }: any = useRequest(
    accountApi.getNotificationsByAccountIdWithType,
    {
      manual: !accountId,
      fetchKey: (p: { accountId: string }) => p.accountId,
      params: { accountId, type: 'PWANewFeatureCallOutDialog' },
    }
  );

  const pwaNewFeatureCallOutDialog =
    (pwaNewFeatureCallOutDialogResponse?.data as Notification[]) || null;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { response: pwaVisualGuidanceResponse }: any = useRequest(
    accountApi.getNotificationsByAccountIdWithType,
    {
      manual: !accountId,
      fetchKey: (p: { accountId: string }) => p.accountId,
      params: { accountId, type: 'PWAVisualGuides' },
    }
  );

  const pwaVisualGuidance = (pwaVisualGuidanceResponse?.data as Notification[]) || null;

  React.useEffect(() => {
    if (!pwaVisualGuidance || !pwaNewFeatureCallOutDialog) return;

    setOpenPWAVisualGuidance(pwaNewFeatureCallOutDialog.length > 0 && pwaVisualGuidance.length < 1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pwaNewFeatureCallOutDialogResponse, pwaVisualGuidanceResponse]);

  const shouldShowPwaVisualGuidance = React.useMemo(
    () =>
      Boolean(
        pwaNewFeatureCallOutDialog &&
          pwaVisualGuidance &&
          pwaVisualGuidance.length < 1 &&
          openPWAVisualGuidance &&
          shouldSupportInstall &&
          (webInstallWebPromptEvent || iosInstallPromptEvent)
      ),
    [
      shouldSupportInstall,
      webInstallWebPromptEvent,
      iosInstallPromptEvent,
      pwaNewFeatureCallOutDialog,
      pwaVisualGuidance,
      openPWAVisualGuidance,
    ]
  );

  React.useEffect(() => {
    const beforeInstallPromptHandler = (event: BeforeInstallPromptEvent) => {
      if (userShouldBePromptedToInstall) {
        setWebInstallPromptEvent(event);
      }
    };

    window.addEventListener('beforeinstallprompt', beforeInstallPromptHandler);

    return () => window.removeEventListener('beforeinstallprompt', beforeInstallPromptHandler);
  }, [userShouldBePromptedToInstall]);

  React.useEffect(() => {
    const removeChosen = () => {
      cookieHelper.remove(NOTIFICATION_PERMISSION_REQUEST_NAME);
    };

    window.addEventListener('appinstalled', removeChosen);

    return () => window.removeEventListener('appinstalled', removeChosen);
  }, []);

  React.useEffect(() => {
    setIosInstallPromptEvent(!!iOSVersion);
  }, [iOSVersion]);

  React.useEffect(() => {
    if (openPWAVisualGuidance) {
      accountApi.createNotification({ type: 'PWAVisualGuides' });
    }
  }, [openPWAVisualGuidance]);

  const handleWebInstallPrompt = React.useCallback(async () => {
    const result = await webInstallWebPromptEvent?.prompt();

    setUserShouldBePromptedToInstall(false);
    setWebInstallPromptEvent(null);

    return result;
  }, [webInstallWebPromptEvent]);

  return (
    <StateContext.Provider
      value={{
        webInstallWebPromptEvent: shouldSupportInstall ? webInstallWebPromptEvent : null,
        showIosInstallPromptEvent: shouldSupportInstall ? iosInstallPromptEvent : false,
        openPWAVisualGuidance: shouldShowPwaVisualGuidance,
        guidanceStep,
      }}
    >
      <DispatchContext.Provider
        value={{
          handleWebInstallPrompt,
          setOpenPWAVisualGuidance,
          setGuidanceStep,
        }}
      >
        {children}
      </DispatchContext.Provider>
    </StateContext.Provider>
  );
};

PwaContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default PwaContextProvider;
