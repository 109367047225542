const generator = (iconUrl: string, { color = 'white', crossOrigin = false }) => {
  if (typeof iconUrl !== 'string' || iconUrl.length === 0) {
    throw new Error('Invalid icon URL');
  }

  const deviceWidth = window.screen.width;
  const deviceHeight = window.screen.height;
  // calculate the pixel ratio
  const pixelRatio = window.devicePixelRatio || 1;

  // for landscape
  const canvas = document.createElement('canvas');
  // for portrait
  const canvas2 = document.createElement('canvas');
  const ctx = canvas.getContext('2d');
  const ctx2 = canvas2.getContext('2d');

  // load the icon image, make sure it is served from the same domain (ideal size 512pxX512px).
  // if not then set the proper CORS headers on the image and uncomment the next line.
  const iconImage = new Image();

  iconImage.onerror = () => {
    // throw new Error('Failed to load icon image');
  };

  iconImage.onload = () => {
    // calculate the icon size
    const iconSizeWidth = iconImage.width / (3 / pixelRatio);
    const iconSizeHeight = iconImage.height / (3 / pixelRatio);

    canvas.width = deviceWidth * pixelRatio;
    canvas.height = deviceHeight * pixelRatio;

    canvas2.height = canvas.width;
    canvas2.width = canvas.height;

    if (!ctx || !ctx2) {
      throw new Error('Failed to create canvas context');
    }

    ctx.fillStyle = color;
    ctx2.fillStyle = color;
    ctx.fillRect(0, 0, canvas.width, canvas.height);
    ctx2.fillRect(0, 0, canvas2.width, canvas2.height);

    // calculate icon position to center
    const x = (canvas.width - iconSizeWidth) / 2;
    const y = (canvas.height - iconSizeHeight) / 2;
    const x2 = (canvas2.width - iconSizeWidth) / 2;
    const y2 = (canvas2.height - iconSizeHeight) / 2;

    // draw the icon
    ctx.drawImage(iconImage, x, y, iconSizeWidth, iconSizeHeight);
    ctx2.drawImage(iconImage, x2, y2, iconSizeWidth, iconSizeHeight);
    const imageDataURL = canvas.toDataURL('image/png');
    const imageDataURL2 = canvas2.toDataURL('image/png');

    // create startup image <link> tag (splash screen)
    const appleTouchStartupImageLink = document.createElement('link');
    appleTouchStartupImageLink.setAttribute('rel', 'apple-touch-startup-image');
    appleTouchStartupImageLink.setAttribute('media', 'screen and (orientation: portrait)');
    appleTouchStartupImageLink.setAttribute('href', imageDataURL);
    document.head.appendChild(appleTouchStartupImageLink);

    // create startup image <link> tag (landscape screen)
    const appleTouchStartupImageLink2 = document.createElement('link');
    appleTouchStartupImageLink2.setAttribute('rel', 'apple-touch-startup-image');
    appleTouchStartupImageLink2.setAttribute('media', 'screen and (orientation: landscape)');
    appleTouchStartupImageLink2.setAttribute('href', imageDataURL2);
    document.head.appendChild(appleTouchStartupImageLink2);
  };

  if (crossOrigin) {
    iconImage.crossOrigin = 'anonymous';
  }

  iconImage.src = iconUrl;
};

export default {
  generator,
};
