import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

interface HamburgIconProps extends SvgIconProps {}

const HamburgIcon = (props: HamburgIconProps) => (
  <SvgIcon viewBox="0 0 32 32" data-testid="svg" {...props}>
    <rect x="4" y="8.5" width="24" height="1.8" rx="0.9" />
    <rect x="4" y="15.1" width="24" height="1.8" rx="0.9" />
    <rect x="4" y="21.7" width="24" height="1.8" rx="0.9" />
  </SvgIcon>
);

export default HamburgIcon;
