import { Theme } from '@mui/material';

const styles = {
  tabsRoot: (theme: Theme) => ({
    height: '100%',
    marginRight: 0,
    display: 'none',
    alignItems: 'center',

    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },

    '& .MuiTabs-indicator': {
      height: 4,
      bottom: '1px',
      backgroundColor: theme.palette.blue[500],
    },
  }),

  tabRoot: (theme: Theme) => ({
    height: '100%',
    fontFamily: 'SofiaPro-Semibold',
    fontWeight: 'normal',
    padding: '20px',
    opacity: 1,
    minWidth: 0,
    letterSpacing: 1,
    color: theme.palette.black[900],
    overflow: 'visible',
    '&.Mui-selected': {
      color: theme.palette.blue[500],
    },
    zIndex: 2,
  }),
  navLink: (theme: Theme) => ({
    color: theme.palette.black[900],
    fontSize: '16px',
    fontFamily: 'SofiaPro-Semibold',
    lineHeight: '20px',
    textTransform: 'uppercase',
    letterSpacing: 1,
    textDecoration: 'none',
    boxSizing: 'border-box',
    margin: '0 0 0 20px',
    padding: '20px 0px',
    '&:hover': {
      color: theme.palette.blue[500],
      textDecoration: 'none',
    },
  }),
};

export default styles;
