import React from 'react';
import { useNotificationExists } from './useNotificationExists';
import { useCreateNotification } from './useCreateNotification';
import { NotificationScopeValue } from './constants';

export interface UseNotificationProps {
  notificationType: string;
  notificationScope: NotificationScopeValue;
}

export const useDismissibleNotification = ({
  notificationType,
  notificationScope,
}: UseNotificationProps) => {
  const { exists: alreadyDismissed } = useNotificationExists({
    notificationType,
    notificationScope,
  });

  const [justDismissed, setJustDismissed] = React.useState(false);

  const { create } = useCreateNotification({
    notificationType,
    notificationScope,
  });

  const isReady = alreadyDismissed !== undefined;
  const isDismissed = alreadyDismissed || justDismissed;

  const dismiss = async () => {
    if (!isDismissed) {
      setJustDismissed(true);
      await create();
    }
  };

  return {
    isReady,
    isDismissed,
    dismiss,
  };
};
