import Dialog from '@mui/material/Dialog';
import { styled } from '@mui/material/styles';

import styles from './styles';

const BaseStyleBigDialog = styled(Dialog, {
  name: 'BaseStyleBigDialog',
})(styles);

export default BaseStyleBigDialog;
