import React from 'react';

import { Tabs, Tab, Link } from '@mui/material';

import { gmConfig } from 'config/index';
import { EVENTS, trackEvent } from 'helpers/tracking';
import styles from './styles';

type HeaderTabsProps = {
  welcomeAccountName: string;
  value: string | boolean;
  handleHoverTab: (value: string | boolean) => void;
  calLeftPosition: (value: number) => void;
};

const HeaderTabs = (props: HeaderTabsProps) => {
  const { welcomeAccountName = '', handleHoverTab, value, calLeftPosition } = props;

  return (
    <Tabs sx={styles.tabsRoot} value={value} data-testid="span-greeting-welcome-name">
      <Tab
        sx={styles.tabRoot}
        component="li"
        label={`Hi, ${welcomeAccountName}`}
        value="welcomeAccountName"
        onMouseEnter={(e) => {
          handleHoverTab('welcomeAccountName');
          calLeftPosition(e.currentTarget.getBoundingClientRect().left + 30);
        }}
        onMouseLeave={() => handleHoverTab(false)}
      />
      <Tab
        component={Link}
        sx={styles.navLink}
        href={`${gmConfig.domains.www}/contact`}
        onClick={() => {
          trackEvent(EVENTS.contactUsClicked, {
            source: 'header',
          });
        }}
        data-testid="link-contact-us"
        label="Contact Us"
      />
    </Tabs>
  );
};

export default HeaderTabs;
