import React from 'react';
import vendorApi from 'api/vendor';
import accountApi from 'api/account';
import VendorContext from 'context/vendor';
import { NotificationScope, NotificationScopeValue } from './constants';
import { useEagerRequest } from '../request/useEagerRequest';

export interface UseNotificationExistsProps {
  notificationType: string;
  notificationScope: NotificationScopeValue;
}

export const useNotificationExists = ({
  notificationType,
  notificationScope,
}: UseNotificationExistsProps) => {
  const { vendor } = React.useContext(VendorContext);

  const getNotificationExists = async () => {
    if (notificationScope === NotificationScope.ACCOUNT) {
      const response = await accountApi.getNotificationsByAccountIdWithType({
        accountId: vendor.accountId!,
        type: notificationType,
      });

      return response.data.length > 0;
    }

    const response = await vendorApi.getNotificationsByVendorIdWithType({
      vendorId: vendor.vendorId,
      type: notificationType,
    });

    return response.data.length > 0;
  };

  const {
    response: exists,
    isLoading,
    isError,
    isSuccessful,
  } = useEagerRequest<boolean, void>(getNotificationExists, {});

  return {
    exists,
    isLoading,
    isError,
    isSuccessful,
  };
};
