import CloseIcon from '@mui/icons-material/Close';
import { Theme } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import { gmConfig } from 'config/index';
import VendorContext from 'context/vendor';
import React from 'react';
import { noop } from 'utils/function';
import { NotificationScope, NotificationScopeValue } from 'hooks/notification/constants';
import { useJsonCookie } from 'hooks/useJsonCookie';
import { useDismissibleNotification } from '../../../hooks/notification/useDismissibleNotification';
import { ButtonsSection } from './ButtonsSection';
import styles from './styles';
import {
  OnCloseSources,
  OnCloseSourceValue,
  OnDismissSources,
  OnDismissSourceValue,
} from './types';

interface NewFeatureDialogProps {
  notificationType: string;
  title: string;
  notificationScope: NotificationScopeValue;
  remindLaterButtonText?: string;
  dismissButtonText?: string;
  closable?: boolean;
  onRemindLater?: () => void;
  onDismiss?: (source: OnDismissSourceValue) => void;
  onClose?: (source: OnCloseSourceValue) => void;
  'data-testid'?: string;
}

interface RemindMeLaterCookie {
  scope: NotificationScopeValue;
  id: string;
}

export const NewFeatureDialog = ({
  notificationType,
  title,
  notificationScope = NotificationScope.ACCOUNT,
  remindLaterButtonText = 'Remind me later',
  dismissButtonText = 'Dismiss',
  closable = false,
  onRemindLater = noop,
  onDismiss = noop,
  onClose = noop,
  'data-testid': dataTestid = 'new-feature-dialog',
  children,
}: React.PropsWithChildren<NewFeatureDialogProps>) => {
  const { vendor } = React.useContext(VendorContext);
  const [open, setOpen] = React.useState(true);

  const remindMeLaterCookie = useJsonCookie<RemindMeLaterCookie>(notificationType, {
    expires: 1,
    domain: gmConfig.cookie.domain,
  });

  const remindMeLaterCookieValue = remindMeLaterCookie.get();

  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  const isRemindLaterOn = React.useMemo(() => {
    if (!remindMeLaterCookieValue) return false;
    if (remindMeLaterCookieValue.scope === NotificationScope.ACCOUNT) {
      return remindMeLaterCookieValue.id === String(vendor.accountId);
    }
    return remindMeLaterCookieValue.id === vendor.vendorId;
  }, [remindMeLaterCookieValue, vendor]);

  const notification = useDismissibleNotification({
    notificationType,
    notificationScope,
  });

  const handleClickOnRemindLater = () => {
    remindMeLaterCookie.set({
      scope: notificationScope,
      id:
        notificationScope === NotificationScope.ACCOUNT
          ? String(vendor.accountId)
          : vendor.vendorId,
    });
    onRemindLater();
    onClose(OnCloseSources.REMIND_ME_LATER_BUTTON);
    setOpen(false);
  };

  const handleClickOnDismiss = () => {
    notification.dismiss();
    onDismiss(OnDismissSources.DISMISS_BUTTON);
    onClose(OnCloseSources.DISMISS_BUTTON);
    setOpen(false);
  };

  const handleClickCloseButton = () => {
    notification.dismiss();
    onDismiss(OnDismissSources.CLOSE_BUTTON);
    onClose(OnCloseSources.CLOSE_BUTTON);
    setOpen(false);
  };

  if (!notification.isReady || notification.isDismissed || isRemindLaterOn) {
    return null;
  }

  return (
    <Dialog open={open} sx={styles.dialogPaper} disableEscapeKeyDown fullWidth>
      <DialogTitle sx={styles.title}>
        <Typography variant="h3">{title}</Typography>
        {closable && (
          <IconButton
            aria-label="close"
            onClick={handleClickCloseButton}
            size="small"
            data-testid={`${dataTestid}-close-button`}
          >
            <CloseIcon
              sx={{
                color: (theme) => theme.palette.text.primary,
              }}
            />
          </IconButton>
        )}
      </DialogTitle>
      <DialogContent sx={styles.dialogContent} data-testid={dataTestid}>
        {children}
        {isMobile && (
          <ButtonsSection
            dismissButtonText={dismissButtonText}
            remindLaterButtonText={remindLaterButtonText}
            onRemindLater={handleClickOnRemindLater}
            onDismiss={handleClickOnDismiss}
            data-testid={dataTestid}
          />
        )}
      </DialogContent>
      {!isMobile && (
        <ButtonsSection
          dismissButtonText={dismissButtonText}
          remindLaterButtonText={remindLaterButtonText}
          onRemindLater={handleClickOnRemindLater}
          onDismiss={handleClickOnDismiss}
          data-testid={dataTestid}
        />
      )}
    </Dialog>
  );
};
