import { Theme } from '@mui/material';
import { HEADER_HEIGHT } from '../Header/styles';

export const classes = {
  root: 'LeftNav-root',
};

const styles = {
  nav: (theme: Theme) => ({
    '@media print': {
      display: 'none',
    },
    [theme.breakpoints.down('md')]: {
      height: 'calc(100% - 60px)',
      width: '100%',
      position: 'fixed',
      top: 0,
      overflowY: 'scroll',
      zIndex: 1200,
    },
    [theme.breakpoints.up('md')]: {
      position: 'sticky',
      top: `${HEADER_HEIGHT}px`,
    },
    marginTop: `${HEADER_HEIGHT}px`,
    flex: '0 0 280px',
    width: '280px',
    backgroundColor: theme.palette.grey[900],
    listStyleType: 'none',
    paddingBottom: '48px',
    height: `calc(100lvh - ${HEADER_HEIGHT}px)`,
    overflowY: 'auto',
  }),
  vendorDetails: {
    display: 'flex',
    flexDirection: 'row',
    padding: '32px 34px 20px 31px',
    position: 'unset',
    lineHeight: 'normal',
    '& > div': {
      display: 'flex',
      flexDirection: 'column',
      textAlign: 'left',
    },
  },
  membershipSelectionBox: {
    padding: '32px 34px 20px 31px',
  },
  customArrow: {
    '& .MuiTooltip-arrow': {
      left: '-13px !important',
    },
    '.MuiTooltip-popper[data-popper-placement*="bottom"] &': {
      marginTop: '12px',
      marginLeft: '30px',
    },
  },
  membershipSelectionLink: (theme: Theme) => ({
    color: theme.palette.grey[300],
    textTransform: 'none',
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
      color: theme.palette.common.white,
    },
  }),
  profileImage: {
    height: 50,
    width: 50,
    borderRadius: '50%',
    marginRight: '12px',
  },
  profileImageBox: {
    position: 'relative',
    height: 50,
    width: 50,
    marginRight: '11px',
  },
  vacationIconLabel: (theme: Theme) => ({
    height: '22px',
    width: '22px',
    borderRadius: '50%',
    backgroundColor: theme.palette.mint[50],
    position: 'absolute',
    bottom: 0,
    right: 0,
    display: 'flex',
    justifyContent: 'center',
    '& p': {
      color: theme.palette.mint[700],
      paddingTop: '1px',
    },
  }),
  vendorInfo: (theme: Theme) => ({
    color: theme.palette.grey[500],
    opacity: 0.9,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    textTransform: 'uppercase',
  }),
  vendorName: (theme: Theme) => ({
    color: theme.palette.common.white,
    marginBottom: '4px',
  }),
  vendorSelection: {
    border: 'none',
    marginTop: '0px',
  },
  switchVendor: (theme: Theme) => ({
    display: 'inline-flex',
    alignItems: 'center',
    fontSize: '14px',
    color: theme.palette.grey[300],
    marginTop: '4px',
    textTransform: 'none',
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
      color: theme.palette.common.white,
      '& > svg': {
        fill: theme.palette.common.white,
      },
    },
    '& > svg': {
      fill: theme.palette.grey[300],
      marginLeft: '7px',
      height: 14,
      width: 24,
    },
  }),
  divider: (theme: Theme) => ({
    borderColor: theme.palette.grey[600],
    marginBottom: '16px',
  }),
  navList: (theme: Theme) => ({
    marginTop: '32px',
    display: 'none',
    [theme.breakpoints.down('md')]: {
      display: 'block',
    },
  }),
  navListItem: (theme: Theme) => ({
    position: 'unset',
    letterSpacing: '1px',
    padding: '10px 32px',
    textTransform: 'uppercase',
    '& > a': {
      color: theme.palette.grey[300],
      textDecoration: 'none',
      display: 'block',
    },
    '& > a:hover': {
      cursor: 'pointer',
      textDecoration: 'none',
      color: theme.palette.blue[300],
    },
  }),
  navListVisibleItem: {
    display: 'block',
    borderTop: 'none',
  },

  loaderContainer: (theme: Theme) => ({
    height: '136px',
    '& .Loader-ErrorMessage-root': {
      borderBottom: `1px solid ${theme.palette.decorative.loader.border}`,
      padding: '40px 18px',
      '& .MuiLink-root': {
        fontFamily: theme.typography.body1.fontFamily,
        color: theme.palette.primary.contrastText,
        textDecoration: 'underline',
      },
      '& .MuiLink-root:hover': {
        color: theme.palette.primary.contrastText,
        textDecoration: 'none',
      },
      '& .MuiTypography-subtitle2': {
        fontSize: '14px',
        lineHeight: '20px',
        color: theme.palette.primary.contrastText,
        whiteSpace: 'initial',
      },
    },
  }),

  pwaInstallBanner: (theme: Theme) => ({
    margin: '32px 12px 0 12px',

    [theme.breakpoints.down('md')]: {
      margin: '32px 32px 0 32px',
    },
  }),
};

export default styles;
