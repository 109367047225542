import { vendorAPIRequest, theBashWebAPIRequest, gigMasterAPIRequest } from 'api/index';
import { AxiosResponse } from 'axios';
import { gmConfig } from 'config/index';
import { VendorId } from 'types/vendor';
import { Account } from './types/account';

export interface Notification {
  accountId: string;
  type: string;
  createdAt: string;
}

const getNotificationsByAccountIdWithType = ({
  accountId,
  type,
}: {
  accountId: number;
  type: string;
}) => vendorAPIRequest.get<Notification[]>(`/accounts/${accountId}/notifications?type=${type}`);

const createNotification = ({ type }: { type: string }) =>
  vendorAPIRequest.post<void>(`/accounts/notifications`, {
    type,
  });

const updatePassword = ({
  isResetPassword,
  currentPassword,
  newPassword,
  otp,
  vendorId,
}: {
  isResetPassword: boolean;
  currentPassword: string;
  newPassword: string;
  otp?: string;
  vendorId: VendorId;
}) =>
  vendorAPIRequest.put<void>(`/accounts/password?isResetPassword=${isResetPassword}`, {
    currentPassword,
    newPassword,
    vendorId,
    otp,
  });

const grantToken = (payload: URLSearchParams) =>
  theBashWebAPIRequest.post<
    URLSearchParams,
    AxiosResponse<{
      access_token: string;
      token_type: string;
      expires_in: number;
      refresh_token: string;
      scope: string;
    }>
  >('/api/v1/token', payload, {
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
  });

const getAccount = () => gigMasterAPIRequest.get<Account>('/api/v1/whoami');

const grantTokenByPassword = ({ username, password }: { username: string; password: string }) => {
  const payload = new URLSearchParams({
    grant_type: 'password',
    client_id: gmConfig.auth.client.id || '',
    client_secret: gmConfig.auth.client.secret || '',
    username,
    password,
  });
  return grantToken(payload);
};

export default {
  getNotificationsByAccountIdWithType,
  createNotification,
  updatePassword,
  grantToken,
  grantTokenByPassword,
  getAccount,
};
