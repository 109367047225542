import React, { PropsWithChildren } from 'react';
import { env } from 'config/index';
import { LOCAL_ENV_NAME, LOCAL_ENV_LOGIN_PATH } from 'constants/localEnv';
import DevelopmentLogin from '../index';

// FIXME reconvert this to a general auth provider, which handles the auth checking, logout redirection, dev login, etc.
const DevelopmentLoginAppWrapper = ({ children }: PropsWithChildren) =>
  env === LOCAL_ENV_NAME && window.location.pathname === LOCAL_ENV_LOGIN_PATH ? (
    <DevelopmentLogin />
  ) : (
    children
  );

export default DevelopmentLoginAppWrapper;
