import { Theme } from '@mui/material';

const styles = {
  learnMoreBox: (theme: Theme) => ({
    paddingBlock: '24px',
    '& ul': { padding: '0 0 0 24px', margin: 0 },
    '& li': {
      color: theme.palette.primary.main,
    },
    '& h6': {
      marginBottom: '12px',
    },
    [theme.breakpoints.down('sm')]: {
      paddingBottom: '12px',
    },
  }),
};

export default styles;
