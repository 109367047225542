import React from 'react';
import { styled } from '@mui/material/styles';

import { gmConfig } from 'config/index';

import styles from './styles';

const StyledPrideBrandImage = styled('img', { name: 'PrideBrandImage' })(styles.root);

const PrideBrandImage = (props) => (
  <StyledPrideBrandImage
    alt="PrideBrandImage"
    src={`https://${gmConfig.domains.cdn}/logo/the-bash-header-logo-pride.svg`}
    {...props}
  />
);

export default PrideBrandImage;
