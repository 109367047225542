import { PlanTypeValue } from 'views/Membership/PlanOptions/types';

export const MEMBERSHIP_EVENTS = {
  manageMembershipUpgradeClicked: 'Manage Membership Upgrade Clicked',
  manageMembershipRenewClicked: 'Manage Membership Renew Clicked',
  manageMembershipPayClickedFail: 'Manage Membership Pay Clicked Fail',
  manageMembershipPayClickedOk: 'Manage Membership Pay Clicked Ok',
  manageMembershipEditProfileClicked: 'Manage Membership Edit Profile Clicked',
  manageMembershipDashboardClicked: 'Manage Membership Dashboard Clicked',
} as const;

export interface ManageMembershipEventProps {
  vendorId: number;
  membershipPricePaid: number;
  membershipPrice: number;
  couponCode?: string;
  couponValue?: string;
  membershipSelected: string;
  memberLevel: number;
  currentMembership: string;
}

interface PayMembershipEventWithErrorProps extends ManageMembershipEventProps {
  validationErrors: Record<string, string>;
}

interface PayMembershipEventSuccessfulProps extends ManageMembershipEventProps {
  purchaseType: PlanTypeValue;
}

export type ManageMembershipEventPropsMap = {
  [MEMBERSHIP_EVENTS.manageMembershipUpgradeClicked]: ManageMembershipEventProps;
  [MEMBERSHIP_EVENTS.manageMembershipRenewClicked]: ManageMembershipEventProps;
  [MEMBERSHIP_EVENTS.manageMembershipPayClickedFail]: PayMembershipEventWithErrorProps;
  [MEMBERSHIP_EVENTS.manageMembershipPayClickedOk]: PayMembershipEventSuccessfulProps;
  [MEMBERSHIP_EVENTS.manageMembershipEditProfileClicked]: { vendorId: number };
  [MEMBERSHIP_EVENTS.manageMembershipDashboardClicked]: { vendorId: number };
};

export interface ManageMembershipPageProps {
  name: 'Manage Membership';
  vendorId: number;
  couponCode?: string;
  couponValue?: string;
  memberLevel: number;
  currentMembership: string;
}
