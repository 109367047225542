import SvgIcon from '@mui/material/SvgIcon';
import DoDisturbOnOutlinedIcon from '@mui/icons-material/DoDisturbOnOutlined';

export const LEAD_STATUS = {
  unanswered: 'Unanswered',
  booked: 'Booked',
  declined: 'DeclineSent',
  quoteSent: 'BidSent',
  quoteExpired: 'ExpiredBid',
  pendingPayment: 'Pending',
  inactive: 'Closed',
} as const;

export type LeadStatusKey = keyof typeof LEAD_STATUS;
export type LeadStatusValue = (typeof LEAD_STATUS)[LeadStatusKey];

export const REVIEW_STATUS = {
  futureDate: 'Future date',
  awaitingReview: 'Awaiting review',
  reviewed: 'Reviewed',
} as const;

export type ReviewStatusKey = keyof typeof REVIEW_STATUS;
export type ReviewStatusValue = (typeof REVIEW_STATUS)[ReviewStatusKey];

export interface StatusToUIMappingValue {
  apiValue: LeadStatusValue;
  label: string;
  status: 'error' | 'warning' | 'secondSuccess' | 'unavailable' | 'inactive';
  icon?: typeof SvgIcon;
}

export const STATUS_TO_UI_MAPPING: Record<LeadStatusKey, StatusToUIMappingValue> = {
  unanswered: {
    apiValue: LEAD_STATUS.unanswered,
    label: 'Unanswered',
    status: 'error',
  },
  booked: { apiValue: LEAD_STATUS.booked, label: 'Booked', status: 'secondSuccess' },
  declined: { apiValue: LEAD_STATUS.declined, label: 'Declined', status: 'unavailable' },
  quoteSent: {
    apiValue: LEAD_STATUS.quoteSent,
    label: 'Quote sent',
    status: 'warning',
  },
  quoteExpired: {
    apiValue: LEAD_STATUS.quoteExpired,
    label: 'Quote expired',
    status: 'unavailable',
  },
  pendingPayment: {
    apiValue: LEAD_STATUS.pendingPayment,
    label: 'Pending booking',
    status: 'warning',
  },
  inactive: {
    apiValue: LEAD_STATUS.inactive,
    label: 'Inactive',
    status: 'inactive',
    icon: DoDisturbOnOutlinedIcon,
  },
};

export const REQUEST_FORM_TYPE = {
  general: 'General',
  transportation: 'Transportation',
} as const;

export type RequestFormTypeKey = keyof typeof REQUEST_FORM_TYPE;
export type RequestFormTypeValue = (typeof REQUEST_FORM_TYPE)[RequestFormTypeKey];

export const RESPONSE_TYPE = {
  decline: 'decline',
  quote: 'quote',
} as const;

export type ResponseTypeKey = keyof typeof RESPONSE_TYPE;
export type ResponseTypeValue = (typeof RESPONSE_TYPE)[ResponseTypeKey];

export const LEAD_STATUS_API_ID = {
  unanswered: 0,
  quoteSent: 1,
  declined: 2,
  quoteExpired: 3,
  booked: 4,
  pendingPayment: 5,
  inactive: 6,
} as const satisfies Record<LeadStatusKey, number>;

export type LeadStatusApiIdValue = (typeof LEAD_STATUS_API_ID)[LeadStatusKey];
