import React, { ReactNode } from 'react';
import Link, { LinkProps } from '@mui/material/Link';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

interface LinkButtonProps extends LinkProps<typeof Button> {
  children?: ReactNode;
  startIcon?: ReactNode;
  onClick?: React.MouseEventHandler<HTMLAnchorElement> & React.MouseEventHandler<HTMLSpanElement>;
  inline?: boolean;
}

const LinkButton = ({
  children = null,
  startIcon = null,
  underline = 'none',
  onClick = () => {},
  inline = false,
  sx = {},
  ...otherProps
}: LinkButtonProps) => (
  <Link
    {...otherProps}
    component="button"
    underline={underline}
    onClick={onClick}
    sx={[inline ? { display: 'inline' } : {}, sx].flat()}
  >
    {startIcon}
    <Typography
      component="span"
      variant="inherit"
      align="inherit"
      color="inherit"
      sx={{
        width: '100%',
        display: 'inherit',
      }}
    >
      {children}
    </Typography>
  </Link>
);

export default LinkButton;
