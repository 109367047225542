import { TRACK_EVENT, pageViewEvent } from '@tkww/the-bash-frontend';

export const generateTrackEventAction = (name: string, properties = {}) => ({
  type: TRACK_EVENT,
  name,
  properties: {
    ...properties,
    loggedState: 'vendor',
  },
});

export const generatePageEventAction = (name: string, properties = {}) =>
  pageViewEvent(name, { ...properties, loggedState: 'vendor' });
