// base props used on both page and event tracking
export interface BillingInformationBaseEventProperties {
  arOptedIn: boolean;
}

export interface BillingInformationClickEventProperties
  extends BillingInformationBaseEventProperties {
  name: string;
  clickArea: string;
  clickTarget?: string;
  ctaStyle?: string;
}

export const BILLINGINFO_EVENTS = {
  autoRenewOptOut: 'Auto Renew Opt Out Clicked',
  autoRenewOptIn: 'Auto Renew Opt In Clicked',
  membershipRenew: 'Membership Renew Clicked',
  autoRenewModalOptOut: 'Auto Renew Modal CTA Clicked',
  autoRenewModalClose: 'Auto Renew Modal Closed',
  autoRenewModalSave: 'Auto Renew Modal AR Opt Out Saved',
} as const;

export type BillingInformationEventPropsMap = {
  [BILLINGINFO_EVENTS.autoRenewOptOut]: BillingInformationClickEventProperties;
  [BILLINGINFO_EVENTS.autoRenewOptIn]: BillingInformationClickEventProperties;
  [BILLINGINFO_EVENTS.membershipRenew]: BillingInformationClickEventProperties;
  [BILLINGINFO_EVENTS.autoRenewModalOptOut]: BillingInformationClickEventProperties;
  [BILLINGINFO_EVENTS.autoRenewModalClose]: BillingInformationClickEventProperties;
  [BILLINGINFO_EVENTS.autoRenewModalSave]: BillingInformationClickEventProperties;
};
