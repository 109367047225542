import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import { useTheme } from '@mui/material/styles';

interface SavedResponsesProps extends SvgIconProps {
  inheritColor?: boolean;
}

const SavedResponsesIcon = ({ inheritColor = false, ...restProps }: SavedResponsesProps) => {
  const theme = useTheme();
  const color = inheritColor ? theme.palette.decorative.youtube : '';
  return (
    <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" {...restProps}>
      <path d="M20.5 14L19.5 20L23.85 12.25H20.85L23.625 7H19L18 14H20.5Z" fill={color} />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 2C2.9 2 2.01 2.9 2.01 4L2 22L6 18H18.3333L18.6667 16H5.17L4 17.17V4H20V5.5H22V4C22 2.9 21.1 2 20 2H4ZM17.6275 6H6V8H17.3375L17.6275 6ZM17.1925 9H6V11H16.9025L17.1925 9Z"
        fill={color}
      />
    </SvgIcon>
  );
};

export default SavedResponsesIcon;
