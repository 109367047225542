import React from 'react';
import { useLocation, Link as RouteLink, useHistory } from 'react-router-dom';

import { Box, Typography, Link } from '@mui/material';
import LandscapeOutlinedIcon from '@mui/icons-material/LandscapeOutlined';
import Divider from '@mui/material/Divider';

import VendorContext from 'context/vendor';
import LeadsFolderContext from 'context/leadsFolders';
import { useLeftNavContextDispatch, useLeftNavContextState } from 'context/leftNav';
import SwitchIcon from 'components/Icon/SwitchIcon';
import { gmConfig } from 'config/index';
import Loader from 'components/Loader';
import PwaInstallBanner from 'components/PwaInstallBanner';
import { EVENTS, trackEvent } from 'helpers/tracking';
import { getThumbnailUrl } from 'helpers/vendor';

import { MEMBERSHIP_STATUS } from 'constants/vendor';
import ArrowTooltip from 'components/ArrowTooltip';
import ChildrenMenuItem from './ChildrenMenuItem';
import MenuItem from './MenuItem';
import LeadsFolderMenu from './LeadsFolderMenu';
import { vendorMenu } from './config';
import styles, { classes } from './styles';

const LeftNav = () => {
  const { pathname } = useLocation();
  const { openLeftNav } = useLeftNavContextState();
  const { setOpenLeftNav } = useLeftNavContextDispatch();

  const {
    vendor,
    isLoading: isGetVendorLoading,
    isError: isGetVendorError,
    hasSelectedVendor,
  } = React.useContext(VendorContext);

  const { leadsFolders, refreshLeadsFolder } = React.useContext(LeadsFolderContext);

  const { action } = useHistory();
  const isInitialMount = React.useRef(true);

  React.useEffect(() => {
    if (action === 'REPLACE') return;
    if (!isInitialMount.current) {
      refreshLeadsFolder();
    } else {
      isInitialMount.current = false;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const thumbnailUrl = getThumbnailUrl(vendor);

  const handleClickListItem = (title, url) => {
    setOpenLeftNav(false);

    trackEvent(EVENTS.leftNavMenuItemClicked, {
      title,
      url,
    });
  };
  const handleClickMembershipSelection = () => {
    setOpenLeftNav(false);

    trackEvent(EVENTS.switchMembershipClicked, {
      source: 'left_nav',
    });
  };

  const renderChildrenMenu = (menu) => {
    const { title, childrenList } = menu;
    if (title !== 'Leads') return null;

    return (
      <Box sx={{ marginBottom: '12px' }}>
        <LeadsFolderMenu leadsFolders={leadsFolders} onClickMenuItem={handleClickListItem} />
        {childrenList.map((item) => (
          <ChildrenMenuItem
            key={item.title}
            title={item.title}
            data-testid={item.dataTestId}
            url={item.url}
            icon={<item.icon />}
            onClickMenuItem={() => handleClickListItem(item.title, item.url)}
          />
        ))}
      </Box>
    );
  };

  const renderVendorMenu = () =>
    vendorMenu.map((menu, index) => (
      <Box key={index.toString()}>
        <MenuItem
          icon={<menu.icon />}
          key={menu.title}
          title={menu.title}
          data-testid={menu.dataTestId}
          url={menu.url}
          onClickMenuItem={() => handleClickListItem(menu.title, menu.url)}
          hasChildren={Boolean(menu.childrenList)}
        />
        {menu.childrenList && renderChildrenMenu(menu)}
      </Box>
    ));

  return (
    <Box sx={[styles.nav, { display: openLeftNav ? 'block' : 'none' }]} className={classes.root}>
      <Loader isError={isGetVendorError} isLoading={isGetVendorLoading} sx={styles.loaderContainer}>
        {hasSelectedVendor ? (
          <Box sx={styles.vendorDetails}>
            <Box sx={styles.profileImageBox}>
              <Box
                component="img"
                sx={styles.profileImage}
                src={thumbnailUrl}
                alt={vendor.profileName}
              />
              {vendor.vendorStatusId === MEMBERSHIP_STATUS.VACATION_MODE_LEADS_PAUSED && (
                <ArrowTooltip
                  title="You're on Vacation mode"
                  arrow
                  placement="bottom"
                  componentsProps={{ tooltip: { sx: styles.customArrow } }}
                >
                  <Box sx={styles.vacationIconLabel}>
                    <Typography>
                      <LandscapeOutlinedIcon fontSize="small" />
                    </Typography>
                  </Box>
                </ArrowTooltip>
              )}
            </Box>
            <Box>
              <Typography component="span" variant="body1" sx={styles.vendorName}>
                {vendor.profileName}
              </Typography>
              <Typography variant="caption" sx={styles.vendorInfo}>
                Member Id: {vendor.legacyId}
              </Typography>
              {vendor.primaryServiceType && (
                <Typography variant="caption" sx={styles.vendorInfo}>
                  {vendor.primaryServiceType.name}
                </Typography>
              )}
              <Typography variant="caption" sx={styles.vendorInfo}>
                {vendor.cityState}
              </Typography>
              {vendor.hasMultipleVendorsOnAccount && (
                <Link
                  variant="caption"
                  sx={styles.switchVendor}
                  component={RouteLink}
                  rel="no-follow"
                  title="Switch membership"
                  to="/membership-selection"
                  onClick={handleClickMembershipSelection}
                  data-testid="link-left-nav-switch-membership"
                >
                  Switch membership
                  <SwitchIcon />
                </Link>
              )}
            </Box>
          </Box>
        ) : (
          <Box sx={styles.membershipSelectionBox}>
            <Link
              sx={styles.membershipSelectionLink}
              variant="subtitle2"
              component={RouteLink}
              rel="no-follow"
              title="Switch membership"
              to="/membership-selection"
              onClick={handleClickMembershipSelection}
              data-testid="link-left-nav-switch-membership"
            >
              Membership Selection
            </Link>
          </Box>
        )}
      </Loader>
      <Divider sx={styles.divider} />
      {hasSelectedVendor && renderVendorMenu()}
      {
        <PwaInstallBanner
          sx={[
            styles.pwaInstallBanner,
            {
              display: hasSelectedVendor ? 'block' : 'none',
            },
          ]}
        />
      }
      <Box sx={styles.navList}>
        <Typography variant="subtitle1" component="div" sx={styles.navListItem}>
          <Link
            href={`${gmConfig.domains.www}/contact`}
            title="Contact Us"
            onClick={() => {
              trackEvent(EVENTS.contactUsClicked, {
                source: 'left_nav',
              });
            }}
            data-testid="link-left-nav-contact-us"
          >
            Contact Us
          </Link>
        </Typography>
        <Typography variant="subtitle1" component="div" sx={styles.navListItem}>
          <Link
            title="Go to main site"
            rel="nofollow"
            href={gmConfig.domains.www}
            onClick={() => {
              trackEvent(EVENTS.headerGoToPublicSiteClicked, {
                source: 'left_nav',
              });
            }}
            data-testid="link-left-nav-go-to-public-site"
          >
            Go to main site
          </Link>
        </Typography>
        <Typography variant="subtitle1" component="div" sx={styles.navListItem}>
          <Link
            component={RouteLink}
            title="Logout"
            rel="nofollow"
            to="/logout"
            onClick={() => {
              trackEvent(EVENTS.logoutClicked, {
                source: 'left_nav',
              });
            }}
            data-testid="link-left-nav-log-out"
          >
            Logout
          </Link>
        </Typography>
      </Box>
    </Box>
  );
};

export default LeftNav;
