import { Theme } from '@mui/material';
import { keyframes } from '@mui/material/styles';

const loadMask = keyframes({
  from: { opacity: 0.2 },
  to: { opacity: 1 },
});

const styles = {
  root: ({ theme }: { theme: Theme }) => ({
    position: 'fixed',
    width: '100%',
    height: '100%',
    top: '0',
    backgroundColor: theme.palette.decorative.loader.primary,
    zIndex: 9999,
    animation: `${loadMask} 200ms`,
  }),
};

export default styles;
