import React, { ComponentType } from 'react';
import { UIContext, UIGlobalContextState } from 'context/global';

type MapStateToProps<TProps> = (state: UIGlobalContextState) => Partial<TProps>;

export const withGlobalContext =
  <TProps,>(mapStateToProps?: MapStateToProps<TProps>) =>
  (Component: ComponentType<TProps>) =>
  (props: TProps) => (
    <UIContext.Consumer>
      {(state: UIGlobalContextState) => {
        const mapState = mapStateToProps && mapStateToProps(state);
        const combineProps = { ...props, ...mapState };

        return <Component {...combineProps} />;
      }}
    </UIContext.Consumer>
  );
