import vendorApi from 'api/vendor';
import { TRANSPORTATION_OVERALL_SERVICE_TYPE_ID } from 'constants/serviceTypes';
import {
  AUTHENTICATED_USER_LOCAL_STORAGE_NAME,
  DEFAULT_PERFORMER_CONTEXT,
} from 'constants/storage';
import { MEMBERSHIP_TIER } from 'constants/vendor';
import { useFeatureFlagsContext } from 'featureFlags/index';
import cookieHelper from 'helpers/cookieHelper';
import useRequest from 'hooks/useRequest';
import isNil from 'lodash/isNil';
import PropTypes from 'prop-types';
import React, { createContext } from 'react';
import { Vendor } from 'types/vendor';
import { noop } from 'utils/function';
import { AxiosResponse } from 'axios';
import { Honeybadger } from '@honeybadger-io/react';
import { VendorFromAPI } from '../api/types/vendor';
import localStorageHelper from '../helpers/localStorageHelper';
import { mapApiVendorToUserTraits } from '../helpers/tracking/helpers';
import { honeyBadgerNotify } from '../helpers/honeyBadger';

const GetVendorFetches = {
  init: 'init',
  refresh: 'refresh',
} as const;

export type GetVendorFetchesKey = keyof typeof GetVendorFetches;
export type GetVendorFetchesValue = (typeof GetVendorFetches)[GetVendorFetchesKey];

const initialVendor: Vendor = {
  vendorId: '',
  legacyId: NaN,
  accountId: null,
  cityId: null,
  city: {
    name: '',
  },
  state: {
    abbreviation: '',
    name: '',
  },
  country: { abbreviation3: '' },
  vendorStatusId: null,
  venueId: null,
  profileName: '',
  firstName: '',
  lastName: '',
  zipCode: '',
  phone: '',
  email: '',
  alternativeEmail: '',
  website: '',
  minPayRange: 0,
  payUnit: '',
  displayPrice: true,
  rating: null,
  travelDistance: '',
  extendedUrlPart1: '',
  extendedUrlPart2: '',
  customUrl: '',
  isRestrictLeadsByCountry: false,
  isAutoAddToGigAlert: false,
  isAutoRenew: null,
  isAvailableForVirtualEvent: false,
  isLifetimeMember: null,
  membershipTerm: null,
  description: '',
  stripeAccountId: null,
  paymentToken: null,
  paymentTypeOnFile: null,
  paypalEmail: null,
  facebook: null,
  twitter: null,
  youtube: null,
  blog: null,
  linkedIn: null,
  instagram: null,
  membershipId: null,
  membershipStartDate: '',
  membershipEndDate: '',
  membershipTier: MEMBERSHIP_TIER.lite,
  createdAt: '',
  updatedAt: '',
  cityState: '',
  numberOfPhoto: 0,
  numberOfVideo: 0,
  numberOfCategory: null,
  thumbnailUrl: '',
  hasMultipleVendorsOnAccount: false,
  overallServiceTypeId: 0,
  serviceTypes: [],
  primaryServiceType: null,
  searchResultPhoto: null,
  userName: '',
  personForm: [],
  isTransportationVendor: false,
  hasEventPay: false,
};
const Context = createContext({
  vendor: initialVendor,
  refreshVendor: noop,
  isLoading: true,
  isError: false,
});

export const VendorContextProvider = ({ children }: React.PropsWithChildren) => {
  const legacyIdCookie: string | undefined = cookieHelper.get(DEFAULT_PERFORMER_CONTEXT);
  const legacyId = legacyIdCookie ? Number(legacyIdCookie) : null;
  const hasSelectedVendor = Boolean(legacyId);

  const {
    response,
    fetches: { [GetVendorFetches.init]: { isLoading: initIsLoading } = { isLoading: true } },
    exec: refresh,
    isError,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  }: any = useRequest(vendorApi.getById, {
    manual: !legacyId,
    params: {
      key: GetVendorFetches.init,
      vendorId: legacyId,
    },
    fetchKey: (p: { key: GetVendorFetchesValue }) => p.key,
    onSuccess: ({ data: apiVendor }: AxiosResponse<VendorFromAPI>) => {
      const userTraits = mapApiVendorToUserTraits(apiVendor);

      // Prevent btoa() from being called with a string containing the "’" character
      const sanitizedJson = JSON.stringify(userTraits).replace('’', "'");

      const encodedTraits = btoa(sanitizedJson);
      localStorageHelper.set(AUTHENTICATED_USER_LOCAL_STORAGE_NAME, encodedTraits);

      window.analytics.identify(String(apiVendor.accountId), userTraits);
      Honeybadger.setContext(userTraits);
    },
    onFailed: (error: Error) => {
      localStorageHelper.remove(AUTHENTICATED_USER_LOCAL_STORAGE_NAME);
      Honeybadger.resetContext();
      honeyBadgerNotify(error);
    },
  });

  const vendorAPIData = response?.data as VendorFromAPI | undefined;

  const refreshVendor = React.useCallback(
    () =>
      refresh({
        key: GetVendorFetches.refresh,
        vendorId: legacyId,
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [legacyId]
  );

  const [value, setValue] = React.useState({
    vendor: initialVendor,
    isLoading: true,
    isError: false,
    hasSelectedVendor,
    refreshVendor,
  });

  React.useEffect(() => {
    if (!vendorAPIData) return;
    setValue((pre) => ({
      refreshVendor,
      vendor: {
        ...response.data,
        membershipTier: isNil(vendorAPIData.membershipTier)
          ? initialVendor.membershipTier
          : vendorAPIData.membershipTier,
        cityState: `${vendorAPIData.city?.name || pre.vendor.city?.name}, ${
          vendorAPIData.state?.abbreviation || pre.vendor.state?.abbreviation
        }`,
        primaryServiceType: (vendorAPIData.serviceTypes || pre.vendor.serviceTypes)?.find(
          (item) => item.isPrimary
        ),
        hasEventPay: vendorAPIData.acceptDeposits || vendorAPIData.acceptBalances,
        personForm: [
          ...new Set(vendorAPIData.serviceTypes?.map((serviceType) => serviceType.bidPersonForm)),
        ],
        isTransportationVendor:
          vendorAPIData.overallServiceTypeId === TRANSPORTATION_OVERALL_SERVICE_TYPE_ID,
      },
      isLoading: Boolean(initIsLoading),
      isError,
      hasSelectedVendor,
    }));
  }, [response.data, refreshVendor, isError, hasSelectedVendor, vendorAPIData, initIsLoading]);

  const featureFlagsContext = useFeatureFlagsContext();

  React.useEffect(() => {
    featureFlagsContext.setUserInfo({
      vendorLegacyId: legacyId,
      accountId: value.vendor?.accountId,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [legacyId, value.vendor?.accountId]);

  return <Context.Provider value={value}>{children}</Context.Provider>;
};

VendorContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Context;
