import { Theme } from '@mui/material';

const styles = {
  root: (theme: Theme) => ({
    background: theme.palette.blue[700],
    position: 'relative',
    maxWidth: '300px',
    borderRadius: '3px',
    overflow: 'hidden',
  }),

  button: {
    background: 'transparent',
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    height: 'auto',
    zIndex: 2,
  },

  content: {
    position: 'relative',
    zIndex: 1,
    padding: '8px 12px 8px 50px',
  },

  bannerLeftImage: {
    position: 'absolute',
    top: 0,
    left: 0,
  },

  bannerRightImage: {
    position: 'absolute',
    bottom: 0,
    right: 0,
  },
};

export default styles;
