import React from 'react';
import Slide, { SlideProps } from '@mui/material/Slide';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import { UseScrollTriggerOptions } from '@mui/material/useScrollTrigger/useScrollTrigger';
import { Theme, useMediaQuery } from '@mui/material';

type HideOnScrollProps = {
  threshold: number;
  mobileOnly?: boolean;
} & Pick<UseScrollTriggerOptions, 'disableHysteresis'> &
  Pick<SlideProps, 'children'>;

const HideOnScroll = (props: HideOnScrollProps) => {
  const { threshold, disableHysteresis, mobileOnly, children, ...otherProps } = props;
  const isMobile = useMediaQuery<Theme>((theme) => theme.breakpoints.down('sm'));

  const trigger = useScrollTrigger({
    disableHysteresis: disableHysteresis || false,
    threshold,
  });

  if (mobileOnly && !isMobile) {
    return children;
  }

  return (
    <Slide appear={false} direction="down" in={!trigger} {...otherProps}>
      {children}
    </Slide>
  );
};

export default HideOnScroll;
