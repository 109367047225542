import { objectToSnake } from 'ts-case-convert';
import { VendorFromAPI } from '../../api/types/vendor';

export const mapApiVendorToUserTraits = (apiVendor: VendorFromAPI) => ({
  memberId: apiVendor.legacyId,
  email: apiVendor.email?.toLowerCase(),
  phone: apiVendor.phone?.replace(/\D/g, ''),
  firstName: apiVendor.firstName.toLowerCase(),
  lastName: apiVendor.lastName.toLowerCase(),
  city: apiVendor.city.name.toLowerCase(),
  state: apiVendor.state.abbreviation.toLowerCase(),
  postalCode: apiVendor.zipCode?.toLowerCase(),
  country: apiVendor.country.abbreviation2,
});

export const transformEventPropertiesCase = <T extends object>(props: T) => objectToSnake(props);
