import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import StarOutlinedIcon from '@mui/icons-material/StarOutlined';
import PersonOutlinedIcon from '@mui/icons-material/PersonOutlined';
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import BuildOutlinedIcon from '@mui/icons-material/BuildOutlined';
import SavedResponsesIcon from 'components/Icon/SavedResponsesIcon';
import ManageFolderIcon from 'components/Icon/ManageFolderIcon';

export const vendorMenu = [
  {
    title: 'Dashboard',
    url: `/dashboard`,
    icon: HomeOutlinedIcon,
    dataTestId: 'link-left-nav-dashboard',
  },
  {
    title: 'Leads',
    url: '/leads',
    icon: StarOutlinedIcon,
    dataTestId: 'link-left-nav-leads',
    childrenList: [
      {
        title: 'Manage folders',
        url: '/leads/manage-folders',
        icon: ManageFolderIcon,
        dataTestId: 'link-left-nav-leads-manage-folders',
      },
      {
        title: 'Saved responses',
        url: '/leads/saved-responses?type=quote',
        icon: SavedResponsesIcon,
        dataTestId: 'link-left-nav-leads-saved-responses',
      },
    ],
  },
  {
    title: 'Profile',
    url: `/profile`,
    icon: PersonOutlinedIcon,
    dataTestId: 'link-left-nav-profile',
  },
  {
    title: 'Calendar',
    url: `/calendar`,
    icon: CalendarTodayOutlinedIcon,
    dataTestId: 'link-left-nav-calendar',
  },
  {
    title: 'Account',
    url: `/account`,
    icon: AccountCircleOutlinedIcon,
    dataTestId: 'link-left-nav-account',
  },
  {
    title: 'Tools',
    url: `/tools`,
    icon: BuildOutlinedIcon,
    dataTestId: 'link-left-nav-tools',
  },
];
