import { LeadStatusValue, ResponseTypeValue } from '../../constants/leads';

export const LEAD_DETAIL_EVENTS = {
  leadsFoldersToggleClicked: 'Leads Folders Toggle Button Clicked',
  leadDetailMoreOptionsButtonClicked: 'Lead Detail More Options Button Clicked',
  leadDetailReportSpamButtonClicked: 'Lead Detail Report Spam Button Clicked',
  leadDetailUndoReportSpamButtonClicked: 'Lead Detail Undo Report Spam Button Clicked',
  leadDetailSaveAsSavedResponseButtonClicked: 'Lead Detail Save As Saved Response Button Clicked',
  leadDetailEditSavedResponseLinkClicked: 'Lead Detail Edit Saved Response Link Clicked',
  leadDetailEmailCopyQuoteClickedSuccessfully: 'Lead Detail Email Copy Quote Clicked Ok',
  leadDetailEmailCopyQuoteClickedUnsuccessfully: 'Lead Detail Email Copy Quote Clicked Fail',
  leadDetailsAddMyNotesButtonClick: 'Lead Details Add My Notes Button Clicked',
  leadDetailsEditMyNotesButtonClick: 'Lead Details Edit My Notes Button Clicked',
  leadDetailsDeleteMyNotesButtonClick: 'Lead Details Delete My Notes Button Clicked',
  confirmMyNotesDeleteDeleteButtonClickSuccessfully:
    'Confirm My Notes Delete Delete Button Clicked Ok',
  confirmMyNotesDeleteCancelButtonClick: 'Confirm My Notes Delete Cancel Button Clicked',
  myNotesDialogSaveButtonClickSuccessfully: 'My Notes Dialog Save Button Clicked Ok',
  myNotesDialogCancelButtonClick: 'My Notes Dialog Cancel Button Clicked',
  leadDetailSendResponseButtonClick: 'Lead Detail Send Response Button Clicked',
  leadDetailEditResponseButtonClick: 'Lead Detail Edit Response Button Clicked',
  leadDetailPayNowButtonClick: 'Lead Detail Pay Now Button Clicked',
  leadDetailPaymentsTabClick: 'Lead Detail Payments Tab Clicked',
  leadDetailPaymentsTransactionsButtonClick: 'Lead Detail Payments Transactions Button Clicked',
  leadDetailPaymentsOrderIdButtonClick: 'Lead Detail Payments Order Id Button Clicked',
  leadDetailReviewsTabClick: 'Lead Detail Reviews Tab Clicked',
  leadDetailReviewSendReminderButtonClick: 'Lead Detail Review Send Reminder Button Clicked',
  leadDetailClientLocationButtonButtonClick: 'Lead Detail Location Button Clicked',
  leadDetailClientEmailButtonButtonClick: 'Lead Detail Client Email Button Clicked',
  leadDetailClientPhoneButtonButtonClick: 'Lead Detail Client Phone Button Clicked',
  leadDetailLeadViewMoreButtonClick: 'Lead Detail Lead View More Button Clicked',
  leadDetailResponseViewMoreButtonClick: 'Lead Detail Response View More Button Clicked',
  leadDetailMyNotesViewMoreButtonClick: 'Lead Detail My Notes View More Button Clicked',
  leadDetailEventDetailsViewMoreButtonClick: 'Lead Detail Event Details View More Button Clicked',
  leadDetailPersonalMessageViewMoreButtonClick: 'Lead Detail Personal Msg View More Button Clicked',
} as const;

export interface LeadDetailBaseEventProperties {
  gigId: number;
  leadId: number;
  leadStatus?: LeadStatusValue;
  autoAdded: boolean;
}

interface LeadDetailGigRequestBaseEventProperties extends LeadDetailBaseEventProperties {
  budget: string;
  guestCount: string;
}
interface LeadDetailAdditionalGigRequestEventProperties
  extends LeadDetailGigRequestBaseEventProperties {
  serviceType: string;
  eventType: string;
  serviceLength: number;
  location: string;
}

interface LeadDetailPlannerNotesEventProperties {
  eventDetailLength: number;
}

interface LeadDetailPaymentAndReviewEventProperties
  extends LeadDetailAdditionalGigRequestEventProperties {
  personalMessageLength: number;
}

export const ViewMoreButtonState = {
  VIEW_MORE: 'view_more',
  VIEW_LESS: 'view_less',
} as const;

type ViewMoreButtonStateKey = keyof typeof ViewMoreButtonState;
export type ViewMoreButtonStateValue = (typeof ViewMoreButtonState)[ViewMoreButtonStateKey];

interface LeadDetailViewMoreEventProperties extends LeadDetailBaseEventProperties {
  buttonState: ViewMoreButtonStateValue;
}

export type LeadDetailPaymentAndReviewEventPropsMap = {
  [LEAD_DETAIL_EVENTS.leadDetailPayNowButtonClick]: LeadDetailPaymentAndReviewEventProperties;
  [LEAD_DETAIL_EVENTS.leadDetailPaymentsTabClick]: LeadDetailPaymentAndReviewEventProperties;
  [LEAD_DETAIL_EVENTS.leadDetailPaymentsTransactionsButtonClick]: LeadDetailPaymentAndReviewEventProperties;
  [LEAD_DETAIL_EVENTS.leadDetailPaymentsOrderIdButtonClick]: LeadDetailPaymentAndReviewEventProperties;
  [LEAD_DETAIL_EVENTS.leadDetailReviewsTabClick]: LeadDetailPaymentAndReviewEventProperties;
  [LEAD_DETAIL_EVENTS.leadDetailReviewSendReminderButtonClick]: LeadDetailPaymentAndReviewEventProperties;
  [LEAD_DETAIL_EVENTS.leadDetailClientLocationButtonButtonClick]: LeadDetailPaymentAndReviewEventProperties;
  [LEAD_DETAIL_EVENTS.leadDetailClientEmailButtonButtonClick]: LeadDetailPaymentAndReviewEventProperties;
  [LEAD_DETAIL_EVENTS.leadDetailClientPhoneButtonButtonClick]: LeadDetailPaymentAndReviewEventProperties;
};

export type MoreOptionsEventPropsMap = {
  [LEAD_DETAIL_EVENTS.leadDetailReportSpamButtonClicked]: LeadDetailGigRequestBaseEventProperties &
    LeadDetailPlannerNotesEventProperties;
  [LEAD_DETAIL_EVENTS.leadDetailUndoReportSpamButtonClicked]: LeadDetailGigRequestBaseEventProperties &
    LeadDetailPlannerNotesEventProperties;
  [LEAD_DETAIL_EVENTS.leadDetailSaveAsSavedResponseButtonClicked]: LeadDetailGigRequestBaseEventProperties &
    LeadDetailPlannerNotesEventProperties;
  [LEAD_DETAIL_EVENTS.leadDetailEditSavedResponseLinkClicked]: LeadDetailGigRequestBaseEventProperties &
    LeadDetailPlannerNotesEventProperties;
  [LEAD_DETAIL_EVENTS.leadDetailEmailCopyQuoteClickedSuccessfully]: LeadDetailGigRequestBaseEventProperties &
    LeadDetailPlannerNotesEventProperties;
  [LEAD_DETAIL_EVENTS.leadDetailEmailCopyQuoteClickedUnsuccessfully]: LeadDetailGigRequestBaseEventProperties &
    LeadDetailPlannerNotesEventProperties;
};

export type MyNotesEventPropsMap = {
  [LEAD_DETAIL_EVENTS.leadDetailsAddMyNotesButtonClick]: LeadDetailBaseEventProperties;
  [LEAD_DETAIL_EVENTS.leadDetailsEditMyNotesButtonClick]: LeadDetailBaseEventProperties;
  [LEAD_DETAIL_EVENTS.leadDetailsDeleteMyNotesButtonClick]: LeadDetailBaseEventProperties;
  [LEAD_DETAIL_EVENTS.confirmMyNotesDeleteDeleteButtonClickSuccessfully]: LeadDetailBaseEventProperties;
  [LEAD_DETAIL_EVENTS.confirmMyNotesDeleteCancelButtonClick]: LeadDetailBaseEventProperties;
  [LEAD_DETAIL_EVENTS.myNotesDialogCancelButtonClick]: LeadDetailBaseEventProperties;
  [LEAD_DETAIL_EVENTS.myNotesDialogSaveButtonClickSuccessfully]: LeadDetailBaseEventProperties & {
    isEdition: boolean;
  };
};

export type ViewMoreEventPropsMap = {
  [LEAD_DETAIL_EVENTS.leadDetailLeadViewMoreButtonClick]: LeadDetailViewMoreEventProperties;
  [LEAD_DETAIL_EVENTS.leadDetailResponseViewMoreButtonClick]: LeadDetailViewMoreEventProperties;
  [LEAD_DETAIL_EVENTS.leadDetailMyNotesViewMoreButtonClick]: LeadDetailViewMoreEventProperties;
  [LEAD_DETAIL_EVENTS.leadDetailEventDetailsViewMoreButtonClick]: LeadDetailViewMoreEventProperties;
  [LEAD_DETAIL_EVENTS.leadDetailPersonalMessageViewMoreButtonClick]: LeadDetailViewMoreEventProperties;
};

export type LeadDetailEventPropsMap = {
  [LEAD_DETAIL_EVENTS.leadDetailMoreOptionsButtonClicked]: LeadDetailBaseEventProperties;
  [LEAD_DETAIL_EVENTS.leadDetailSendResponseButtonClick]: LeadDetailAdditionalGigRequestEventProperties &
    LeadDetailPlannerNotesEventProperties & {
      responseType: ResponseTypeValue;
    };
  [LEAD_DETAIL_EVENTS.leadDetailEditResponseButtonClick]: LeadDetailAdditionalGigRequestEventProperties &
    LeadDetailPlannerNotesEventProperties & {
      previousResponseType: ResponseTypeValue;
      newResponseType: ResponseTypeValue;
    };
} & LeadDetailPaymentAndReviewEventPropsMap &
  MoreOptionsEventPropsMap &
  ViewMoreEventPropsMap &
  MyNotesEventPropsMap;
