import { createContext, PropsWithChildren, useState } from 'react';
import { noop } from 'utils/function';

export type SnackbarOptions = {
  open: boolean;
  type: 'success' | 'error' | 'info' | 'warning';
  content?: React.ReactNode;
  actionText?: string;
  actionCallback?: () => void;
  key?: number;
};

export type SnackbarContextState = {
  snackbar: SnackbarOptions;
  setSnackbar: (value: SnackbarOptions) => void;
};

const initialSnackbar = {
  snackbar: {
    open: false,
    type: 'success' as SnackbarOptions['type'],
    content: '' as SnackbarOptions['content'],
  },
  setSnackbar: noop,
};

export const SnackbarContext = createContext<SnackbarContextState>(initialSnackbar);

export const SnackbarContextProvider = ({ children }: PropsWithChildren<unknown>) => {
  const [snackbar, setBar] = useState<SnackbarOptions>(initialSnackbar.snackbar);

  const setSnackbar = (value: SnackbarOptions) => {
    setBar(() => ({
      ...value,
      // TODO: what's the point of this key?
      key: value.key || new Date().getTime() + Math.random(),
    }));
  };

  return (
    <SnackbarContext.Provider
      value={{
        snackbar,
        setSnackbar,
      }}
    >
      {children}
    </SnackbarContext.Provider>
  );
};
