const greyColor = {
  50: '#F9F9FA',
  100: '#F3F3F5',
  300: '#E4E4E5',
  400: '#C2C2C3',
  500: '#ACACAD',
  600: '#6A6F71',
  900: '#1F2737',
} as const;

export default {
  mode: 'light',
  type: 'light',
  common: {
    black: '#000000',
    white: '#FFFFFF',
  },
  overlay: {
    main: 'rgba(255, 255, 255, 0.8)',
    light: 'rgba(255, 255, 255, 0.8)',
    dark: 'rgba(0, 0, 0, 0.8)',
  },
  primary: {
    main: '#185FBC',
    light: '#CCE2FF',
    dark: '#12488F',
    contrastText: '#FFFFFF',
  },
  secondary: {
    main: '#185FBC',
    light: '#CCE2FF',
    dark: '#12488F',
    contrastText: '#FFFFFF',
  },
  decorative: {
    star: '#012CB3',
    facebook: '#1877F2',
    twitter: '#1DA1F2',
    linkedin: '#0A66C2',
    vimeo: '#4DBAFD',
    youtube: '#FF0000',
    instagram: {
      100: '#B13589',
      200: '#C62F94',
      300: '#8A3AC8',
      400: '#E0E8B7',
      500: '#FB8A2E',
      600: '#E2425C',
      700: '#406ADC',
      800: '#6A45BE',
    },
    paypal: { 100: '#253B80', 200: '#179BD7', 300: '#222D65', 400: '#253B80' },
    loader: { primary: 'rgba(243, 243, 245, 0.7)', border: '#4E5155' },
  },
  info: {
    main: '#185FBC',
    light: '#CCE2FF',
    dark: '#12488F',
    disabled: '#B9CFEC',
    contrastText: '#FFFFFF',
    background: 'rgba(24, 95, 188, 0.04)',
  },
  error: {
    main: '#BE130A',
    light: '#FFF1F0',
    dark: '#920E07',
    disabled: '#EFCFCD',
    contrastText: '#FFFFFF',
    background: 'rgba(190, 19, 10, 0.04)',
  },
  warning: {
    main: '#B65002',
    light: '#FFF4E6',
    dark: '#883C02',
    disabled: '#F7E0C5',
    contrastText: '#FFFFFF',
    background: 'rgba(182, 80, 2, 0.04)',
  },
  success: {
    main: '#0E8A0E',
    light: '#F6FFED',
    disabled: '#D3EABB',
    contrastText: '#FFFFFF',
  },
  black: {
    300: '#D9DAE0',
    400: '#C5C6CB',
    500: '#94969E',
    600: '#6F7178',
    800: '#3C3C3C',
    900: '#1C1C1C',
  },
  grey: greyColor,
  green: {
    50: '#C6EFE5',
    100: '#A1E0D0',
    200: '#7EDEC3',
    300: '#4BD6B4',
    400: '#00BF95',
    500: '#009978',
    600: '#008568',
    800: '#006F57',
  },
  blue: {
    50: '#F1F6FF',
    100: '#B8D0FF',
    300: '#87ABFF',
    400: '#436DF1',
    500: '#1947D9',
    700: '#012CB3',
    800: '#082278',
    900: '#03143D',
  },
  citrine: {
    200: '#E8F0AA',
    300: '#DBE777',
    400: '#D2E155',
    500: '#BCCC31',
    600: '#A6BA02',
    800: '#889900',
    900: '#6B7700',
  },
  mint: {
    // TODO: Review mint values
    1: 'rgba(0, 116, 110, 0.04)',
    50: '#EAFBF5',
    700: '#00746E',
    900: '#005752',
  },
  text: {
    main: '#1F2737',
    primary: '#1F2737',
    secondary: '#6A6F71',
    disabled: greyColor[300],
    hint: '#ACACAD',
  },
  divider: '#ACACAD',
  background: {
    default: '#F3F3F5',
  },
  dialog: {
    background: 'rgba(0, 0, 0, 0.5)',
  },
  action: {
    active: 'rgba(0,0,0,.4)',
    hover: 'rgba(0,0,0,0.04)',
    hoverOpacity: 0.04,
    selected: 'rgba(0,0,0,.08)',
    selectedOpacity: 0.08,
    disabled: 'rgba(0,0,0,.26)',
    disabledBackground: 'rgba(0,0,0,.12)',
    disabledOpacity: 0.38,
    focus: 'rgba(0,0,0,.12)',
    focusOpacity: 0.12,
    activatedOpacity: 0.12,
    activeBackground: 'rgba(184, 208, 255, 0.1)',
    shadow: 'rgba(128, 183, 255, 0.3)',
    background: 'rgba(0, 0, 0, 0.8)',
  },
  menu: {
    background: {
      100: 'rgba(204, 226, 255, 0.1)',
      200: 'rgba(204, 226, 255, 0.4)',
      300: 'rgba(204, 226, 255, 0.5)',
    },
  },
  photoCard: {
    background: {
      100: 'rgba(31, 39, 55, 0.5)',
      200: 'rgba(31, 39, 55, 0.7)',
    },
  },
  paper: {
    shadow: 'rgba(0, 0, 0, 0.2)',
  },
  box: {
    shadow: '#E6E6E6',
  },
  backgroundGradient: {
    opaque: 'rgba(255,255,255, 1)',
    intermediate: 'rgba(255,255,255, 0.1)',
    transparent: 'rgba(255,255,255, 0)',
  },
} as const;
