import cookieHelper from 'helpers/cookieHelper';

export const useJsonCookie = <T>(key: string, cookieOptions = {}) => {
  const get = () => {
    const cookieValue = cookieHelper.get(key);
    return cookieValue ? JSON.parse(cookieValue) : undefined;
  };

  const set = (newValue: T) => {
    cookieHelper.set(key, JSON.stringify(newValue), cookieOptions);
  };

  const remove = () => {
    cookieHelper.remove(key);
  };

  return {
    get,
    set,
    remove,
  };
};
