import React from 'react';
import { noop } from 'utils/function';
import { Link as RouteLink, useRouteMatch } from 'react-router-dom';

import { Box, Link } from '@mui/material';

import styles from './styles';

type MenuItemProps = {
  icon?: React.ReactNode;
  title?: string;
  onClickMenuItem?: () => void;
  className?: string;
  url: string;
  'data-testid'?: string;
  hasChildren?: boolean;
};

const MenuItem = (props: MenuItemProps) => {
  const {
    icon,
    title,
    url = '',
    onClickMenuItem = noop,
    className,
    'data-testid': dataTestId,
    hasChildren = false,
    ...otherProps
  } = props;

  const active = useRouteMatch(url);

  return (
    <Box
      component="div"
      // @ts-expect-error Wants a string not an array
      className={[active && 'active', hasChildren && 'hasChildren', className]}
      sx={styles.menuItemRoot}
      {...otherProps}
    >
      {hasChildren ? (
        <Box sx={styles.itemLink} data-testid={dataTestId}>
          <Box component="span" sx={styles.itemLinkIcon}>
            {icon}
          </Box>
          <Box component="span" sx={styles.itemLinkTitle}>
            {title}
          </Box>
        </Box>
      ) : (
        <Link
          sx={styles.itemLink}
          onClick={onClickMenuItem}
          data-testid={dataTestId}
          component={RouteLink}
          to={url}
        >
          <Box component="span" sx={styles.itemLinkIcon}>
            {icon}
          </Box>
          <Box component="span" sx={styles.itemLinkTitle}>
            {title}
          </Box>
        </Link>
      )}
    </Box>
  );
};

export default MenuItem;
