import store from 'store';
import { gmConfig } from 'config/index';

const prefixKey = (key: string) => `${gmConfig.cookie.prefix}${key}`;

type LocalStorageValue = Parameters<typeof store.set>[1];

const localStorageHelper = {
  get: (key: string) => store.get(prefixKey(key)),
  set: (key: string, value: LocalStorageValue) => store.set(prefixKey(key), value),
  remove: (key: string) => store.remove(prefixKey(key)),
};

export default localStorageHelper;
