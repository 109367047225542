import { Theme } from '@mui/material';

const PREFIX = 'component-SnackbarList';

export const classes = {
  snackbarRoot: `${PREFIX}-snackbarRoot`,
};

export const styles = {
  styledRoot: ({ theme }: { theme: Theme }) => ({
    zIndex: theme.zIndex.snackbar,
    top: '70px',
    width: 'calc(100% - 40px)',
    left: '20px',
    bottom: 'auto',
    right: 0,
    position: 'fixed',
    transform: 'translateX(0%)',
    [theme.breakpoints.up('sm')]: {
      left: '32px',
      width: '702px',
    },
    [theme.breakpoints.up('md')]: {
      left: '312px',
    },
    [`& .${classes.snackbarRoot}`]: {
      position: 'static',
      width: '100%',
      marginTop: '12px',
      '&:nth-of-type(1)': {
        margin: '0px',
      },
    },
  }),
};
