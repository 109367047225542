import { HEADER_HEIGHT } from '../../Header/styles';

const styles = {
  minHeight: `max(180px, calc(100lvh - ${HEADER_HEIGHT}px))`,

  '& .Loader-ErrorMessage-root': {
    height: '100%',
    minHeight: '180px',
  },
};

export default styles;
