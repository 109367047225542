import { RequestExecutionCallback, UseEagerRequestOptions, UseRequestReturn } from './types';
import { useLazyRequest } from './useLazyRequest';
import { useMountEffect } from '../useMountEffect';

export const useEagerRequest = <R, P>(
  callback: RequestExecutionCallback<R, P>,
  options: UseEagerRequestOptions<R, P>
): UseRequestReturn<R, P> => {
  const { initialRequestParams, ...useLazyRequestOptions } = options;

  const result = useLazyRequest(callback, useLazyRequestOptions);

  // Can safely use a non-nullish assertion here, as the initial
  // params can only be undefined when exec() accepts it too.
  useMountEffect(() => {
    result.exec(initialRequestParams!);
  });

  return result;
};
