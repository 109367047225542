import { Theme } from '@mui/material';

const styles = {
  tooltip: (theme: Theme) => ({
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.background.default,
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: '2px',
    marginLeft: '2px',
    marginRight: '2px',
    padding: '8px 8px',
    maxWidth: '204px',

    '.MuiTooltip-popper[data-popper-placement*="top"] &': {
      marginBottom: '12px',
    },
    '.MuiTooltip-popper[data-popper-placement*="bottom"] &': {
      marginTop: '12px',
    },
  }),

  arrow: (theme: Theme) => ({
    color: theme.palette.background.default,
    '&:before': {
      border: `1px solid ${theme.palette.divider}`,
    },
  }),
};

export default styles;
