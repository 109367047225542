export const SEARCH_LOCAL_STORAGE_NAME = 'store.search2';

export const BEARER_TOKEN_COOKIE_NAME = 'BearerToken';
export const REFRESH_TOKEN_COOKIE_NAME = 'RefreshToken';
export const REFRESH_TOKEN_COOKIE_EXPIRATION_DAYS = 30;
export const REFRESH_BEARER_TOKEN_RETRY_COUNT = 1;
export const JWT_COOKIE_NAME = 'Token';
export const SCOPES = 'Scopes';
export const WELCOME_NAME = 'WelcomeName';
export const DEFAULT_PERFORMER_CONTEXT = 'defaultPerformerContext';
export const ASPXAUTH_LEGACY = 'ASPXAUTH_LEGACY';
export const K = 'k';
export const AUTHENTICATED_USER_LOCAL_STORAGE_NAME = 'AuthenticatedUser';

export const FAVORITES_COOKIE_NAME = 'favoriteMemberIDs';
export const GIGKIDS_MEMBER_IDS_COOKIE_NAME = 'gigkidsMemberIds';
export const RFQ_AB_TEST_COOKIE_NAME = 'rfqABTest';
export const PERSIST_PARTNER_OBJECT = 'fromPartner';
export const BUDGET_RFQ_TEST_OBJECT = 'budgetRfqTest';
export const PERSIST_VENDOR_SOURCES_OBJECT = 'vendorSources'; // keys are VendorId/MemberId and values are the source (see shared/constants/source)
export const LOCATION_STORE_KEY = 'location';

export const PWA_NEW_FEATURE_DIALOG_IS_CLICKED_LATER_BUTTON_COOKIE_NAME =
  'PWANewFeatureDialogIsClickedLaterButton';

export const NOTIFICATION_PERMISSION_REQUEST_NAME = 'notificationPermissionRequest';
