import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import { styled, useTheme } from '@mui/material/styles';

const StyledSvgIcon = styled(SvgIcon)({
  width: '300px',
  height: '88px',
});

interface InstallBannerLeftProps extends SvgIconProps {}

const InstallBannerLeft = (props: InstallBannerLeftProps) => {
  const theme = useTheme();
  return (
    <StyledSvgIcon viewBox="0 0 300 88" fill="none" data-testid="svg" {...props}>
      <g clipPath="url(#clip0_2135_17626)">
        <circle
          cx="77"
          cy="77"
          r="77"
          transform="matrix(1 0 0 -1 -106 68)"
          fill={theme.palette.blue[800]}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.9751 28.9382C20.2099 28.9382 21.7043 28.2086 21.7043 25.9488C21.7043 23.8107 20.2343 22.912 17.2935 22.912H15.1859V28.9382H16.9751ZM17.2935 20.2377C19.5724 20.2377 20.8223 19.5096 20.8223 17.6139C20.8223 15.5732 19.6211 14.8678 17.2935 14.8678H15.1859V20.2377H17.2935ZM12 12H17.2197C21.7296 12 23.9841 13.6774 23.9841 17.2981C23.9841 19.2422 23.077 20.6273 21.5826 21.2829C23.8127 21.8667 25.1114 23.6891 25.1114 25.973C25.1114 29.8853 22.5628 32 17.0491 32H12V12Z"
          fill={theme.palette.common.white}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.5 45L22 50.5L16.5 56L11 50.5L16.5 45Z"
          fill={theme.palette.blue[500]}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M32.5652 53.333L36.9999 57.7775L32.5652 62.2219L28.1304 57.7775L32.5652 53.333Z"
          fill={theme.palette.blue[300]}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M238 8L244 14L238 20L232 14L238 8Z"
          fill={theme.palette.blue[300]}
        />
      </g>
      <defs>
        <clipPath id="clip0_2135_17626">
          <rect width="300" height="88" rx="3" fill={theme.palette.common.white} />
        </clipPath>
      </defs>
    </StyledSvgIcon>
  );
};

export default InstallBannerLeft;
