import React from 'react';

import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Link from '@mui/material/Link';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import HamburgIcon from 'components/Icon/HamburgIcon';
import CloseIcon from 'components/Icon/CloseIcon';
import RefreshIcon from '@mui/icons-material/Refresh';
import HeaderTabs from 'components/Header/HeaderTabs';
import HeaderTabDropDown from 'components/Header/HeaderTabDropDown';
import cookieHelper from 'helpers/cookieHelper';
import { EVENTS, trackEvent } from 'helpers/tracking';
import { gmConfig } from 'config/index';
import { WELCOME_NAME, DEFAULT_PERFORMER_CONTEXT } from 'constants/storage';
import { useLeftNavContextDispatch, useLeftNavContextState } from 'context/leftNav';
import HideOnScroll from 'components/HideOnScroll';
import { getIOSVersion, isPwa } from 'helpers/deviceHelper';

import BrandLogo from './BrandLogo';
import styles, { HEADER_HEIGHT } from './styles';

const StyledAppBar = styled(AppBar)(styles.appBar);
const StyledToolbar = styled(Toolbar)(styles.toolBar);
const StyledMenuIconButton = styled(IconButton)(styles.menuIconButtonRoot);
const StyledPWARefreshIconButton = styled(IconButton)(styles.menuIconButtonRoot);
const StyledLogoLink = styled(Link)(styles.brandLogo);

const Header = () => {
  const { openLeftNav } = useLeftNavContextState();
  const { setOpenLeftNav } = useLeftNavContextDispatch();
  const hasSelectedVendor = Boolean(cookieHelper.get(DEFAULT_PERFORMER_CONTEXT));
  const welcomeAccountName = cookieHelper.get(WELCOME_NAME);
  const [isMouseOverAccountTab, setIsMouseOverAccountTab] = React.useState(false);
  const [tabAnchorPosition, setTabAnchorPosition] = React.useState(0);
  const handleToggleMenu = (isOpened) => {
    setOpenLeftNav(isOpened);
  };

  const SwitchableHamburgIcon = openLeftNav ? CloseIcon : HamburgIcon;

  return (
    <>
      <HideOnScroll threshold={HEADER_HEIGHT} mobileOnly>
        <StyledAppBar color="primary" data-testid="div-app-header">
          <StyledToolbar position="sticky">
            <StyledMenuIconButton
              onClick={() => handleToggleMenu(!openLeftNav)}
              disableRipple
              data-testid="button-switch-left-nav-open"
            >
              <SwitchableHamburgIcon />
            </StyledMenuIconButton>
            <StyledLogoLink
              aria-label="The Bash Home"
              href={gmConfig.domains.mcp}
              onClick={() => {
                trackEvent(EVENTS.goToDashboardClicked, {
                  source: 'logo',
                });
              }}
              data-testid="link-brand-logo"
            >
              <BrandLogo />
            </StyledLogoLink>
            {getIOSVersion() && isPwa() && (
              <StyledPWARefreshIconButton
                onClick={() => {
                  trackEvent(EVENTS.headerTapOnPWARefresh);
                  window.location.reload();
                }}
                disableRipple
                data-testid="button-header-nav-refresh"
              >
                <RefreshIcon />
              </StyledPWARefreshIconButton>
            )}
            <HeaderTabs
              welcomeAccountName={welcomeAccountName}
              shouldShowHoverBorder={isMouseOverAccountTab}
              handleHoverTab={setIsMouseOverAccountTab}
              calLeftPosition={setTabAnchorPosition}
              value={isMouseOverAccountTab}
            />
          </StyledToolbar>
        </StyledAppBar>
      </HideOnScroll>
      <HeaderTabDropDown
        isShow={isMouseOverAccountTab}
        hasSelectedVendor={hasSelectedVendor}
        tabAnchorPosition={tabAnchorPosition}
        onHoverBlock={setIsMouseOverAccountTab}
      />
    </>
  );
};

export default Header;
